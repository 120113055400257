import WheelPicker from 'react-simple-wheel-picker';
import moment from 'moment';

const data = [...Array(100).keys()].map(n => {
	const now = moment().year()
	return {
		id: (now - n).toString(),
		value: (now - n).toString()
	}
}).reverse()

export const YearPicker = ({ year, setYear }) => {

	const handleOnChange = ({ id }) => {
		setYear(id)
	};

	if (data.length === 0) return null
	return (
		<WheelPicker
			data={data}
			onChange={handleOnChange}
			height={270}
			width={100}
			titleText="Enter value same as aria-label"
			itemHeight={30}
			selectedID={year}
			color="#ccc"
			activeColor="#F173BE"
			backgroundColor="transparent"
			classname="dayPicker"
		/>
	);
};