import axios from 'axios';

export const me = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/users/me`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export const login = async ({ email, password }) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/local`, {
    identifier: email,
    password
  });
}

export const forgot = async ({ email }) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/forgot-password`, { email }
  );
}

export const sendEmailConfirmation = async ({ email }) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/send-email-confirmation`, { email }
  );
}

export const signup = async (profile) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/local/register`, profile
  );
}

export const reset = async ({ code, password }) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/reset-password`, {
    code,
    password,
    passwordConfirmation: password
  });
}

export const updateMe = async ({ token, id, body }) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}


export const updateAvatar = async ({ token, id, file }) => {

  const form = new FormData();
  form.append('ref', 'user');
  form.append('source', 'users-permissions');
  form.append('refId', id);
  form.append('field', 'avatar');
  form.append('files', file, file.name);

  return await axios.post(
    `${process.env.REACT_APP_API_URL}/upload`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }
  );
}