import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BackButton, FlexContainer, PrimaryBackground, StyledP } from "../components/StyledComponents";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { getWorkouts, getWorkoutsCount } from "../api/workouts";
import { useAuth } from "../providers/auth";
import { MiniWorkoutCard } from "../components/MiniWorkoutCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLoading } from "../providers/loading";
import { HeaderLogo } from "../components/HeaderLogo";
import { getNextWeekEnd } from "../helpers/workout";

const LIMIT = 20;

export function WorkoutList(props) {
  const { t } = useTranslation();
  const [level] = useQueryParam("level", StringParam);
  const [{ token }] = useAuth();
  const history = useHistory();
  const [workouts, setWorkouts] = useState({ items: [], total: 0 });
  const [start, setStart] = useState(0);
  const loading = useLoading();

  const { items = [], total = 0 } = workouts;

  const [sentryRef] = useInfiniteScroll({
    loading: loading.isOpen,
    hasNextPage: items.length < total,
    onLoadMore: () => {
      setStart(start + LIMIT);
    },
  });

  useEffect(() => {
    fetchWorkouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  const fetchWorkouts = async () => {
    if (loading.isOpen) {
      return;
    }
    try {
      loading.show();
      const params = {
        token,
        level,
        start,
        limit: LIMIT,
        date_lte: getNextWeekEnd().format('YYYY-MM-DD')
      }
      let newTotal;
      if (!total) {
        const { data = 0 } = await getWorkoutsCount(params);
        newTotal = Number(data)
      } else {
        newTotal = total
      }

      const { data = [] } = await getWorkouts(params);

      setWorkouts({
        items: items.concat(data),
        total: newTotal,
      });
    } catch (err) {
      console.log(err);
    } finally {
      loading.hide();
    }
  };

  return (
    <PrimaryBackground direction="column" news>
      <HeaderLogo />
      <FlexContainer align="center" bottom={3}>
        <BackButton onClick={() => history.goBack()}>
          <img src="../arrow-back.svg" alt="back" />
        </BackButton>
        <StyledP semiBold left={1}>
          {t(`LEVELS.${level}`)}
        </StyledP>
      </FlexContainer>
      {!loading.isOpen && items.length === 0 ? (
        <StyledP align="center" style={{ alignSelf: 'center' }}>
          {t('NO_WORKOUTS')}
        </StyledP>
      ) : (
        items.map((workout) => (
          <FlexContainer key={workout.id} bottom={2}>
            <MiniWorkoutCard workout={workout} />
          </FlexContainer>
        ))
      )}
      {items.length < total && <div ref={sentryRef} />}
    </PrimaryBackground>
  );
}
