import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { isIOS, isMobile } from '../helpers/fullscreen';
import { StyledP } from './StyledComponents';

export const OrientationAlert = () => {
	const [landscape, setLandscape] = useState(false)
	const { t } = useTranslation();

	useEffect(() => {
		const mql = window.matchMedia && window.matchMedia("(orientation: landscape)");
		const changeOrientation = () => {
			const activeTag = document.activeElement ? document.activeElement.tagName : ''
			// Fix per alcuni telefoni android all'apertura della tastiera
			if (!isIOS && ['INPUT', 'TEXTAREA'].includes(activeTag.toUpperCase())) {
				setLandscape(false)
				return 
			}
			setLandscape(mql ? mql.matches : false)
		}

		if (mql.addEventListener) {
			mql.addEventListener('change', changeOrientation);
		} else if (mql.addListener) {
			mql.addListener(changeOrientation);
		}

		// chiamo prima volta
		changeOrientation()

		return () => {
			if (mql.removeEventListener) {
				mql.removeEventListener('change', changeOrientation)
			} else if (mql.removeListener) {
				mql.removeListener(changeOrientation)
			}
		}
	}, [])

	return (
		<div>
			<Modal isOpen={isMobile && landscape} className="orientation-modal" fade={false}>
				<ModalBody>
					<img src="../strong-emoji.svg" alt="strong emoji"></img>
					<StyledP
						color="purple"
						align="center"
						top={2}
						dangerouslySetInnerHTML={{ __html: t('ORIENTATION_MODAL_TEXT') }}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
};