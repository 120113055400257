import { TermsContainer } from './../StyledComponents';

export function TermsIt() {
  return (
    <TermsContainer>
      <h1>1. PREMESSA</h1>
      <p>I presenti Termini e Condizioni d’uso servono a regolare l’utilizzo di questo Sito Web e qualsiasi rapporto giuridico tra l’Utente, che utilizza i servizi qui offerti, e il Titolare del Sito stesso.</p>
      <p>I servizi possono essere aggiornati o in evoluzione, con sviluppo anche di eventuali nuovi strumenti.</p>
      <p>Le presenti disposizioni verranno aggiornate e/o modificate, mediante pubblicazione sul Sito Web <a href="https://megafitnesscommunity.it/get-now/">https://megafitnesscommunity.it/get-now/</a>, solo se necessario. Si consiglia, pertanto, di leggere periodicamente le condizioni d’uso e di prestarvi attenzione. </p>
      <p>In particolare si fa presente che determinate disposizioni di questi Termini e Condizioni d’uso potrebbero essere solo applicabili ad alcune categorie di Utenti. Alcune disposizioni potrebbero applicarsi solo a Consumatori o solo a Utenti che non agiscono come Consumatori. Tali limitazioni sono sempre menzionate esplicitamente in ciascuna clausola interessata. In caso di mancata menzione, le clausole si applicano a tutti gli Utenti e secondo la legge applicabile.</p>
      <p>Pertanto, prima dell’uso dei servizi, l’Utente deve leggere attentamente e, se richiesto, accettare le corrispondenti condizioni specifiche di ciascun servizio, che saranno pubblicate sugli stessi supporti degli attuali Termini e Condizioni d’uso.</p>
      <p>L’utilizzo dei servizi implica per l’Utente la piena accettazione delle disposizioni incluse nei presenti Termini e Condizioni d’uso, Privacy Policy (visionabile come segue: <a href="https://www.iubenda.com/privacy-policy/16918108">https://www.iubenda.com/privacy-policy/16918108</a>) e, se del caso, le condizioni specifiche.</p>
      <p>Qualora l’Utente non sia d’accordo con le disposizioni delle suddette condizioni, non dovrebbe continuare a utilizzare i servizi qui proposti.</p>

      <h1>2. IDENTIFICAZIONE DEL RESPONSABILE</h1>
      <p>Il responsabile, proprietario e Titolare di https://megafitnesscommunity.it/get-now/ è:</p>
      <p>GOLD INTERNATIONAL FCZO DUBAI TRADE LICENSE NR:5295</p>
      <p>Indirizzo email del Titolare: info@goldinternational.co goldinternational.co</p>

      <h1>3. CONDIZIONI D’USO</h1>
      <p>Le Condizioni d’uso esposte in questa sezione hanno validità generale, salvo ove diversamente specificato.</p>
      <p>Ulteriori Condizioni d’uso o d’accesso applicabili in particolari situazioni sono espressamente indicate in questo documento.</p>
      <p>Utilizzando questo Sito Web l’Utente prende atto della mancanza di restrizioni riferite agli Utenti rispetto al fatto che essi siano Consumatori o Utenti Professionisti.</p>

      <h2>3.1 REGISTRAZIONE</h2>
      <p>Per usufruire del servizio offerto l’Utente può aprire un account, indicando tutti i dati e le informazioni richieste in maniera completa e veritiera.</p>
      <p>Non è possibile usufruire del servizio offerto senza aprire un account Utente.</p>
      <p>È responsabilità degli Utenti conservare le proprie credenziali d’accesso in modo sicuro e salvaguardare la loro riservatezza per impedire l’accesso non autorizzato. A tal fine, gli Utenti devono scegliere una password che corrisponda al più alto livello di sicurezza disponibile su questo Sito Web.</p>
      <p>L’account e la password di ciascun Utente sono unici, personali e non trasferibili e, pertanto, non possono comunicarli a terzi o assegnarne l’utilizzo. Creando un account, pertanto, l’Utente accetta di essere pienamente responsabile di tutte le operazioni da lui eseguite e/o di qualsiasi operazione effettuata da un dispositivo associato al proprio account.</p>
      <p>Gli Utenti sono tenuti a informare il Titolare immediatamente e univocamente tramite i recapiti indicati in questo documento (info@goldinternational.co goldinternational.co) qualora ritengano che le proprie informazioni personali, quali ad esempio l’account Utente, le credenziali d’accesso o dati personali, siano state violate, illecitamente diffuse o sottratte.</p>

      <h2>3.2. CHIUSURA ACCOUNT</h2>
      <p>L’Utente è libero di chiudere il proprio account in qualsiasi momento, contattando il Titolare ai recapiti indicati in questo documento (info@goldinternational.co goldinternational.co).</p>

      <h2>3.3. SOSPENSIONE E CANCELLAZIONE ACCOUNT</h2>
      <p>Il Titolare si riserva la facoltà di rifiutare qualsiasi richiesta di registrazione o di annullare e/o sospendere una registrazione precedentemente accettata, in qualsiasi momento a propria discrezione e senza preavviso, qualora lo ritenga inopportuno, offensivo o contrario a questi Termini e Condizioni d’uso, senza essere obbligato a comunicare o spiegare i motivi della sua decisione e senza che ciò generi alcun diritto al risarcimento, rimborso o indennizzo.</p>
      <p>La sospensione o cancellazione di un account per cause addebitabili all’Utente non esonera l’Utente dal pagamento dei compensi o prezzi eventualmente applicabili.</p>

      <h2>3.4. PROPRIETÀ INTELLETTUALE</h2>
      <p>Il Titolare detiene e si riserva espressamente ogni diritto di proprietà intellettuale sui contenuti disponibili su questo Sito Web, di proprietà o forniti dal Titolare o suoi licenzianti.</p>
      <p>Tutti i diritti sono riservati e gli Utenti non sono autorizzati ad usare i suddetti contenuti in alcun modo, salvo il caso in cui sia necessario od implicito nel corretto utilizzo del servizio.</p>
      <p>È fatto divieto agli Utenti di copiare, scaricare, condividere oltre i limiti sotto specificati, modificare, tradurre, elaborare, pubblicare, trasmettere, vendere, concedere sottolicenze, trasformare, trasferire/alienare a terze parti o creare opere derivate a partire dal contenuto disponibile su questo Sito Web, di permettere a terze parti di intraprendere tali attività tramite il proprio account Utente o dispositivo, anche a propria insaputa.</p>
      <p>Ove espressamente indicato su questo Sito Web, l’Utente è autorizzato a scaricare, copiare e/o condividere determinati contenuti, resi disponibili esclusivamente per scopi personali e non commerciali, a condizione che sia osservata l’attribuzione della proprietà intellettuale, nonché l’indicazione di ogni altra circostanza rilevante richiesta dal Titolare.</p>
      <p>Restano ferme le limitazioni ed esclusioni previste dalla normativa sul diritto d’autore.</p>
      <p>L’uso non autorizzato e/o la violazione dei diritti di proprietà intellettuale e industriale esistenti su https://megafitnesscommunity.it/get-now/ e/o i suoi contenuti, compresi nomi commerciali e design industriali, daranno posto alle corrispondenti responsabilità legali.</p>

      <h2>3.5. ACCESSO A RISORSE FORNITE DA TERZI</h2>
      <p>Gli Utenti potrebbero avere accesso a risorse fornite da terzi nel presente Sito Web, riconoscendo ed accettando che il Titolare non ha alcun controllo su tali risorse e che, quindi, non risponde del loro contenuto e della loro disponibilità.</p>
      <p>Le condizioni applicabili alle risorse fornite da terzi, ivi incluse quelle applicabili a eventuali concessioni di diritti su contenuti, sono determinate dagli stessi terzi e regolate nei relativi termini e condizioni o, in loro assenza, dalla legge a loro applicabile.</p>

      <h2>3.6. USO AMMESSO </h2>
      <p>Il presente Sito Web ed i servizi con esso offerti possono essere usati esclusivamente per gli scopi per i quali sono proposti, secondo gli attuali Termini e Condizioni d’uso, oltre che ai sensi della legge applicabile.</p>
      <p>L’Utente, mentre utilizza i contenuti del presente Sito Web e/o dei servizi con esso offerti, è l’esclusivo responsabile di eventuali violazioni della legge, dei regolamenti o dei diritti di terzi.</p>
      <p>Pertanto, il Titolare si riserva la facoltà di adottare ogni misura idonea a proteggere i propri interessi legittimi, ed in particolare di negare all’Utente l’accesso a questo Sito Web, risolvere contratti, denunciare ogni attività censurabile svolta tramite il presente Sito Web alle autorità competenti, ogniqualvolta sussista il sospetto che l’Utente ponga in essere violazioni di legge, regolamenti, diritti di terzi e/o i Termini e Condizioni d’uso, in particolare, mettendo in atto una delle seguenti attività riportate a titolo esemplificativo:</p>
      <ul>
        <li>fingere di soddisfare un qualsiasi requisito di accesso a questo Sito Web o di utilizzo dei servizi in esso offerti, come ad esempio dichiarare di avere la maggiore età o la qualifica di Consumatore;</li>
        <li>nascondere la propria identità, usare abusivamente l’account di un altro Utente, utilizzare l’identità altrui o fingere di agire in nome di un terzo, salvo autorizzazione prestata da tale terzo;</li>
        <li>diffamare, minacciare, abusare, usare pratiche violente intimidatorie, utilizzare trash talking o diffondere o pubblicare contenuti illeciti e osceni, minacciare o violare i diritti di terzi in qualsiasi altro modo, compresi segreti di stato o militari, commerciali, professionali o dati personali;</li>
        <li>promuovere odio, razzismo, discriminazione, pornografia o attività violente o che possono mettere a rischio la vita e/o l’incolumità propria o di qualsiasi altro Utente, nonché pubblicare contenuti che promuovono, esaltano o illustrano atteggiamenti autodistruttivi o violenti;</li>
        <li>mettere alla prova, analizzare o testare la vulnerabilità di questo Sito Web, i servizi e le reti collegate al predetto sito Web, oppure violare la sicurezza o le procedure di autenticazione del medesimo Sito Web, compresi installare, integrare, caricare o altrimenti incorporare malware, virus, spam, diffondere pubblicità illecita, phishing, frode a danno di terzi;</li>
        <li>tentare di disgregare o manomettere l’infrastruttura tecnologica in modo tale da causare un danno o un pericolo all’integrità di questo Sito Web o dei dispositivi di altri Utenti;</li>
        <li>implementare processi automatizzati di estrazione, raccolta o captazione di informazioni, dati e/o contenuti da questo Sito Web e da tutte le relative estensioni digitali, se non espressamente autorizzati dal Titolare;</li>
        <li>diffondere o pubblicare contenuti falsi o che possono provocare ingiustificato allarme, nonché usare questo Sito Web per promuovere, vendere o pubblicizzare prodotti o servizi di qualsiasi tipo in qualsiasi modo;</li>
        <li>pubblicare, diffondere o altrimenti offrire contenuti tutelati dalla normativa sulla proprietà intellettuale, compresi brevetti, marchi e diritto d’autore, senza l’autorizzazione del titolare dei diritti stessi;</li>
        <li>raccogliere o estrarre informazioni personali ed identificative di altri Utenti, compresi indirizzi di posta elettronica o recapiti, per scopi diversi da quelli di questo Sito Web, violando le impostazioni di riservatezza degli account di altri Utenti mediante il presente Sito Web o in qualsiasi altro modo;</li>
      </ul>

      <h1>4. TERMINI E CONDIZIONI DI VENDITA</h1>
      <p>Il presente Sito Web propone alcuni servizi/prodotti a pagamento, come di seguito disposto.</p>

      <h2>4.1. DESCRIZIONE DEL PRODOTTO</h2>
      <p>Le caratteristiche dei prodotti, quali prezzi, descrizioni e disponibilità, sono specificate durante la procedura d’acquisto, nelle rispettive sezioni di questo Sito Web, e sono soggette a modifica senza preavviso e in modo unilaterale da parte del Titolare.</p>
      <p>Nonostante i suddetti prodotti siano presentati con la massima accuratezza tecnicamente possibile, la rappresentazione degli stessi sul presente Sito Web tramite qualsiasi mezzo (inclusi, a seconda dei casi, materiali grafici, immagini, colori, suoni) è da intendersi come mero riferimento e non implica alcuna garanzia in merito alle caratteristiche del prodotto acquistato.</p>
      <p>Attualmente il Titolare propone prevalentemente i seguenti prodotti, meglio descritti nel Sito Web:</p>
      <ul>
        <li>Mega Fitness 1 mese: riservato accesso mensile alla piattaforma, condivisione di scheda settimanale di workout, video degli allenamenti interattivi, consigli di alimentazione, diretta mensile, possibilità di condividere opinioni mediante commenti scritti;</li>
        <li>Mega Fitness 3 mesi: riservato accesso trimestrale alla piattaforma, condivisione di scheda settimanale di workout, video degli allenamenti interattivi, consigli di alimentazione, diretta mensile, possibilità di condividere opinioni mediante commenti scritti.</li>
      </ul>

      <h2>4.2. PROCEDURA D’ACQUISTO</h2>
      <p>La procedura d’acquisto è costituita da tutte le fasi comprese fra la scelta del prodotto e l’inoltro dell’ordine dello stesso.</p>
      <p>La procedura d’acquisto comprende i seguenti passaggi:</p>
      <ul>
        <li>gli Utenti hanno la facoltà di scegliere il prodotto desiderato e di verificare la loro scelta d’acquisto;</li>
        <li>dopo aver esaminato le informazioni visibili nella scelta d’acquisto, gli Utenti possono inoltrare l’ordine di acquisto, determinando la conclusione del contratto con il Titolare;</li>
        <li>tale invio dell’ordine da parte dell’Utente comporta per quest’ultimo l’obbligo di pagare prezzo, tasse ed eventuali ulteriori oneri e spese, così come specificato nella pagina dell’ordine di acquisto;</li>
        <li>qualora l’acquisto del prodotto scelto richieda un contributo attivo da parte dell’Utente, come la fornitura di informazioni o dati personali, specificazioni o richieste particolari, l’inoltro dell’ordine costituisce in capo all’Utente anche l’obbligo di collaborare di conseguenza;</li>
        <li>una volta inoltrato l’ordine di acquisto da parte degli Utenti, questi riceveranno una conferma di ricezione dell’ordine.</li>
      </ul>
      <p>Tutte le notifiche relative alla procedura d’acquisto sopra descritta saranno inviate all’Utente all’indirizzo di posta elettronica fornito a tal fine dal medesimo, nel rispetto della legge aplicabile.</p>

      <h2>4.3. PREZZI E MEZZI DI PAGAMENTO</h2>
      <p>Durante la procedura d’acquisto, ma prima dell’inoltro dell’ordine relativo, gli Utenti vengono informati chiaramente di tutte le commissioni, tasse e costi (comprese eventuali spese di spedizione) che saranno loro addebitati, escluse le commissioni bancarie e/o finanziarie connesse ai mezzi di pagamento utilizzati.</p>
      <p>I prezzi su questo Sito Web includono tutte le commissioni, tasse e costi applicabili, escluse le commissioni bancarie e/o finanziarie connesse ai mezzi di pagamento utilizzati.</p>
      <p>Attualmente i prezzi dei prodotti proposti dal Titolare, meglio descritti nel Sito Web, sono i seguenti:</p>
      <ul>
        <li>Mega Fitness 1 mese: €.19,90;</li>
        <li>Mega Fitness 3 mesi: €.49,90.</li>
      </ul>
      <p>I mezzi di pagamento sono attualmente i seguenti:</p>
      <ul>
        <li>PayPal</li>
        <li>carta di credito.</li>
      </ul>
      <p>Nella relativa sezione di questo Sito Web, la procedura d’acquisto riporta i dettagli relativi ai mezzi di pagamento accettati. Alcuni mezzi di pagamento sono legati ad ulteriori condizioni o comportano costi aggiuntivi. </p>
      <p>Tutti i pagamenti vengono gestiti autonomamente da servizi terzi. Pertanto, il presente Sito Web non raccoglie dati relativi al pagamento, come ad esempio i numeri di carta di credito, ma riceve una notifica relativa al buon esito del pagamento.</p>
      <p>Nel caso in cui il pagamento effettuato con uno dei mezzi disponibili fallisca o venga rifiutato dal fornitore di servizi di pagamento, il Titolare non è obbligato a eseguire l’ordine. Eventuali costi o commissioni derivanti dal pagamento fallito o rifiutato sono esclusivamente a carico dell’Utente.</p>

      <h2>4.4. RISERVE </h2>
      <p>L’Utente acquista la proprietà e i relativi diritti d’uso dei prodotti ordinati, solamente dopo la ricezione del pagamento del prezzo integrale di acquisto da parte del Titolare.</p>

      <h2>4.5. CONSEGNA</h2>
      <p>Il contenuto digitale acquistato sul presente Sito Web viene consegnato, solamente dopo la ricezione del pagamento del prezzo integrale di acquisto da parte del Titolare, tramite download sul dispositivo o sui dispositivi scelti dall’Utente, fatte salve diverse espresse specificazioni.</p>
      <p>Gli Utenti riconoscono ed accettano che, per scaricare e/o utilizzare il prodotto acquistato, è possibile che il dispositivo o i dispositivi prescelti debbano soddisfare i requisiti tecnici indicati su questo Sito Web.</p>
      <p>Gli Utenti riconoscono ed accettano che la possibilità di scaricare il prodotto acquistato potrebbe essere limitata nel tempo e nello spazio, anche a seconda del tipo di acquisto.</p>

      <h1>5. PRIVACY POLICY</h1>
      <p>Il presente Sito Web elabora le informazioni rilasciate facoltativamente dagli Utenti, al fine di fornire il servizio richiesto. </p>
      <p>I dati forniti saranno conservati per tutto il tempo in cui viene mantenuto il rapporto commerciale o per gli anni necessari per ottemperare agli obblighi di legge. I dati non saranno trasferiti a terzi, ad eccezione dei casi in cui sussista un obbligo di legge. L’Utente mantiene il diritto di accedere ai propri dati personali, rettificare dati inesatti o richiederne la cancellazione quando i dati non sono più necessari, semplicemente inviando relativa richiesta, in via alternativa, agli indirizzi info@goldinternational.co - goldinternational.co</p>
      <p>Nel caso in cui un Utente si collegasse a un link o banner, diverso dal presente Sito Web, deve sapere che visiterà un altro Sito Web su Internet, le cui relative regole di utilizzo e di Privacy Policy non sono sotto il controllo del Titolare, il quale, pertanto, non detiene alcuna responsabilità in merito.</p>
      <p>Si prega di leggere attentamente la completa Privacy Policy visionabile come segue: <a href="https://www.iubenda.com/privacy-policy/16918108">https://www.iubenda.com/privacy-policy/16918108</a></p>

      <h1>6. COOKIE POLICY</h1>
      <p>In alcune occasioni, nel presente Sito Web, potrebbero essere utilizzati “cookie”; cioè piccoli file di testo che vengono generati sui dispositivi dell’Utente e che consentono di ottenere informazioni, come la data e l’ora dell’ultima volta in cui l’Utente ha visitato il presente Sito Web, senza che il Titolare abbia il controllo e/o qualsiasi responsabilità per tali informazioni.</p>
      <p>Si prega di leggere attentamente la completa Cookie Policy visionabile come segue: <a href="https://www.iubenda.com/privacy-policy/16918108/cookie-policy">https://www.iubenda.com/privacy-policy/16918108/cookie-policy</a></p>

      <h1>7. DISDETTA</h1>
      <p>L’Utente che ha acquistato uno dei prodotti proposti dal Titolare, attualmente Mega Fitness 1 mese e Mega Fitness 3 mesi, come sopra descritti, ha la facoltà di comunicare disdetta, cioè può rifiutarsi di continuare il rapporto contrattuale prescelto, in qualsiasi momento e senza dare particolari giustificazioni, mandando una mail con dichiarazione inequivocabile della propria intenzione di dare disdetta a info@goldinternational.co o cliccando su “unsubscribe” nell’apposito form, all’interno del presente Sito Web.</p>
      <p>Dopo aver annullato l’iscrizione a qualsiasi abbonamento, il Titolare eliminerà tutti i dati personali dell’Utente, ad eccezione di quelli che devono essere conservati in conformità con le leggi applicabili.</p>
      <p>In caso di disdetta, come sopra indicato, l’Utente non avrà diritto ad alcun rimborso, ma avrà comunque accesso al presente Sito Web per tutta la durata dell’abbonamento acquistato (ad esempio non esaustivo: per 1 mese o 3 mesi, dal momento dell’acquisto del prodotto, a prescindere dalla data di comunicazione della disdetta).</p>
      <p>In caso di mancata disdetta, come sopra indicato, vi è rinnovo tacito dell’acquisto del prodotto prescelto, via via per uguale periodo descritto nell’abbonamento stesso, senza limiti temporali, restando l’obbligo dell’Utente di versare i pagamenti corrispondenti e fatte salve tutte le disposizioni dei presenti Termini e Condizioni d’uso.</p>

      <h1>8. ESONERO DI RESPONSABILITÀ </h1>
      <p>L’Utente esonera da ogni tipo di responsabilità il Titolare e i suoi sottoposti, affiliati, funzionari, agenti, contitolari del marchio, partner e dipendenti da qualsivoglia rivendicazione o pretesa, senza alcuna limitazione, oneri e spese legali, avanzata da Utenti e da terzi, a causa di o in collegamento con comportamenti colposi quali l’utilizzo o la connessione al servizio, la violazione dei presenti Termini e Condizioni d’uso, la violazione di diritti di terze parti o di leggi da parte dell’Utente e/o dei suoi affiliati, nella misura di legge.</p>
      <p>Il Titolare, infatti, si limita a fornire agli Utenti l’infrastruttura tecnica e le funzionalità disponibili su questo Sito Web.</p>
      <p>Il Titolare non ha la funzione di intermediario, moderatore o promotore nelle interazioni, accordi o transazioni tra gli Utenti e declina, pertanto, ogni responsabilità per tali interazioni tra Utenti, e per l’adempimento ad eventuali obbligazioni da parte degli Utenti, i quali accettano tale disposizione.</p>
      <p>L’Utente esonera il Titolare da ogni tipo di responsabilità per i consigli di terapia medica o fisica. Le malattie e gli infortuni eventuali degli Utenti devono essere curati dai rispettivi professionisti, dalla piattaforma verranno forniti solo consigli nutrizionali e sportivi.</p>
      <p>Il Titolare, inoltre, non si assume alcuna responsabilità per quanto riguarda:</p>
      <ul>
        <li>danni o perdite derivanti da interruzioni o malfunzionamenti di questo Sito Web dovuti a cause di forza maggiore, casi fortuiti o eventi imprevisti ed imprevedibili e, in ogni caso, indipendenti dalla volontà e fuori dal controllo del Titolare, quali, a mero titolo esemplificativo, guasti o interruzioni delle linee telefoniche o elettriche, della connessione Internet e/o di altri mezzi di trasmissione, inaccessibilità di siti web, scioperi, calamità naturali, virus e attacchi informatici, interruzioni di fornitura dei prodotti, servizi o applicazioni di terzi;</li>
        <li>eventuali perdite che non siano conseguenza diretta di una violazione dei Termini e Condizioni d’uso da parte del Titolare.</li>
      </ul>
      <p>Il mancato esercizio di diritti di legge o pretese, derivanti da questi Termini e Condizioni d’uso, da parte del Titolare non costituisce rinuncia agli stessi. </p>

      <h1>9. MODIFICA E AGGIORNAMENTO DEI TERMINI E CONDIZIONI D’USO</h1>
      <p>Il Titolare si riserva il diritto di modificare e/o aggiornare unilateralmente, in qualsiasi momento e senza preavviso, i presenti Termini e Condizioni d’uso. </p>
      <p>In tal caso, il Titolare darà opportuna notizia agli Utenti delle modifiche e/o aggiornamenti, i quali genereranno effetti sui rapporti concordati fra le parti non in modo retroattivo.</p>
      <p>Per garantire il miglior livello di servizio possibile, senza poter generare qualsiasi pretesa risarcitoria, il Titolare si riserva la facoltà di interrompere il servizio per finalità di manutenzione, aggiornamenti di sistema o per qualsiasi altra modifica, comunicandolo agli Utenti.</p>
      <p>Nei limiti di legge, il Titolare si riserva di sospendere o terminare completamente il servizio. In caso di chiusura dei servizi offerti, il Titolare si adopererà affinché gli Utenti possano estrarre i propri dati personali e le informazioni secondo le disposizioni di legge.</p>
      <p>L’utilizzo continuato del servizio implica l’accettazione dell’Utente dei Termini aggiornati. Se l’Utente non desidera accettare le modifiche, deve cessare l’utilizzo del servizio. </p>
      <p>La versione del servizio offerto prima della modifica e/o aggiornamento non è più applicabile.</p>
      <p>Se richiesto dalla legge applicabile, il Titolare specificherà la data entro cui le modifiche ai Termini e Condizioni d’uso entreranno in vigore.</p>

      <h1>10. CESSIONE DEL CONTRATTO</h1>
      <p>Il Titolare si riserva il diritto di trasferire, cedere, disporre o appaltare singoli o tutti i diritti e le obbligazioni secondo questi Termini e Condizioni d’uso, avendo riguardo per gli interessi legittimi degli Utenti.</p>
      <p>Si applicano le disposizioni relative alla modifica di questi Termini e Condizioni d’uso.</p>
      <p>L’Utente non è autorizzato a cedere o trasferire i propri diritti e le proprie obbligazioni secondo i Termini e Condizioni d’uso senza il consenso scritto del Titolare.</p>

      <h1>11. NULLITÀ DELLE CLAUSOLE</h1>
      <p>Se una qualsiasi clausola, inclusa nei presenti Termini e Condizioni d’uso, è dichiarata, totalmente o parzialmente, nulla o inefficace, tale nullità o inefficacia influenzerà solo detta disposizione o la parte di essa che è nulla o inefficace. Il resto delle disposizioni riportate, pertanto, rimane valido.</p>

      <h1>12. LEGISLAZIONE E GIURISDIZIONE APPLICABILI</h1>
      <p>Il presente Sito Web è creato e gestito dal Titolare, con sede a Dubai, Emirati Arabi Uniti.</p>
      <p>Pertanto, i presenti Termini e Condizioni d'uso sono disciplinati dalla legge di Dubai e, ove applicabile, dalla legge federale degli Emirati Arabi Uniti, senza dar luogo ad alcun principio di conflitto normativo. </p>
      <p>Per qualsiasi domanda o divergenza che possa sorgere in merito ai suddetti contenuti dei Termini e Condizioni d’uso, attività, prodotti e servizi contrattati, è competente in via esclusiva il giudice del luogo in cui il Titolare è stabilito, quindi la giurisdizione competente è quella di Dubai.</p>
      <p>Gli Utenti possono segnalare, ai recapiti indicati nell’articolo 3 - Identificazione del responsabile (info@goldinternational.co goldinternational.co), eventuali controversie e reclami al Titolare, che cercherà di risolvere in via amichevole.</p>
    </TermsContainer>
  );
}