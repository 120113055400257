import { PrimaryBackground, StyledH3, StyledP, PrimaryButton, StyledH4 } from "../components/StyledComponents";
import { useTranslation } from "react-i18next";
import { HeaderLogo } from "../components/HeaderLogo";
import { isIOS } from "../helpers/fullscreen";
import { useEffect } from "react";

const src = `../welcome_video.m4v${isIOS ? '#t=0.001' : ''}`

export function StartScreen({ history }) {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem('start-viewed', 'true')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PrimaryBackground direction="column">
      <HeaderLogo />
      <video width="100%" height="320" controls controlsList="nodownload">
        <source src={src} />
      </video>
      <StyledH3 bottom={1} top={3}> {t('LETS_START')} </StyledH3>
      <StyledP color="purple"> {t('START_SENTENCE')} </StyledP>
      <StyledH4 bottom={1} top={3}> {t('START_FROM_ZERO')} </StyledH4>
      <StyledP color="purple" bottom={3}> {t('START_BEGINNER_SENTENCE')} </StyledP>
      <PrimaryButton
        auto="auto"
        onClick={() => history.push("/workouts?level=beginner")}
        bottom={3}
        white
      >
        {t('GO_TO_BEGINNER_VIDEO')}
      </PrimaryButton>
      <PrimaryButton
        onClick={() => history.push("/")}
      >
        {t('GO_HOME')}
      </PrimaryButton>
    </PrimaryBackground>
  );
}