export function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncate(text = '', LIMIT = 100) {
  if (text.length <= LIMIT) {
    return text;
  }
  const subString = text.slice(0, LIMIT - 1);
  return subString.slice(0, subString.lastIndexOf(' ')) + '...';
}