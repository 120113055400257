import React from "react";
import { Route, Redirect } from "react-router-dom";
import isEmpty from "lodash.isempty";

function PrivateRoute(props) {
  const {
    component: Component,
    isAuthenticated = false,
    publicRedirect = "/",
    profile,
    path = "",
    ...rest
  } = props;

  const { location } = rest || {}
  const { search = '' } = location || {}

  const {
    sex = "",
    birthdate = null,
    weight = 0,
    height = 0,
  } = profile || {};
  
  const onboardingCompleted = sex && birthdate && weight && height;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <Redirect
              push={false}
              to={{
                pathname: publicRedirect,
                search,
                state: { from: props.location },
              }}
            />
          );
        }

        if (!isEmpty(profile)) {
          /* NON C'E' la conferma email */
          // if (!confirmed) {
          //   return (
          //     <Redirect
          //       push={false}
          //       to={{
          //         pathname: "/account-checking",
          //         search,
          //         state: { from: props.location },
          //       }}
          //     />
          //   );
          // }

          if (profile.is_subscribed && !onboardingCompleted && !path.startsWith("/onboarding")) {
            return (
              <Redirect
                push={false}
                to={{
                  pathname: "/onboarding",
                  search,
                  state: { from: props.location },
                }}
              />
            );
          }

          if (
            !profile.is_subscribed &&
            !path.startsWith("/subscription-renewal") &&
            !path.startsWith("/plan-selection") &&
            !path.startsWith("/free-trial") &&
            !path.startsWith("/contact") &&
            !path.startsWith("/pay-plan")
          ) {
            return (
              <Redirect
                push={false}
                to={{
                  pathname: "/subscription-renewal",
                  search,
                  state: { from: props.location },
                }}
              />
            );
          }
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
