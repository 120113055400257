import i18n from "i18next";
import { getErrorIdMessage } from "./utils";

export const formatError = (error) => {
  const { response = {} } = error;
  const { status } = response;
  const errorId = getErrorIdMessage(error);

  if (errorId) {
    const translateError = i18n.t(errorId);
    if (translateError !== errorId) {
      return translateError;
    }
  }

  if (status === 401) {
    return i18n.t("Auth.form.error.invalid");
  }

  return i18n.t("GENERIC_ERROR");
};
