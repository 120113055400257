import { TermsContainer } from './../StyledComponents';

export function TermsEn() {
  return (
    <TermsContainer>
      <h1>1.	INTRODUCTION</h1>
      <p>These Terms and Conditions are used to regulate the use of this Website and any legal relationship between the User, who uses the services offered here, and the Owner of the Website.</p>
      <p>The services can be updated or evolving, with the development of any new tools.</p>
      <p>These provisions will be updated and/or modified by publication on the Website <a href="https://megafitnesscommunity.it/get-now/">https://megafitnesscommunity.it/get-now/</a>, only if necessary. We therefore recommend that you read on regular basis the terms of use and pay attention to them.</p>
      <p>In particular, please note that certain provisions of these Terms and Conditions of use may only be applicable to certain categories of Users. Some provisions may apply only to Consumers or only to Users who do not act as Consumers. Such limitations are always explicitly mentioned in each related clause. If not mentioned, the clauses apply to all Users and according to the applicable law.</p>
      <p>Therefore, before using the services, the User must read carefully and, if required, accept the corresponding specific conditions of each service, which will be published on the same media as the current Terms and Conditions of use.</p>
      <p>The use of the services implies full acceptance by the User of the provisions included in these Terms and Conditions of Use, Privacy Policy (available on <a href="https://www.iubenda.com/privacy-policy/16918108">https://www.iubenda.com/privacy-policy/16918108</a>) and, where applicable, the specific conditions.</p>
      <p>If the User does not agree with the provisions of the above-mentioned conditions, he/she should not continue to use the services offered here.</p>

      <h1>2.	IDENTIFICATION OF THE RESPONSIBLE</h1>
      <p>The manager and Owner of https://megafitnesscommunity.it/get-now/ is:</p>
      <p>GOLD INTERNATIONAL FCZO DUBAI TRADE LICENSE NR:5295</p>
      <p>E-mail address of the Owner: info@goldinternational.co goldinternational.co</p>

      <h1>3.	CONDITIONS OF USE</h1>
      <p>The Terms of Use described in this section have general validity, unless otherwise specified.</p>
      <p>Additional conditions of use or access applicable in particular situations are expressly indicated in this document.</p>
      <p>By using this Website, the User acknowledges the lack of restrictions relating to Users with respect to the fact that they are Consumers or Professional Users.</p>

      <h2>3.1	REGISTRATION</h2>
      <p>To take advantage of the service offered, the User can open an account, indicating all the data and information required in a complete and truthful manner.</p>
      <p>It is not possible to use the service offered without opening a User account.</p>
      <p>It is the responsibility of the Users to keep their access credentials securely and to safeguard their confidentiality to prevent unauthorized access. For this purpose, Users must choose a password that corresponds to the highest level of security available on this Website.</p>
      <p>The account and password of every User are unique, personal and non-transferable. Therefore, the User cannot communicate them to third parties or assign their use. By creating an account, therefore, the User agrees to be fully responsible for all the operations performed by him/her and/or for any operation carried out by a device associated with his/her account.</p>
      <p>Users are required to inform the Owner immediately and unambiguously using the contact details indicated in this document(info@goldinternational.co goldinternational.co), if they believe that their personal information, such as the User account, login credentials or personal data, have been violated, illegally disseminated or stolen.</p>

      <h2>3.2	ACCOUNT CLOSURE</h2>
      <p>The User is free to close his/her own account at any time by contacting the Owner at the addresses indicated in this document (info@goldinternational.co goldinternational.co).</p>

      <h2>3.3	ACCOUNT SUSPENSION AND CANCELLATION</h2>
      <p>The Owner reserves the right to refuse any registration request or to cancel and/or suspend a previously accepted registration at any time at its discretion and without notice, if inappropriate, offensive or contrary to these Terms and Conditions of use, without being obliged to communicate or explain the reasons for its decision and without this generating any right to compensation, reimbursement or compensation.</p>
      <p>The suspension or cancellation of an account for reasons attributable to the User does not exempt the User from paying any fees or prices that may be applicable</p>

      <h2>3.4	INTELLECTUAL PROPERTY</h2>
      <p>The Owner expressly holds and reserves all intellectual property rights on the content available on this Website, owned or provided by the Owner or its licensors.</p>
      <p>All rights are reserved and Users are not authorized to use the above-mentioned contents in any way, except in case it is necessary or implicit in the correct use of the service.</p>
      <p>It is forbidden to the Users to copy, download, share beyond the limits specified below, modify, translate, process, publish, transmit, sell, grant sub-licenses, transform, transfer/alienate to third parties or create derivative works from the content available on this Website, to allow third parties to undertake such activities through their User account or device, even without their knowledge.</p>
      <p>Where expressly indicated on this Website, the User is authorized to download, copy and/or share certain contents, made available exclusively for personal and non-commercial purposes, provided that the attribution of intellectual property is observed, as well as the indication of any other relevant circumstance requested by the Owner.</p>
      <p>The limitations and exclusions according to the copyright law remain valid.</p>
      <p>Unauthorized use and/or violation of intellectual and industrial property rights on https://megafitnesscommunity.it/get-now/ and/or its contents, including trade names and industrial designs, will give rise to the corresponding legal responsibilities.</p>

      <h2>3.5 ACCESS TO RESOURCES PROVIDED BY THIRD PARTIES</h2>
      <p>Users may have access to resources provided by third parties on this Website, acknowledging and accepting that the Owner has no control over these resources and therefore is not responsible for their content and availability.</p>
      <p>The conditions applicable to the resources provided by third parties, including those applicable to any concessions of rights to content, are determined by the third parties themselves and regulated in the relative terms and conditions or, in their absence, by the law applicable to them.</p>

      <h2>3.6	USE ALLOWED</h2>
      <p>This Website and the services offered with it may be used exclusively for the purposes for which they are proposed, according to the current Terms and Conditions of use, as well as in accordance with applicable law.</p>
      <p>The User, while using the contents of this Website and/or the services offered with it, is solely responsible for any violations of the law, regulations or rights of third parties.</p>
      <p>Therefore, the Owner reserves the right to adopt any suitable measure to protect its legitimate interests and, in particular, to deny the User access to this Website, terminate contracts, report any censurable activity carried out through this Website at competent authorities, whenever there is a suspicion that the User violates the law, regulations, rights of third parties and/or the Terms and Conditions of use by implementing one of the following activities listed as examples:</p>
      <ul>
        <li>pretending to satisfy any requirement for access to this Website or for the use of the services offered therein, such as, for example, declaring that you are of legal age or qualification as a Consumer;</li>
        <li>hiding your identity, misuse another user's account, using the identity of others or pretending to act in the name of a third party, unless authorized by that third party;</li>
        <li>defaming, threatening, abusing, using violent intimidating practices, using trash talking or disseminating or publishing illicit and obscene content, threatening or violating the rights of third parties in any other way, including state or military, commercial, professional secrets or personal data;</li>
        <li>promoting hatred, racism, discrimination, pornography or violent activities or activities that may endanger the life and/or safety of oneself or any other User, as well as publishing content that promotes, exalts or illustrates self-destructive or violent attitudes;</li>
        <li>analyzing or testing the vulnerability of this Website, the services and networks connected to this Website, or violating the security or authentication procedures of this Website, including installing, integrating, uploading or adding malware, viruses, spam, illegal advertising, phishing, fraud that cause damages to third parties;</li>
        <li>trying to crush or tamper with the technological infrastructure in such a way as to cause damage or danger to the integrity of this Website or the devices of other Users;</li>
        <li>implementing automated processes for extracting, collecting or capturing information, data and/or content from this Website and all related digital extensions, unless expressly authorized by the Owner;</li>
        <li>disseminating or publishing false content or content that may cause unjustified alarm, as well as using this Website to promote, sell or advertise products or services of any kind in any way;</li>
        <li>publishing, disseminating or otherwise offering content protected by intellectual property legislation, including patents, trademarks and copyrights, without the authorization of the owner of that rights;</li>
        <li>collecting or extracting personal and identifying information of other Users, including e-mail addresses or contact details, for purposes other than those of this Website, violating the confidentiality settings of the accounts of other Users through this Website or in any other way.</li>
      </ul>

      <h1>4	TERMS AND CONDITIONS OF SALE</h1>
      <p>This Website offers some paid services / products, as described below.</p>

      <h2>4.1. PRODUCT DESCRIPTION</h2>
      <p>The characteristics of the products, such as prices, descriptions and availability, are specified during the purchase procedure, in the respective sections of this Website, and are subject to change without notice and unilaterally by the Owner.</p>
      <p>Although the above-mentioned products are presented with the utmost accuracy technically possible, their representation on this Website by any means (including, as the case may be, graphic materials, images, colors, sounds) is intended as a mere reference and does not imply any guarantee regarding the characteristics of the purchased product.</p>
      <p>Currently the Owner mainly offers the following products, better described on the Website:</p>
      <ul>
        <li>Mega Fitness 1 month: reserved monthly access to the platform, sharing of weekly workout schedule, interactive training videos, nutrition advice, monthly live stream, possibility to share opinions through written comments;</li>
        <li>Mega Fitness 3 months: reserved three-month-access to the platform, sharing of weekly workout sheets, interactive training videos, nutrition advice, monthly live stream, possibility to share opinions through written comments.</li>
      </ul>

      <h2>4.2. PURCHASE PROCEDURE</h2>
      <p>The purchase procedure consists of all the stages between choosing the product and placing the order.</p>
      <p>The purchase procedure includes the following steps:</p>
      <ul>
        <li>Users have the right to choose the desired product and to verify their purchase choice;</li>
        <li>after examining the information visible in the purchase choice, Users can submit the purchase order, resulting in the conclusion of the contract with the Owner;</li>
        <li>sending the order by the User entails for the User the obligation to pay the price, taxes and any additional charges and expenses, as specified on the purchase order page;</li>
        <li>if the purchase of the chosen product requires an active contribution from the User, such as the provision of information or personal data, specifications or special requests, the forwarding of the order also constitutes an obligation for the User to collaborate consequently;</li>
        <li>once the purchase order has been submitted by the Users, the Users will receive a confirmation of receipt of the order.</li>
      </ul>
      <p>All notifications related to the purchase procedure described above will be sent to the User at the e-mail address provided for this purpose by the User, in compliance with the applicable law.</p>

      <h1>4.3 PRICES AND PYEMENT METHODS</h1>
      <p>During the purchase process, but before the order is forwarded, Users are clearly informed of all commissions, taxes and costs (including any shipping costs) that will be charged to them, excluding bank and / or financial commissions connected to the means of payment used.</p>
      <p>The prices on this Website include all applicable commissions, taxes and costs, excluding bank and/or financial commissions associated with the means of payment used.</p>
      <p>Currently, the prices of the products proposed by the Owner, better described on the Website, are as follows:</p>
      <ul>
        <li>Mega Fitness 1 month: 19,90€</li>
        <li>Mega Fitness 3 months: 49,90€</li>
      </ul>
      <p>The means of payment are currently the following:</p>
      <ul>
        <li>PayPal</li>
        <li>Credit card.</li>
      </ul>
      <p>In the relevant section of this Website, the purchase procedure reports details of the accepted payment methods. Some payment methods are linked to additional conditions or involve additional costs.</p>
      <p>All payments are managed independently by third party services. Therefore, this Website does not collect payment data, such as credit card numbers, but receives a notification relating to the successful completion of the payment.</p>
      <p>In the event that the payment made by one of the available means fails or is rejected by the payment service provider, the Owner is not obliged to execute the order. Any costs or commissions arising from the failed or refused payment are the sole responsibility of the User.</p>

      <h2>4.4 RESERVES</h2>
      <p>The User acquires the ownership and related rights of use of the products ordered, only after that the Owner has received the payment of the full purchase price.</p>

      <h2>4.5 DELIVERY</h2>
      <p>The digital content purchased on this Website is delivered, only after receipt of the payment of the full purchase price by the Owner, via download on the device or devices chosen by the User, unless otherwise expressly specified.</p>
      <p>Users acknowledge and accept that, in order to download and/or use the purchased product, it is possible that the device or devices chosen must meet the technical requirements indicated on this Website.</p>
      <p>Users acknowledge and accept that the possibility of downloading the purchased product may be limited in time and space, also depending on the type of purchase.</p>

      <h1>5	PRIVACY POLICY</h1>
      <p>This Website processes the information optionally released by Users, in order to provide the requested service.</p>
      <p>The data provided will be kept for as long as the commercial relationship is maintained or for the years necessary to comply with legal obligations. The data will not be transferred to third parties, except in cases where there is a legal obligation. The Users retain the right to access their personal data, rectify inaccurate data or request its cancellation when the data is no longer necessary, simply by sending the relevant request, to the addresse info@goldinternational.co or goldinternational.co</p>
      <p>In the event that a User connects to a link or banner other than this Website, he/she must know that he/she will visit another Website on the Internet whose relative rules of use and Privacy Policy are not under the control of the Owner, who, therefore, does not hold any responsibility in this regard.</p>
      <p>Please read carefully the complete Privacy Policy, available on: <a href="https://www.iubenda.com/privacy-policy/16918108">https://www.iubenda.com/privacy-policy/16918108</a></p>

      <h1>6	COOKIE POLICY</h1>
      <p>On some occasions, "cookies" may be used on this Website, i.e. small text files that are generated on the User's devices and that allow to obtain information, such as date and time of the last time the User visited this Website, without the Owner having control and/or any liability for such information.</p>
      <p>Please read carefully the complete Cookie Policy, available on: <a href="https://www.iubenda.com/privacy-policy/16918108/cookie-policy">https://www.iubenda.com/privacy-policy/16918108/cookie-policy</a></p>

      <h1>7	CANCELLATION</h1>
      <p>Users who have purchased one of the products offered by the Owner, Mega Fitness 1 month and Mega Fitness 3 months as described above, have the right to cancel, that is, they can refuse to continue the chosen contractual relationship at any time and without justifications, by sending an email with an unequivocal declaration of their intention to cancel to info@goldinternational.co or by clicking on "unsubscribe" in the appropriate form on this Website.</p>
      <p>After unsubscribing, the Owner will delete all the User's personal data, except for those that must be kept in accordance with applicable laws.</p>
      <p>In case of cancellation, as indicated above, the User will not be entitled to any refund, but he/she will still have access to this Website for the entire duration of the purchased subscription (for example, not exhaustive: for 1 month or 3 months, from the moment of purchase of the product, regardless of the date of communication of the cancellation).</p>
      <p>In case of non-cancellation, as indicated above, there is tacit renewal of the purchase of the chosen product, for the same period described in the subscription itself, without time limits, remaining the obligation of the User to pay the corresponding payments and notwithstanding other provisions of these Terms and Conditions of Use.</p>

      <h1>8	DISCLAIMER OF LIABILITY</h1>
      <p>The User exonerates the Owner and his subordinates, affiliates, officers, agents, co-owners of the brand, partners and employees from any kind of responsibility from any claim, without any limitation, legal fees or expenses, advanced by Users and third parties due to or in connection with culpable behavior such as use or connection to the service, the violation of these Terms and Conditions of use, the violation of third party rights or laws by the User and/or its affiliates, to the extent of the law.</p>
      <p>The Owner, in fact, limits himself to providing Users with the technical infrastructure and features available on this Website.</p>
      <p>The Owner does not have the function of intermediary, moderator or promoter in the interactions, agreements or transactions between Users and therefore the Owner declines any responsibility for such interactions between Users and for the fulfillment of any obligations by the Users, who accept this provision.</p>
      <p>The User exempts the Owner from any kind of responsibility for medical or physical therapy advice. Any illnesses or accidents of the Users must be treated by the doctor or other specialist. This platform will only provide nutritional and sports advice.</p>
      <p>Furthermore, the Owner assumes no responsibility for:</p>
      <ul>
        <li>damages or losses deriving from interruptions or malfunctions of this Website due to force majeure, or unforeseeable circumstances and, in any case, independent of the will and beyond the control of the Owner, such as, by way of example, breakdowns or interruptions in telephone or electrical lines, Internet connections and/or other means of transmission, inaccessibility of websites, strikes, natural disasters, viruses and computer attacks, interruptions in the supply of third party products, services or applications;</li>
        <li>any losses that are not a direct consequence of a violation of the Terms and Conditions of use by the Owner.</li>
      </ul>
      <p>Failure by the Owner to exercise legal rights or claims arising from these Terms and Conditions of use does not constitute a waiver of the same.</p>

      <h1>9	MODIFICATION AND UPDATING OF THE TERMS AND CONDITIONS OF USE</h1>
      <p>The Owner reserves the right to unilaterally modify and/or update these Terms and Conditions of use at any time and without notice.</p>
      <p>In that case, the Owner will give appropriate notice to Users of the changes and/or updates, which will generate effects on the relationships agreed between the parties not retroactively.</p>
      <p>To guarantee the best possible level of service, without generating any claim for compensation, the Owner reserves the right to interrupt the service for purposes of maintenance, system updates or any other changes, communicating it to Users.</p>
      <p>Within the limits of the law, the Owner reserves the right to suspend or completely terminate the service. In the event of closure of the services offered, the Owner will ensure that Users can extract their personal data and information according to the provisions of the law.</p>
      <p>Continued use of the service implies the User's acceptance of the updated Terms. If the User does not wish to accept the changes, he must cease using the service.</p>
      <p>The version of the service offered before the modification and/or update is no longer applicable.</p>
      <p>If required by applicable law, the Owner will specify the date by which the changes to the Terms and Conditions of Use will come into force.</p>

      <h1>10	CONTRACT ASSIGNEMENT</h1>
      <p>The Owner reserves the right to transfer, assign, dispose or contract out individual or all rights and obligations under these Terms and Conditions of use, having regard for the legitimate interests of Users.</p>
      <p>The provisions relating to the modification of these Terms and Conditions of use shall apply.</p>
      <p>Users are not authorized to assign or transfer their rights and obligations according to the Terms and Conditions of use without written consent of the Owner.</p>

      <h1>11	NULLITY OF CLAUSES</h1>
      <p>If any clause, included in these Terms and Conditions of use, is declared, totally or partially, null or ineffective, such nullity or ineffectiveness will affect only said provision or the part of it that is null or ineffective. The rest of the provisions reported, therefore, remain valid.</p>

      <h1>12	APPLICABLE LEGISLATION AND JURISDICTION</h1>
      <p>This Website is created and managed by the Owner, based in Dubai, United Arab Emirates.</p>
      <p>Therefore, these Terms and Conditions of Use are governed by Dubai law and, where applicable, the federal law of the United Arab Emirates, without giving effect to any principle of conflict of laws.</p>
      <p>For any question or divergence that may arise regarding the above-mentioned contents of the Terms and Conditions of use, activities, products and services contracted, the judge of the place where the Owner is established is exclusively competent, therefore the competent jurisdiction is that of Dubai.</p>
      <p>Users can report any disputes and complaints to the Owner, at the addresses indicated in Article 3 - Identification of the manager (info@goldinternational.co goldinternational.co), who will try to resolve them amicably.</p>
    </TermsContainer>
  );
}