import get from 'lodash.get'
import moment from 'moment'

export const capitalizeFirstChar = (text) => {
  if (!text) {
    return ''
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const getErrorIdMessage = error => {
  const { response = {} } = error;
  const { data } = response;
  const errorId = get(data, 'data[0].messages[0].id', '')
  return errorId
}

export const getPostTime = (date) => {
  const now = moment()
  const d = moment(date)
  const hours = now.diff(date, 'hour')
  if (Math.abs(hours) >= 24) {
    return d.format('L')
  }
  if (Math.abs(hours) >= 1) {
    return `${Math.abs(hours)}h`
  }
  const minutes = now.diff(date, 'minute')
  return `${minutes}min`
}

export const getWorkoutTime = (duration) => {
  if (!duration) {
    return `00' 00''`
  }
  const sec_num = parseInt(duration, 10); // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return `${minutes}' ${seconds}''`
}

export const getCommentTime = (date) => {
  const now = moment()
  const hours = now.diff(date, 'hour')
  if (Math.abs(hours) >= 24) {
    const days = now.diff(date, 'days')
    if (days < 7) {
      return `${days}d`
    }
    const weeks = now.diff(date, 'weeks')
    return `${weeks}w`
  }
  if (Math.abs(hours) >= 1) {
    return `${Math.abs(hours)}h`
  }
  const minutes = now.diff(date, 'minute') || 1
  return `${minutes}min`
}