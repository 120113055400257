export const generateLabels = (interval_count, interval = 'month') => {
  switch (interval_count) {
    case 1:
      if (interval.toLowerCase() === 'day') { 
        return {
          title: "DAILY",
          interval: "DAY",
          plan_text: "PLAN_TEXT_1_DAY",
        };
      }
      return {
        title: "MONTHLY",
        interval: "MONTH",
        plan_text: "PLAN_TEXT_1_MONTH",
      };
    case 3:
      return {
        title: "QUARTERLY",
        interval: "QUARTER",
        plan_text: "PLAN_TEXT_1_QUARTERLY",
      };
    case 6:
      return {
        title: "HALF_YEAR",
        interval: "HALF",
        plan_text: "PLAN_TEXT_1_HALF_YEAR",
      };
    default:
      return {
        title: "",
        interval: "",
        plan_text: "",
      };
  }
};

