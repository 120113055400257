import { Link } from "react-router-dom";
import { PrimaryBackground, PrimaryButton, StyledH3, StyledP } from "../../components/StyledComponents";
import { useTranslation } from "react-i18next";

export function OnboardingHome() {
  const { t } = useTranslation();
  return (
    <PrimaryBackground direction="column" padding="0">
      <img src="../../../onboarding-img.jpg" alt="onboarding-img" width="100%"></img>
      <StyledH3 bottom={1} top={3} left={3} right={3}>{t('COMPLETE_PROFILE')}</StyledH3>
      <StyledP color="purple" left={3} right={3}>
        {t('ONBOARDING_HOME_SENTENCE')}
      </StyledP>
      <Link to="/onboarding/step1" className="mt-auto px-24">
        <PrimaryButton bottom={3} top={3}>{t('CONTINUE')}</PrimaryButton>
      </Link>
    </PrimaryBackground>
  );
}