import {
  BackButton,
  FlexContainer,
  PrimaryBackground,
  PrimaryButton,
  StyledP,
} from "../components/StyledComponents";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useLoading } from "../providers/loading";
import { useAlert } from "../providers/alert";
import { useHistory } from "react-router-dom";
import { useAuth } from "../providers/auth";
import StripeForm from "../components/Stripe/Form";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Avatar } from "../components/Avatar";
import { formatError } from "../helpers/formatError";
import PayPalButton from "../components/PayPal/PayPalButton";
import { me } from "../api/auth";
import JSCookies from "js-cookie";
import { SummaryBeforePayment } from "../components/SummaryBeforePayment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

function PayChild() {
  const { t } = useTranslation();
  const loading = useLoading();
  const stripe = useStripe();
  const history = useHistory();
  const alert = useAlert();
  const stripeRef = useRef();
  const [{ profile }, dispatch] = useAuth();
  const [inputsEvent, setInputsEvent] = useState({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });
  const { firstname = "", lastname = "", email = "" } = profile || {};
  const [platform, setPlatform] = useState("stripe"); // 'paypal' | 'stripe';

  const isStripeFormValid = () => {
    return (
      stripe &&
      Object.values(inputsEvent).every((input) => input && input.complete)
    );
  };

  const onSubmit = async () => {
    if (!isStripeFormValid()) {
      return;
    }

    try {
      loading.show();

      // controllo che non abbia già l'abbonamento
      const token = JSCookies.get("token");
      const { data = {} } = await me(token);
      dispatch({ type: "setProfile", profile: data });
      if (data.is_subscribed) {
        history.replace("/");
        alert.show(t("SUBSCRIPTION_ALREADY_ACTIVE"));
        return;
      }

      loading.setMessage(t("PAYMENT_WAITING"))
      await stripeRef.current.submit();
      alert.show(t("PAYMENT_COMPLETED"));
      history.push("/");
    } catch (error) {
      console.log("error", error);
      alert.show(typeof error === "string" ? error : formatError(error));
    } finally {
      loading.hide();
    }
  };

  const onClickPaypal = async (d, actions) => {
    // controllo che non abbia già l'abbonamento
    const token = JSCookies.get("token");
    const { data = {} } = await me(token);
    dispatch({ type: "setProfile", profile: data });
    if (data.is_subscribed) {
      history.replace("/");
      alert.show(t("SUBSCRIPTION_ALREADY_ACTIVE"));
      return actions.reject();
    }
    return actions.resolve();
  };

  return (
    <PrimaryBackground direction="column">
      <FlexContainer align="center" paddingBottom={4} top={1}>
        <BackButton onClick={() => history.goBack()}>
          <img src="../arrow-back.svg" alt="back"></img>
        </BackButton>
        <StyledP semiBold left={1}>
          {t("RENEW_YOUR_SUB")}
        </StyledP>
      </FlexContainer>

      <FlexContainer justify="between" paddingBottom={3} paddingTop={1}>
        <FlexContainer align="center" fitContent>
          <Avatar profile={profile} />
          <div>
            <StyledP semiBold>
              {firstname} {lastname}
            </StyledP>
            <StyledP>{email}</StyledP>
          </div>
        </FlexContainer>
      </FlexContainer>

      <SummaryBeforePayment />

      <Form>
        <div style={{ display: "flex" }}>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="radiostripe"
                onChange={() => setPlatform("stripe")}
                checked={platform === "stripe"}
              />{" "}
              {t("CREDIT_CARD")}
            </Label>
          </FormGroup>
          <FormGroup check style={{ marginLeft: 10 }}>
            <Label check>
              <Input
                type="radio"
                name="radiopaypal"
                onChange={() => setPlatform("paypal")}
                checked={platform === "paypal"}
              />{" "}
              Paypal
            </Label>
          </FormGroup>
        </div>
      </Form>

      {platform === "stripe" && (
        <Form>
          <StripeForm
            ref={stripeRef}
            inputsEvent={inputsEvent}
            setInputsEvent={setInputsEvent}
            isSubmitted={false}
          />

          <PrimaryButton
            type="button"
            onClick={onSubmit}
            bottom={3}
            top={3}
            disabled={!isStripeFormValid()}
          >
            {t("SIGNUP")}
          </PrimaryButton>
        </Form>
      )}
      {platform === "paypal" && (
        <Form className="mt-20">
          <PayPalButton onClickPaypal={onClickPaypal} disabled={false} />
        </Form>
      )}
    </PrimaryBackground>
  );
}

export function PayPlan(props) {
  return (
    <Elements stripe={stripePromise}>
      <PayChild {...props} />
    </Elements>
  );
}
