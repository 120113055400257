import React, { useState, useEffect } from "react";
import { Menu } from "../components/Menu";
import {
  BackButton,
  FlexContainer,
  NewsHr,
  PrimaryBackground,
  StyledP,
} from "../components/StyledComponents";
import { formatError } from "../helpers/formatError";
import { useAlert } from "../providers/alert";
import { useAuth } from "../providers/auth";
import { useLoading } from "../providers/loading";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { HeaderLogo } from "../components/HeaderLogo";
import { getNotifications, markNotificationsRead } from "../api/notifications";
import { Notification } from "../components/Notification";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LIMIT = 10;

const Notifications = () => {
  const loading = useLoading();
  const { t } = useTranslation()
  const alert = useAlert();
  const [{ token }] = useAuth();
  const [start, setStart] = useState(0);
  const [notifications, setNotifications] = useState({ items: [], total: 0 });
  const history = useHistory()

  const { items = [], total = 0 } = notifications;

  const [sentryRef] = useInfiniteScroll({
    loading: loading.isOpen,
    hasNextPage: items.length < total,
    onLoadMore: () => {
      setStart(start + LIMIT);
    },
  });

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
  }, []);

  const search = async () => {
    if (loading.isOpen) return;

    try {
      loading.show();
      const { data = [], headers } = await getNotifications({
        token,
        start,
        limit: LIMIT,
      });

      const total = Number(headers["x-total-count"]);

      setNotifications({
        items: items.concat(data),
        total,
      });

      const ids = data.filter(({ read }) => !read).map(({ id }) => id)
      if (ids.length > 0) {
        markNotificationsRead({ token, ids });
      }

    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide();
    }
  };

  return (
    <>
      <PrimaryBackground direction="column" right="0">
        <HeaderLogo />
        <FlexContainer align="center" paddingBottom={2} top={1}>
          <BackButton onClick={() => history.goBack()}>
            <img src="../arrow-back.svg" alt="back"></img>
          </BackButton>
          <StyledP semiBold left={1}>{t('NOTIFICATIONS')}</StyledP>
        </FlexContainer>

        <NewsHr />

        <div className="articles-cont w-100">
          {
            !loading.isOpen && items.length === 0 && (
              <StyledP align="center" top={2}>{t('NO_NOTIFICATIONS')}</StyledP>
            )  
          }
          {items.map((notification) => (
            <Notification key={notification.id} data={notification} />
          ))}
        </div>
        {items.length < total && <div ref={sentryRef} />}
      </PrimaryBackground>
      <Menu />
    </>
  );
};

export { Notifications };
