import axios from "axios";
import qs from "qs"

export const getNotificationsCount = async ({ token, user }) => {
  const query = qs.stringify({
    _where: [
      { 
        user, 
        read: false
      },
      {
        _or: [{ 'comment.blocked': false }, { 'comment.blocked_null': true }]
      },
      {
        _or: [{ 'comment.blockedThread': false }, { 'comment.blockedThread_null': true }]
      },
    ],
  });
  
  return axios.get(
    `${process.env.REACT_APP_API_URL}/notifications/count?${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getNotifications = async ({ token, start = 0, limit = 10 }) => {
  const query = qs.stringify({
    _sort: 'created_at:DESC',
    _start: start,
    _limit: limit,
    _where: [
      {
        _or: [{ 'comment.blocked': false }, { 'comment.blocked_null': true }]
      },
      {
        _or: [{ 'comment.blockedThread': false }, { 'comment.blockedThread_null': true }]
      },
    ],
  });

  return await axios.get(`${process.env.REACT_APP_API_URL}/notifications?${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const markNotificationsRead = async ({ token, ids = [] }) => {
  return await axios.patch(
    `${process.env.REACT_APP_API_URL}/notifications/read`,
    {
      ids
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
