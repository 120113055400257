import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getArticle } from "../api/articles";
import { formatError } from "../helpers/formatError";
import { useAlert } from "../providers/alert";
import { useAuth } from "../providers/auth";
import { useLoading } from "../providers/loading";
import { NewsComponent } from "../components/NewsComponent";

export function NewsDetail({ history }) {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const loading = useLoading()
  const alert = useAlert()
  const [{ token }] = useAuth();
  const [article, setArticle] = useState()

  useEffect(() => {
    if (id) {
      find()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const find = async () => {
    try {
      loading.show()
      const { data = {} } = await getArticle({ token, lang: i18n.language, id })
      setArticle(data)
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }

  return (
    <>
      <NewsComponent article={article} />
    </>
  );
}