import { ButtonsContainer, DatePickerContainer, DatePickerFade, PrimaryBackground, PrimaryButtonSmall, SecondaryButtonSmall, StyledH3, StyledP } from "../../components/StyledComponents";
import { useTranslation } from "react-i18next";
import { DayPicker } from "../../components/DayPicker"
import { MonthPicker } from "../../components/MonthPicker";
import { YearPicker } from "../../components/YearPicker";
import { useState } from "react";
import moment from "moment";
import { useAuth } from "../../providers/auth";
import { HeaderLogo } from "../../components/HeaderLogo";

export function Step2({ history, next }) {
  const { t } = useTranslation();
  const [{ profile = {} }] = useAuth()
  const { birthdate } = profile || {}
  const defaultDate = birthdate ? moment(birthdate) : moment()

  // salvo i valori in stringa perchè la libreria dei picker non accetta i number
  const [day, setDay] = useState(defaultDate.date().toString())
  const [month, setMonth] = useState(defaultDate.month().toString())
  const [year, setYear] = useState(defaultDate.year().toString())

  const onNext = () => {
    const birthdate = moment().date(day).month(month).year(year).hour(12).minute(0)
    next({ birthdate }, '/onboarding/step3')
  }

  return (
    <PrimaryBackground direction="column" align="center">
      <HeaderLogo/>
      <StyledH3 bottom={1} align="center">{t('YOUR_BIRTHDAY')}</StyledH3>
      <StyledP color="purple" align="center" bottom={4}>
        {t('SELECT_ONE_OPTION')}
      </StyledP>
      <DatePickerContainer>
        <DatePickerFade />
        <DayPicker day={day} setDay={setDay} month={month} />
        <MonthPicker month={month} setMonth={setMonth} />
        <YearPicker year={year} setYear={setYear} />
        <DatePickerFade bottom />
      </DatePickerContainer>
      <ButtonsContainer auto="auto" justify="between" >
        <SecondaryButtonSmall onClick={() => history.goBack()}>
          {t('BACK')}
        </SecondaryButtonSmall>
        <PrimaryButtonSmall onClick={onNext}>
          {t('FORWARD')}
        </PrimaryButtonSmall>
      </ButtonsContainer>
    </PrimaryBackground>
  );
}