import { Link } from "react-router-dom";
import {
  LoginContainer,
  PrimaryButton,
  StyledH1,
  StyledP,
  WelcomeBackground,
} from "../components/StyledComponents";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAlert } from "../providers/alert";
import { useAuth } from "../providers/auth";

export function SubscriptionRenewal({ history }) {
  const alert = useAlert()
  const { t } = useTranslation();
  const [{ profile }] = useAuth()

  useEffect(() => {
    if (profile && profile.is_subscribed) {
      history.replace('/')
      alert.show(t('SUBSCRIPTION_ALREADY_ACTIVE'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  return (
    <WelcomeBackground>
      <img src="../../logo.png" width="150px" alt="logo" />
      <LoginContainer>
        <StyledH1 bottom={2}>{t("RENEW_YOUR_SUB")}</StyledH1>
        <StyledP color="purple" bottom={2}>
          {t("SUB_RENEWAL_SENTENCE")}
        </StyledP>
        <Link to="/plan-selection">
          <PrimaryButton bottom={3}>{t("RENEW_NOW")}</PrimaryButton>
        </Link>
        <StyledP color="purple">
          {t("PROBLEM_REPORT")} <Link to="/contact">{t("CLICK_HERE")}</Link>
        </StyledP>
      </LoginContainer>
    </WelcomeBackground>
  );
}
