import { useTranslation } from "react-i18next";
import { Menu } from "../components/Menu";
import {
  PrimaryBackground,
  StyledH3,
  WorkoutButton,
} from "../components/StyledComponents";
import { useHistory } from "react-router-dom";
import { HeaderLogo } from "../components/HeaderLogo";
import { WORKOUT_LEVELS } from "../helpers/workout";
import { NotificationIcon } from "../components/NotificationIcon";

export function Workout() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <PrimaryBackground direction="column" align="center">
        <HeaderLogo paddingRight="0px">
          <NotificationIcon />
        </HeaderLogo>
        <StyledH3 bottom={7} align="center">{t('CHOOSE_YOUR_WORKOUT')}</StyledH3>
        <div className="workout-buttons-cont">
          {
            WORKOUT_LEVELS.map(({ level }, i, levels) => (
              <WorkoutButton
                key={level}
                level={level}
                bottom={i === levels.length - 1 ? 0 : 3}
                onClick={() => history.push(`/workouts?level=${level}`)}
              >
                {t(`LEVELS.${level}`)}
              </WorkoutButton>
            ))
          }
        </div>

      </PrimaryBackground>
      <Menu />
    </>
  );
}
