import React from "react";
import { useTranslation } from "react-i18next";
import { BackButton, ButtonsContainer, FlexContainer, PrimaryBackground, PrimaryButton, StyledP } from "../components/StyledComponents";
import { useQueryParam, NumberParam } from "use-query-params";
import { HeaderLogo } from "../components/HeaderLogo";
import Slider from "react-slick";

const IMAGES = [
  '../add_to_home_1.png',
  '../add_to_home_2.png',
]

export function TutorialAddHome({ history }) {
  const { t } = useTranslation();
  const [onboarding] = useQueryParam("onboarding", NumberParam);

  const isOnboarding = onboarding === 1

  return (
    <>
      <PrimaryBackground direction="column">
        <HeaderLogo />
        <FlexContainer align="center" paddingBottom={4} top={1}>
          {
            !isOnboarding && (
              <BackButton onClick={() => history.goBack()}>
                <img src="../arrow-back.svg" alt="back"></img>
              </BackButton>
            )
          }
          <StyledP semiBold left={1}>
            {t("ADD_TO_HOMESCREEN")}
          </StyledP>
        </FlexContainer>
        <FlexContainer justify="center">
          <StyledP color="purple" bottom={3} align="center" small >
            {t('ADD_TO_HOME_TEXT')}
          </StyledP>
        </FlexContainer>
        <Slider
          dots={false}
          infinite={true}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={true}
          className="add-to-home-slider"
        >
          {
            IMAGES.map((image, i) => (
              <div key={image} className="image-slider-cont">
                <img src={image} alt={`Tutorial ${i}`} style={{ maxWidth: '70%', maxHeight: '500px' }} />
              </div>
            ))
          }
        </Slider>
        {
          isOnboarding && (
            <ButtonsContainer auto="auto" justify="between" >
              <PrimaryButton
                onClick={() => history.push("/start")}
              >
                {t('FORWARD')}
              </PrimaryButton>
            </ButtonsContainer>
          )
        }
      </PrimaryBackground>
    </>
  );
}
