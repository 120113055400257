import { useTranslation } from "react-i18next";
import { TabBar} from "./StyledComponents";
import { ReactComponent as HomeIcn } from '../assets/icons/home.svg';
import { ReactComponent as WorkoutIcn } from '../assets/icons/calories-grey.svg';
import { ReactComponent as NewsIcn } from '../assets/icons/news.svg';
import { ReactComponent as ProfileIcn } from '../assets/icons/profile.svg';
import { NavLink } from "react-router-dom";

export function Menu() {
  const { t } = useTranslation();

  return (
    <TabBar>
      <NavLink exact activeClassName="active" to='/'>
        <HomeIcn width="24px"/>
        Home
      </NavLink>
      <NavLink activeClassName="active" to='/workout'>
        <WorkoutIcn/>
        Workout
      </NavLink>
      <NavLink activeClassName="active" to='/news'>
        <NewsIcn/>
        { t('NEWS') }
      </NavLink>
      <NavLink activeClassName="active" to='/profile'>
        <ProfileIcn/>
        { t('PROFILE') }
      </NavLink>
    </TabBar>
  );
}