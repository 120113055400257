import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FadeContainer,
  PrimaryButton,
  StyledH1,
  StyledP,
  WelcomeBackground,
} from "../components/StyledComponents";
import { eventViewContent } from "../helpers/event";

export function WelcomeScreen({ history }) {
  const { t } = useTranslation();

  useEffect(() => {
    eventViewContent()
  }, [])

  return (
    <WelcomeBackground>
      <img src="../../logo.png" width="150px" alt="logo" />
      <FadeContainer>
        <StyledH1 bottom={1} style={{ alignSelf: "center" }}>
          {t("WELCOMEPAGE_SENTENCE_1")}
        </StyledH1>
        <StyledP bottom={3}>{t("WELCOMEPAGE_SENTENCE_2")}</StyledP>
        <PrimaryButton bottom={3} onClick={() => history.push("/plan-selection")}>
          {t("SIGNUP")}
        </PrimaryButton>
        <PrimaryButton white bottom={3} onClick={() => history.push("/login")}>
          Login
        </PrimaryButton>
        
      </FadeContainer>
    </WelcomeBackground>
  );
}
