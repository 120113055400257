import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useAuth } from "../providers/auth";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getWorkouts } from "../api/workouts";
import { formatEvents } from "../helpers/calendar";
import { useHistory } from "react-router-dom";
import { useQueryParams, StringParam, withDefault } from 'use-query-params';

import {
  PrimaryBackground,
  FlexContainer,
  StyledP,
  BackButton,
} from "../components/StyledComponents";
import CalendarCellContent from "../components/CalendarCellContent";
import { capitalizeFirstLetter } from "../helpers/capitalize";
import { getNextWeekEnd } from "../helpers/workout";
import { Spinner } from "reactstrap";

function Calendar() {
  const START_OF_MONTH = moment().startOf("month").format("YYYY-MM-DD");
  const TODAY = getNextWeekEnd().format("YYYY-MM-DD");

  const [query, setQuery] = useQueryParams({
    start: withDefault(StringParam, START_OF_MONTH),
    end: withDefault(StringParam, TODAY),
  });
  const { start, end } = query;

  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const history = useHistory();
  const [{ token }] = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchWorkouts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  const fetchWorkouts = async () => {
    try {
      setLoading(true)
      const { data = [] } = await getWorkouts({
        token,
        lang: i18n.language,
        date_gte: start,
        date_lte: end,
      });
      setEvents(formatEvents(data));
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleMonthChange = ({ view = {} }) => {
    const nextEndWeek = getNextWeekEnd()
    const currentEnd = moment(view.currentEnd).subtract(1, "day")
    const endDate = currentEnd.isAfter(nextEndWeek) ? nextEndWeek : currentEnd

    const formatStart = moment(view.currentStart).format("YYYY-MM-DD")
    const formatEnd = endDate.format("YYYY-MM-DD")

    if (formatStart !== start || formatEnd !== end) {
      setQuery({
        start: formatStart,
        end: formatEnd,
      })
    }
  };

  const renderTitle = ({ date }) => {
    return capitalizeFirstLetter(moment(date).format('MMMM YYYY'))
  };

  const renderCellContent = ({ date, dayNumberText }) => {
    const event = events.find(
      (evt) => evt.date === moment(date).format("YYYY-MM-DD")
    );

    if (event) {
      return (
        <CalendarCellContent event={event} dayNumberText={dayNumberText} />
      );
    }

    return (
      <p style={{ padding: 0, margin: 0, color: "#fff", paddingTop: '2px' }}>
        <span>{dayNumberText}</span>
      </p>
    );
  };

  return (
    <PrimaryBackground direction="column">
      <FlexContainer align="center" paddingBottom={4} top={1}>
        <BackButton onClick={() => history.replace('/')}>
          <img src="../arrow-back.svg" alt="back"></img>
        </BackButton>
        <StyledP semiBold left={1} style={{ flexGrow: 1 }}>
          {t("GO_BACK")}
        </StyledP>
        {loading && <Spinner size="sm" />}
      </FlexContainer>

      <div style={{ flex: 1, width: "100%" }}>
        <FullCalendar
          locale={i18n.language}
          titleFormat={renderTitle}
          height="auto"
          plugins={[dayGridPlugin]}
          initialDate={start}
          initialView="dayGridMonth"
          dayHeaderContent={({ text = '' }) => capitalizeFirstLetter(text)}
          dayCellContent={renderCellContent}
          headerToolbar={{
            left: "title",
            right: "prev,next",
          }}
          datesSet={handleMonthChange}
          firstDay={i18n.language === 'it' ? 1 : 0}
          validRange={{
            end: getNextWeekEnd().add(1, 'day').format('YYYY-MM-DD')
          }}
        />
      </div>
    </PrimaryBackground>
  );
}

export default Calendar;
