import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputGroup, InputGroupAddon, InputGroupText, Form } from "reactstrap";
import { Menu } from "../components/Menu";
import {
  BackButton,
  FlexContainer,
  PrimaryBackground,
  StyledH4,
  StyledP,
  TransparentButton,
} from "../components/StyledComponents";
import { ReactComponent as SearchIcn } from "../assets/icons/search.svg";
import { Input } from "../components/WrappedInput";
import { useHistory } from "react-router-dom";

export function Search(props) {
  const [query, setQuery] = useState("");
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PrimaryBackground direction="column">
      <FlexContainer align="center" bottom={3}>
        <BackButton onClick={() => history.goBack()}>
          <img src="../arrow-back.svg" alt="back" />
        </BackButton>
        <StyledP semiBold left={1}>
          {t("GO_BACK")}
        </StyledP>
      </FlexContainer>
      <StyledH4 bottom={2} top={1}>
        {t("SEARCH_FOR_CONTENT")}
      </StyledH4>
      <Form onSubmit={() => history.push(`/search-results?_q=${query}`)}>
        <InputGroup className="mb8">
          <Input
            value={query}
            placeholder={t("WHAT_ARE_U_SEARCHING")}
            onChange={({ target }) => setQuery(target.value)}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <TransparentButton type="submit">
                <SearchIcn />
              </TransparentButton>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Form>

      {/* <StyledH4 top={4} bottom={2}>
        {t("MOST_SEARCHED")}
      </StyledH4>
      <GoToItem
        title="Workouts"
        description={"320 " + t("RESULTS")}
        goTo="/search-results"
      /> */}
      <Menu />
    </PrimaryBackground>
  );
}
