import axios from "axios";

export const getDiaries = ({ token, date, user }) => {

  const params = {
    date,
    user,
  }
  
  return axios.get(`${process.env.REACT_APP_API_URL}/diaries`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const updateDiary = ({ id, token, mood, comment }) => {
  const body = {
    mood,
    comment,
  };

  return axios.put(`${process.env.REACT_APP_API_URL}/diaries/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createDiary = ({ token, mood, comment, date }) => {
  const body = {
    mood,
    comment,
    date,
  };

  return axios.post(`${process.env.REACT_APP_API_URL}/diaries/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
