const Variables = {
  "Inter": 'Inter, sans-serif',

  "background": '#ffffff',
  "white": '#ffffff',
  "primary": '#04042E',
  "secondary": '#F173BE',
  "lightPurple": '#7C7CA0',
  "inputBackground": '#2B2951',
  "tabBarGrey": '#B4B4C0'
}

export default Variables