import { CircleContainer } from './StyledComponents';
import { getAvatarUrl } from './../helpers/profile';

export const Avatar = ({ profile, onClick }) => {
	const url = getAvatarUrl(profile, '../profile-default.svg')

	return (
		<CircleContainer right={1} onClick={onClick} className="pointer">
			<img src={url} alt="profile" className="w-100"></img>
		</CircleContainer>
	);
};