import { PrimaryBackground, PrimaryButton, SingleSelectionButton, StyledH3, StyledP } from "../../components/StyledComponents";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/auth";
import { useState } from "react";
import { HeaderLogo } from "../../components/HeaderLogo";

// Valori possibili: male | female | other

const OPTIONS = [
  { id: 'male', label: 'MAN' },
  { id: 'female', label: 'WOMAN' },
  { id: 'other', label: 'OTHER' },
]
export function Step1({ next }) {
  const [{ profile = {} }] = useAuth()
  const { t } = useTranslation();
  const [sex, setSex] = useState(profile.sex || 'male')

  return (
    <PrimaryBackground direction="column" align="center">
      <HeaderLogo/>
      <StyledH3 bottom={1} align="center">{t('SEX')}</StyledH3>
      <StyledP color="purple" align="center" bottom={3}>
        {t('SELECT_ONE_OPTION')}
      </StyledP>
      {
        OPTIONS.map(({ id, label }) => (
          <SingleSelectionButton
            key={id}
            bottom={1}
            className={sex === id ? 'selected' : ''}
            onClick={() => setSex(id)}
          >
            {t(label)}
          </SingleSelectionButton>
        ))
      }
      <PrimaryButton
        auto="auto"
        onClick={() => next({ sex }, '/onboarding/step2')}
      >
        {t('CONTINUE')}
      </PrimaryButton>
    </PrimaryBackground>
  );
}