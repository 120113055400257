import { getPostTime } from "../helpers/utils";
import WaveAudio from "./WaveAudio";
import {
  CircleContainer,
  FlexContainer,
  ImageContainer,
  InteractionCount,
  MediaContainer,
  PostContainer,
  PostHeader,
  StyledP,
} from "./StyledComponents";
import { useRef, useEffect, useState } from "react";
import { useNews } from "../providers/news/ModalNews";
import LikeHeart from "./LikeHeart";
import { truncate } from "../helpers/capitalize";
import { useTranslation } from "react-i18next";
import { isIOS } from "../helpers/fullscreen";
import { TextWithLink } from "./TextWithLink";
const LIMIT_TEXT = 140

export function Post({ article, detail = false, onClick, modal = false }) {
  const { date, message, media, type, id, commentsCount = 0 } = article || {};
  const { url } = media || {};
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const { article: articleInModal } = useNews();
  const [readMore, setReadMore] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!modal && articleInModal && articleInModal.id) {
      if (videoRef && videoRef.current) {
        videoRef.current.pause();
      }
      if (audioRef && audioRef.current) {
        audioRef.current.pause();
      }
    }
  }, [articleInModal, modal]);

  return (
    <PostContainer
      paddingRight={detail ? 0 : 3}
      paddingTop={2}
      paddingBottom={2}
      detail={detail ? "true" : ""}
      className="pointer"
    >
      <PostHeader bottom={1} onClick={onClick}>
        <FlexContainer align="center">
          <CircleContainer post right={1}>
            <img src="../taylor-avatar.png" alt="TaylorMega" className="w-100" />
          </CircleContainer>
          <StyledP small semiBold>Taylor Mega</StyledP>
        </FlexContainer>
        <StyledP small color="purple">
          {getPostTime(date)}
        </StyledP>
      </PostHeader>

      {
        message && (
          <>
            <StyledP
              small
              left={4}
              bottom={1}
              onClick={onClick}
              style={{ whiteSpace: 'pre-line' }}
            >
              <TextWithLink text={readMore ? message : truncate(message, LIMIT_TEXT)} />
            </StyledP>
            {
              message.length > LIMIT_TEXT && (
                <StyledP
                  left={4}
                  bottom={2}
                  color="purple"
                  onClick={() => setReadMore(!readMore)}
                  small
                  semiBold
                  className="pointer"
                >
                  {t(readMore ? 'READ_LESS' : 'READ_MORE')}
                </StyledP>
              )
            }
          </>
        )
      }

      <MediaContainer paddingLeft={4}>
        {type === "image" && (
          <ImageContainer detail={detail ? "true" : ""}>
            <img src={url} alt="img" onClick={onClick} />
          </ImageContainer>
        )}
        {type === "video" && (
          <video src={`${url}${isIOS ? '#t=0.001' : ''}`} controls controlsList="nodownload" ref={videoRef} />
        )}
        {type === "audio" && (
          <WaveAudio url={url} id={id} modal={modal} ref={audioRef} />
        )}
      </MediaContainer>

      <FlexContainer paddingLeft={4} paddingTop={2}>
        <InteractionCount right={1}>
          <LikeHeart article={article} />
        </InteractionCount>
        <InteractionCount>
          <img src="../comment.svg" alt="comment" />
          <span>{commentsCount}</span>
        </InteractionCount>
      </FlexContainer>
    </PostContainer>
  );
}
