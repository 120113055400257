import { useHistory } from "react-router-dom";
import { MostSearchedButton, StyledH4, StyledP } from "./StyledComponents";

export function GoToItem({ title, category, small, goTo, description }) {
  const history = useHistory()

  return (
    <MostSearchedButton
      justify="between"
      align="center" bottom={1}
      onClick={() => history.push(goTo)}
    >
      {small ?
        <div>
          <StyledP small semiBold style={{ whiteSpace: 'pre-line' }}>{title}</StyledP>
          <StyledP small color="purple">{category}</StyledP>
        </div>
        :
        <div>
          <StyledH4>{title}</StyledH4>
          <StyledP small color="purple">{description}</StyledP>
        </div>
      }

      <img src="../arrow-forward.svg" alt="forward" style={{ paddingLeft: 4 }}></img>
    </MostSearchedButton>
  );
}