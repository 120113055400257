import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { formatPriceInterval } from "../helpers/plan";
import { PlanContext } from "../providers/plan";
import { StyledH4, StyledP } from "./StyledComponents";

export const SummaryBeforePayment = () => {
	const [plan] = useContext(PlanContext);
	const { t } = useTranslation();

	const { recurring } = plan || {}
	const { trial_period_days = 0 } = recurring || {};

	return (
		<>
			<StyledH4 top={4} bottom={0.5}>
				{t("PAYMENT_METHOD")}
			</StyledH4>
			{plan && (
				<StyledP small color="purple" semiBold bottom={1}>
					{
						trial_period_days > 0 ? (
							`${t('FREE')} ${trial_period_days} giorni. Poi ${formatPriceInterval(plan)}`
						) : (
							`${t('SUBSCRIPTION')} ${formatPriceInterval(plan)}`
						)
					}
				</StyledP>
			)}
		</>
	)
};