export const scrollToId = (id) => {
  if (document && typeof document.getElementById === 'function') {
    const element = document.getElementById(id)
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
}

export const scrollToIdCenter = (id) => {
  if (document && typeof document.getElementById === 'function') {
    const element = document.getElementById(id)
    if (element && element.scrollIntoView) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
    });
    }
  }
}


