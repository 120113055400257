import { useHistory } from "react-router-dom";
import { getPreviewImage } from "../helpers/workout";
import { Badge, StyledWorkoutCardMini, StyledH3 } from "./StyledComponents";
import { WorkoutInfoComponent } from "./WorkoutInfoComponent";

export function MiniWorkoutCard({ workout }) {
  const history = useHistory()
  const {
    id = 0,
    title = '',
    bodyparts = [],
  } = workout || {}

  return (
    <StyledWorkoutCardMini
      direction="column"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(43, 42, 81, 0.4), rgba(43, 42, 81, 0.4)), url(${getPreviewImage(workout, '../placeholder.png')})`
      }}
      onClick={() => history.push(`/workouts/${id}`)}
      className="pointer"
    >
      <StyledH3 bottom={1}>{title}</StyledH3>
      <div className="mt-auto">
        <div className="row-container">
          {
            bodyparts.map(part => (
              <Badge right={1} key={part.id}><span>{part.name}</span></Badge>
            ))
          }
        </div>
        <div className="row-container m-0">
          <WorkoutInfoComponent workout={workout} />
        </div>
      </div>
    </StyledWorkoutCardMini>
  );
}