import Linkify from 'react-linkify';

export const TextWithLink = ({ text }) => {
	return (
		<Linkify
			componentDecorator={(decoratedHref, decoratedText, key) => (
				<a
					rel="noreferrer"
					style={{ wordBreak: 'break-all' }}
					target="_blank"
					href={decoratedHref}
					key={key}
					onClick={e => e.stopPropagation()}
				>
					{decoratedText}
				</a>
			)}
		>
			{text}
		</Linkify>
	);
};