import axios from 'axios';
import moment from 'moment-timezone'

export const getCategories = async ({ token, lang = 'it' }) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/categories`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        _locale: lang.substring(0, 2),
        _sort: 'name:ASC',
        _limit: -1
      }
    }
  );
}

export const getArticles = async ({ token, lang = 'it', limit = 10, categories = [], start = 0, date }) => {
  const params = {
    _locale: lang.substring(0, 2),
    _sort: 'date:DESC',
    _limit: limit,
    _start: start,
    date_lte: moment().toISOString()
  }

  if (date) {
    if (!moment().isSame(date, 'day')) {
      params.date_lte = moment(date).add(1, 'day').toISOString()
    }
    params.date_gte = moment(date).toISOString()
  }
  if (categories.length > 0) {
    params.category_in = categories
  }
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/articles`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );
}

export const getArticle = async ({ token, lang = 'it', id }) => {
  const params = {
    _locale: lang.substring(0, 2),
  }
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/articles/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );
}