import { useTranslation } from "react-i18next";
import { getWorkoutTime } from "../helpers/utils";
import { WorkoutInfo } from "./StyledComponents";

export function WorkoutInfoComponent({ workout }) {
  const { t } = useTranslation()
  const {
    duration = 0,
    calories = 0,
    level = '',
  } = workout || {}

  return (
    <>
      {
        duration > 0 && (
          <WorkoutInfo right={1}>
            <img src="../time.svg" alt="time"></img>
            <span>{getWorkoutTime(duration)}</span>
          </WorkoutInfo>
        )
      }
      {
        calories > 0 && (
          <WorkoutInfo right={1}>
            <img src="../calories.svg" alt="calories"></img>
            <span>{calories}</span>
          </WorkoutInfo>
        )
      }
      {
        level && (
          <WorkoutInfo>
            <img src="../level.svg" alt="level"></img>
            <span>{t(`LEVELS.${level}`)}</span>
          </WorkoutInfo>
        )
      }
    </>
  );
}