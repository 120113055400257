import { useTranslation } from "react-i18next";
import {
  BackButton,
  FlexContainer,
  PrimaryBackground,
  StyledP,
  PlanCardTitle,
  PlanCard,
  StyledH4,
  SubscriptionCardCanceled,
} from "../components/StyledComponents";
import { useAuth } from "../providers/auth";
import moment from "moment";
import { generateLabels } from "../helpers/generatePlanLabels";
import { HeaderLogo } from "../components/HeaderLogo";

export function YourSubscription({ history }) {
  const { t } = useTranslation();
  const [{ profile }] = useAuth();
  const { subscription } = profile || {};
  const {
    amount = 0,
    interval_count = 1,
    interval = '',
    period_start,
    period_end,
    canceled_at,
    is_trial = false,
    regular_amount = 0,
    regular_interval = '',
    regular_interval_count = 1,
  } = subscription || {};

  const labels = generateLabels(
    is_trial ? (regular_interval_count || interval_count) : interval_count,
    is_trial ? (regular_interval || interval) : interval,
  )

  return (
    <PrimaryBackground direction="column">
      <HeaderLogo />
      <FlexContainer align="center" paddingBottom={4}>
        <BackButton onClick={() => history.goBack()}>
          <img src="../arrow-back.svg" alt="back"></img>
        </BackButton>
        <StyledP semiBold left={1}>
          {t("MANAGE_SUBSCR")}
        </StyledP>
      </FlexContainer>
      {
        canceled_at && moment(canceled_at).isValid() && (
          <SubscriptionCardCanceled bottom={3}>
            {t('SUBSCRIPTION_CANCELED_AT', { date: moment(canceled_at).format('DD/MM/YYYY') })}
            <p>{t('SUBSCRIPTION_CANCELED_MESSAGE', { date: moment(period_end).format('DD/MM/YYYY') })}</p>
          </SubscriptionCardCanceled>
        )
      }
      <PlanCard bottom={1} pink={interval_count === 3}>
        <PlanCardTitle justify="between" bottom={2}>
          <StyledH4>
            {t(labels.title)}
          </StyledH4>
          <StyledH4>
            {
              is_trial ? t('FREE') : (
                <>
                  {(amount.toFixed(2) / 100).toFixed(2)}€
                  <span>/{t(labels.interval)}</span>
                </>
              )
            }
          </StyledH4>
        </PlanCardTitle>
        <ul>
          <li>
            {t("VALID_FROM")} {moment(period_start).format("LL")} {t("UNTIL")}{" "}
            {moment(period_end).format("LL")}
          </li>
          <li>{t(labels.plan_text)}</li>
          <li>{t('PLAN_TEXT_2')}</li>
          <li>{t('PLAN_TEXT_3')}</li>
          <li>{t('PLAN_TEXT_4')}</li>
          <li>{t('PLAN_TEXT_5')}</li>
        </ul>
      </PlanCard>
      {
        is_trial && !canceled_at && (
          <StyledP color="purple" bottom={2} top={2} align="left" small>
            {t("PAYMENT_AFTER_TRIAL")} <span style={{ fontWeight: 'bold', color: 'white' }}>
              {`${(regular_amount.toFixed(2) / 100).toFixed(2)}€/${t(labels.interval)}`}
            </span>
          </StyledP>
        )
      }
    </PrimaryBackground>
  );
}
