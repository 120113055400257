import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PublicRoute(props) {
  const {
    component: Component,
    isAuthenticated = false,
    privateRedirect = '/',
    path = "",
    ...rest
  } = props;

  const { location } = rest || {}
  const { search = '' } = location || {}

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && !path.startsWith("/signup") && !path.startsWith("/plan-selection") && !path.startsWith("/free-trial") && !path.startsWith("/contact") ? (
          <Redirect
            push={false}
            to={{
              pathname: privateRedirect,
              search,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default PublicRoute;
