import { useEffect } from "react"
import Helmet from "react-helmet"
import { useLocation } from "react-router-dom"
const { REACT_APP_GTM_ID, REACT_APP_FB_PIXEL_ID, REACT_APP_TIKTOK_PIXEL_ID, REACT_APP_GTM_MARKETING_ID } = process.env

export const Scripts = () => {
  const location = useLocation()

  useEffect(() => {
    const { pathname } = location || {}
    if (pathname && window && typeof window.gtag === 'function') {
      window.gtag('config', REACT_APP_GTM_ID, {
        'page_path': pathname
      });
    }
  }, [location])

  return (
    <>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            src: `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GTM_ID}`,
            async: true,
          },
          {
            type: 'text/javascript',
            innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${REACT_APP_GTM_ID}');
        `
          },
          {
            type: 'text/javascript',
            innerHTML: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${REACT_APP_FB_PIXEL_ID}');
          fbq('track', 'PageView');
        `
          },
          {
            type: 'text/javascript',
            innerHTML: `
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
            ttq.load('${REACT_APP_TIKTOK_PIXEL_ID}');
            ttq.page();
          }(window, document, "ttq");
          `
          },
          {
            type: 'text/javascript',
            innerHTML: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayerMarketing','${REACT_APP_GTM_MARKETING_ID}');
          `
          }
        ]}
      >
      </Helmet>
    </>
  )
}