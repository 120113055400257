export const openFullScreen = (elementId) => {
  var elem = document.getElementById(elementId);
  if (!elem) {
    return false
  }
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
    return true
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
    return true
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
    return true
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
    return true
  }
  return false
}

export const getElementFullscreen = () => {
  return document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement
}

export const EVENTS_FULLSCREEN_CHANGE = ['fullscreenchange', 'mozfullscreenchange', 'webkitfullscreenchange', 'MSFullscreenChange']

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)

export const hasFullScreen = (elementId) => {
  if (!document) return false
  var elem = document.getElementById(elementId);
  if (
    elem && (
      elem.requestFullscreen ||
      elem.mozRequestFullScreen ||
      elem.webkitRequestFullscreen ||
      elem.msRequestFullscreen
    )
  ) {
    return true
  }
  return false
}

export const exitFullScreen = () => {
  try {
    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

    if (!isInFullScreen) return
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } catch (err) {
    console.log(err)
  }
}