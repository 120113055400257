/* eslint-disable no-unused-vars */

import React, { useContext, useState, createContext, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  StyledP,
  AlertAnchorContainer,
  SecondaryButton,
  StyledH4,
  SecondaryButtonSmall,
} from "../../components/StyledComponents";

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const onClose = useRef(() => {});
  const [message, setMessage] = useState("");
  const [small, setSmall] = useState("");
  const [link, setLink] = useState({});
  const [t] = useTranslation();

  const toggle = () => {
    setMessage("");
    setSmall("");
    onClose.current();
  };

  const show = (message, options = {}) => {
    const { small = "", link = "" } = options || {};
    setMessage(message);
    setSmall(small);
    setLink(link);

    if (typeof options.onClose === "function") {
      onClose.current = options.onClose;
    }
  };

  const alertContext = {
    show,
  };

  return (
    <AlertContext.Provider value={alertContext}>
      <Modal
        isOpen={!!message}
        fade={false}
        toggle={toggle}
        centered={true}
        className="alertModal"
      >
        {/* <ModalHeader toggle={toggle}>{title}</ModalHeader> */}
        <ModalBody>
          <StyledH4 color="purple" align="center">
            {message}
            {small && (
              <small>
                <br />
                {small}
              </small>
            )}
          </StyledH4>
        </ModalBody>

        {link && link.route && link.label ? (
          <ModalFooter>
            <AlertAnchorContainer style={{ width: "100%" }}>
              <a href={`/#${link.route}`} onClick={toggle}>
                {link.label}
              </a>
            </AlertAnchorContainer>
            <SecondaryButtonSmall
              style={{ width: "100%" }}
              onClick={toggle}
              top={1}
            >
              {t("CLOSE")}
            </SecondaryButtonSmall>
          </ModalFooter>
        ) : (
          <ModalFooter className="margin-auto">
            <SecondaryButtonSmall onClick={toggle}>
              {t("CLOSE")}
            </SecondaryButtonSmall>
          </ModalFooter>
        )}
      </Modal>
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => useContext(AlertContext);

export { AlertProvider, useAlert };
