import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFaqs } from "../api/faqs";
import { FaqComponent } from "../components/FaqComponent";
import { HeaderLogo } from "../components/HeaderLogo";
import { BackButton, FlexContainer, PrimaryBackground, StyledP } from "../components/StyledComponents";
import { formatError } from "../helpers/formatError";
import { useAlert } from "../providers/alert";
import { useAuth } from "../providers/auth";
import { useLoading } from "../providers/loading";

export function Faq({ history }) {
  const { t, i18n } = useTranslation();
  const loading = useLoading()
  const alert = useAlert()
  const [{ token }] = useAuth();
  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    find()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const find = async () => {
    try {
      loading.show()
      const { data = {} } = await getFaqs({ token, lang: i18n.language })
      setFaqs(data)
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }

  return (
    <>
      <PrimaryBackground direction="column">
        <HeaderLogo/>
        <FlexContainer align="center" paddingBottom={4} top={1}>
          <BackButton onClick={() => history.goBack()}>
            <img src="../arrow-back.svg" alt="back"></img>
          </BackButton>
          <StyledP semiBold left={1}>{t('FAQ_TITLE')}</StyledP>
        </FlexContainer>
        {
          faqs.map((faq) => (
            <FaqComponent
              key={faq.id}
              question={faq.question}
              answer={faq.answer}
            />
          ))
        }
      </PrimaryBackground>
    </>
  );
}