import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
`;

const BannerHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

const LogoImage = styled.img`
  margin: 0rem !important;
  height: 80px;
  width: 80px;
`;

const BannerHeaderInfo = styled.div``;

const BannerHeaderInfoTitle = styled.p`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 1.2rem;
`;

const BannerHeaderClose = styled.button`
  border: none;
  background-color: transparent;
  margin: 0px !important;
  position: absolute;
  top: 0;
  right: 0;
`;

const BannerHeaderCloseIcon = styled.img`
  margin: 12px !important;
  width: 20px;
  height: 20px;
`;

const BannerButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const BannerButton = styled.button`
  border: none;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #f173be;
`;

function InstallAppBanner({ children }) {
  const { t } = useTranslation();
  const btnAddRef = useRef(null);
  const bannerContainerRef = useRef(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", onBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", onBeforeInstallPrompt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBeforeInstallPrompt = (deferredPrompt) => {
    deferredPrompt.preventDefault();
    bannerContainerRef.current.style.display = "block";

    attachButtonListener(deferredPrompt);
  };

  const attachButtonListener = (deferredPrompt) => {
    btnAddRef.current.addEventListener("click", () => {
      bannerContainerRef.current.style.display = "none";

      deferredPrompt.prompt();
    });
  };

  const onBannerClose = () => {
    bannerContainerRef.current.style.display = "none";
  };

  return (
    <>
      {children}
      <BannerContainer ref={bannerContainerRef} style={{ display: 'none' }}>
        <BannerHeaderContainer>
          <LogoImage src="/icon-192x192.png" alt="megafitness logo" />
          <BannerHeaderInfo>
            <BannerHeaderInfoTitle>MegaFitness Community</BannerHeaderInfoTitle>
          </BannerHeaderInfo>
          <BannerHeaderClose onClick={onBannerClose}>
            <BannerHeaderCloseIcon src="/close.svg" alt="close icon" />
          </BannerHeaderClose>
        </BannerHeaderContainer>

        <BannerButtonContainer>
          <BannerButton ref={btnAddRef}>{t("ADD_TO_HOMESCREEN")}</BannerButton>
        </BannerButtonContainer>
      </BannerContainer>
    </>
  );
}

export default InstallAppBanner;
