import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
import { BackButton, FlexContainer, PrimaryBackground, PrimaryButton, StyledP } from "../components/StyledComponents";
import { useAlert } from "../providers/alert";
import { useAuth } from "../providers/auth";
import { useLoading } from "../providers/loading";
import { updateMe } from "../api/auth";
import { formatError } from "../helpers/formatError";
import FormErrorMessage from "../components/FormErrorMessage";
import classNames from "classnames";
import pick from "lodash.pick";
import isEmpty from "lodash.isempty";
import { Input } from "../components/WrappedInput"
import { getErrorIdMessage } from "../helpers/utils";
import moment from "moment";
import { EditProfileBirthdate } from "./EditProfileBirthdate";
import { HeaderLogo } from "../components/HeaderLogo";

export function EditProfile({ history }) {
  const { t } = useTranslation();
  const [{ profile, token }, dispatch] = useAuth()
  const [openBirthdate, setOpenBirthdate] = useState(false)
  const [birthdate, setBirthdate] = useState(null)
  const { register, handleSubmit, formState: { errors }, setError, reset, getValues } = useForm();
  const loading = useLoading()
  const alert = useAlert()

  useEffect(() => {
    if (!isEmpty(profile)) {
      reset(pick(profile, Object.keys(getValues())))
      setBirthdate(profile.birthdate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const toggleBirthdate = () => setOpenBirthdate(!openBirthdate)

  const submit = async (body) => {
    try {
      loading.show()
      const { data = {} } = await updateMe({
        token,
        id: profile.id,
        body: { ...body, birthdate }
      })
      dispatch({ type: 'setProfile', profile: data });
      history.goBack()
    } catch (err) {
      const errorId = getErrorIdMessage(err)
      if (errorId === 'Auth.form.error.username.taken') {
        setError("username", {
          type: "manual",
          message: t(errorId)
        });
      } else if (errorId === 'Auth.form.error.email.taken') {
        setError("email", {
          type: "manual",
          message: t(errorId)
        });
      }

      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }

  return (
    <>
      <PrimaryBackground direction="column">
        <HeaderLogo/>
        <FlexContainer align="center" paddingBottom={4}>
          <BackButton onClick={() => history.goBack()}>
            <img src="../arrow-back.svg" alt="back"></img>
          </BackButton>
          <StyledP semiBold left={1}>{t('EDIT_PROFILE')}</StyledP>
        </FlexContainer>
        <Form onSubmit={handleSubmit(submit)}>

          <FormGroup className={classNames("mb8", { invalid: errors.firstname })}>
            <Label for="name">{t('NAME')}</Label>
            <Input type="text" name="firstname" id="firstname" placeholder={t('NAME')}
              {...register('firstname', {
                required: t('FIELD_REQUIRED'),
              })}
            />
            <FormErrorMessage errors={errors} name="firstname" />
          </FormGroup>

          <FormGroup className={classNames("mb8", { invalid: errors.lastname })}>
            <Label for="surname">{t('SURNAME')}</Label>
            <Input type="text" name="lastname" id="lastname" placeholder={t('SURNAME')}
              {...register('lastname', {
                required: t('FIELD_REQUIRED'),
              })}
            />
            <FormErrorMessage errors={errors} name="lastname" />
          </FormGroup>

          <FormGroup className={classNames("mb8", { invalid: errors.username })}>
            <Label for="username">{t('USERNAME')}</Label>
            <Input type="text" name="username" id="username" placeholder={t('USERNAME')}
              {...register('username', {
                required: t('FIELD_REQUIRED'),
                minLength: {
                  value: 3,
                  message: t('MIN_LENGTH_MESSAGE', { length: 3 })
                }
              })}
            />
            <FormErrorMessage errors={errors} name="username" />
          </FormGroup>

          <FormGroup className={classNames("mb8", { invalid: errors.email })}>
            <Label for="email">Email</Label>
            <Input type="email" name="email" id="email" placeholder="Email"
              {...register('email', {
                required: t('FIELD_REQUIRED'),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                  message: t('INVALID_EMAIL'),
                },
              })}
            />
            <FormErrorMessage errors={errors} name="email" />
          </FormGroup>

          <FormGroup className="form-group">
            <Label for="birthdate">{t('YOUR_BIRTHDAY')}</Label>
            <Input
              readOnly
              type="text"
              name="birthdate"
              id="birthdate"
              placeholder={t('SELECT_YOUR_BIRTHDAY')}
              value={birthdate ? moment(birthdate).format('DD/MM/YYYY') : ''}
              onClick={toggleBirthdate}
              className="pointer"
            />
          </FormGroup>
          {
            openBirthdate && (
              <EditProfileBirthdate
                toggle={toggleBirthdate}
                date={birthdate || moment()}
                setDate={setBirthdate}
              />
            )
          }

          <Label for="weight">{t('WEIGHT')}</Label>
          <InputGroup className={classNames("mb8", { invalid: errors.weight })}>
            <Input type="number" name="weight" id="weight" placeholder={t('WEIGHT')}
              {...register('weight', {
                required: t('FIELD_REQUIRED'),
                min: 1
              })}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className="profile">kg</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <FormErrorMessage errors={errors} name="weight" />

          <Label for="height">{t('HEIGHT')}</Label>
          <InputGroup className={classNames("mb8", { invalid: errors.height })}>
            <Input type="number" name="height" id="height" placeholder={t('HEIGHT')}
              {...register('height', {
                required: t('FIELD_REQUIRED'),
                min: 1
              })}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className="profile">cm</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <FormErrorMessage errors={errors} name="height" />

          <PrimaryButton top={2}>{t('UPDATE_INFO')}</PrimaryButton>
        </Form>
      </PrimaryBackground>
    </>
  );
}