import { useTranslation } from "react-i18next";
import { BackButton, FlexContainer, PrimaryBackground, StyledP } from "../components/StyledComponents";
import { TermsIt } from "../components/Terms/it.js";
import { TermsEn } from "../components/Terms/en.js";
import { HeaderLogo } from "../components/HeaderLogo";

export function Terms({ history }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <PrimaryBackground direction="column">
        <HeaderLogo/>
        <FlexContainer align="center" bottom={2}>
          <BackButton onClick={() => history.goBack()}>
            <img src="../arrow-back.svg" alt="back"></img>
          </BackButton>
          <StyledP semiBold left={1}>
            {t("GO_BACK")}
          </StyledP>
        </FlexContainer>
        <StyledP semiBold bottom={4}>{t('TERMS_TITLE')}</StyledP>
        {
          i18n.language.startsWith('it') ? (
            <TermsIt />
          ) : (
            <TermsEn />
          )
        }
      </PrimaryBackground>
    </>
  );
}