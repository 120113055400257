import { WaveformContainer, Wave, PlayButton } from './styled';
import WaveSurfer from 'wavesurfer.js';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

const WaveAudio = ({ url, id, modal }, ref) => {
  const [playing, setPlaying] = useState()
  const waveformRef = useRef(null);

  const suffix = modal ? `modal-${id}` : id

  useImperativeHandle(ref, () => ({
    pause() {
      setPlaying((playing) => false)
      if (waveformRef && waveformRef.current) {
        waveformRef.current.pause();
      }
    }
  }), [])

  useEffect(() => {
    if (!waveformRef.current) {
      initWaveForm()
    }
    return () => {
      waveformRef.current.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initWaveForm = () => {
    try {
      const track = document.querySelector(`#track-${suffix}`);
      const waveform = WaveSurfer.create({
        barWidth: 0.5,
        cursorWidth: 1,
        container: `#waveform-${suffix}`,
        backend: 'WebAudio',
        height: 70,
        progressColor: '#F173BE',
        responsive: true,
        waveColor: '#FFFFFF',
        cursorColor: 'transparent',
        barGap: 4,
        hideScrollbar: true

      });
      waveform.load(track);
      waveformRef.current = waveform
    } catch (err) {
      console.log('err', err)
    }
  }

  const handlePlay = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setPlaying(!playing)
    if (waveformRef && waveformRef.current) {
      waveformRef.current.playPause();
    }
  };

  return (
    <WaveformContainer >
      <PlayButton onClick={handlePlay} >
        <img src={!playing ? '../../play.svg' : '../../pause.svg'} alt="play-pause"></img>
      </PlayButton>
      <Wave id={`waveform-${suffix}`} />
      <audio id={`track-${suffix}`} src={url} />
    </WaveformContainer>
  );
}

export default forwardRef(WaveAudio);