import axios from 'axios';
import qs from 'qs';

export const COMMENT_TYPE = {
  article: 'article',
  workout: 'workout'
}

export const getComments = async ({ type = '', token, refId }) => {

  const query = qs.stringify({
    _sort: 'created_at:DESC',
    _where: [
      {
        _or: [{ blocked: false }, { blocked_null: true }]
      },
      {
        _or: [{ blockedThread: false }, { blockedThread_null: true }]
      },
    ],
  });

  return await axios.get(
    `${process.env.REACT_APP_API_URL}/comments/${type}:${refId}?${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export const postComment = async ({ type = '', token, refId, content = '', profile = {}, threadOf }) => {
  const body = {
    authorId: profile.id,
    authorName: `${profile.firstname} ${profile.lastname}`,
    authorEmail: profile.email,
    content,
    related: [{
      refId,
      ref: type,
      field: 'comments'
    }]
  }
  if (threadOf) {
    body.threadOf = threadOf
  }
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/comments/${type}:${refId}/`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}