import moment from "moment"
import Variables from "../components/Variables"

export const getPreviewImage = (workout, placeholder = '') => {
  const { preview } = workout || {}
  const { formats, url = '' } = preview || {}
  const { small } = formats || {}
  return small ? small.url : (url || placeholder)
}

export const getLargeImage = (workout, placeholder = '') => {
  const { preview } = workout || {}
  const { formats, url = '' } = preview || {}
  const { large } = formats || {}
  return large ? large.url : (url || placeholder)
}

// la domenica della settimana prossima
export const getNextWeekEnd = () => {
  return moment().add(7, 'days').endOf('isoWeek')
}

export const WORKOUT_LEVELS = [
  { level: 'beginner', color: Variables.secondary },
  { level: 'intermediate', color: '#D04899' },
  { level: 'workout', color: '#AE2175' },
  { level: 'extra', color: '#720847' },
  { level: 'yoga', color: '#4a002c' },
]