import React, { useState, useEffect, useContext } from "react";
import {
  PrimaryBackground,
  StyledH3,
  StyledP,
  PlanCard,
  PlanCardTitle,
  PlanCardButton,
  StyledH4,
} from "../components/StyledComponents";
import { useTranslation } from "react-i18next";
import { useAuth } from "../providers/auth";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { listProducts } from "../api/stripe";
import { useLoading } from "../providers/loading";
import { useAlert } from "../providers/alert";
import { formatError } from "../helpers/formatError";
import { PlanContext } from "../providers/plan";
import { generateLabels } from "../helpers/generatePlanLabels";
import { HeaderLogo } from "../components/HeaderLogo";
import { eventInitiateCheckout, eventPlanSelected } from "../helpers/event";
import { formatPriceInterval } from "../helpers/plan";

export function PlanSelection() {
  const [, setPlan] = useContext(PlanContext);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const loading = useLoading();
  const alert = useAlert();
  const [plans, setPlans] = useState([]);
  const [{ isAuthenticated = false, token }, dispatch] = useAuth();
  const trial = location.pathname.includes("free-trial");

  useEffect(() => {
    (async () => {
      try {
        loading.show();

        const trial =
          location.pathname === "/free-trial"
            ? "14"
            : location.pathname === "/free-trial7"
            ? "7"
            : undefined;

        const { data } = await listProducts(token, trial);

        setPlans(data);
      } catch (err) {
        alert.show(formatError(err));
      } finally {
        loading.hide();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    localStorage.removeItem("profile-id");
    dispatch({ type: "logout" });
    history.replace("/");
  };

  const onSelectPlan = (plan) => {
    console.log('onSelectPlan', plan);
    setPlan(plan);
    const { unit_amount = 0 } = plan;
    const value = (unit_amount.toFixed(2) / 100).toFixed(2);
    eventInitiateCheckout(value);
    eventPlanSelected(plan);
    history.push(isAuthenticated ? "/pay-plan" : "/signup");
  };

  return (
    <PrimaryBackground direction="column" align="center">
      <HeaderLogo />
      <StyledH3 bottom={1} align="center">
        {t("SELECT_THE_PLAN")}
      </StyledH3>
      <StyledP color="purple" align="center" bottom={3}>
        {t("SELECT_ONE_OPTION")}
      </StyledP>

      {plans.map((plan, idx) => {
        const isLastElement = plans.length - 1 === idx;

        const { recurring = {}, unit_amount = 0 } = plan;
        const {
          interval_count,
          interval,
          trial_period_days = 0,
        } = recurring || {};

        const labels = generateLabels(interval_count, interval);

        return (
          <>
            <PlanCard
              key={idx}
              pink={interval_count === 3 || trial_period_days > 0}
              bottom={isLastElement ? 0 : 1}
            >
              <PlanCardTitle justify="between" bottom={2}>
                <StyledH4>{t(labels.title)}</StyledH4>
                <StyledH4>
                  {trial_period_days > 0 ? (
                    t("FREE")
                  ) : (
                    <>
                      {(unit_amount.toFixed(2) / 100).toFixed(2)}€
                      <span>/{t(labels.interval)}</span>
                    </>
                  )}
                </StyledH4>
              </PlanCardTitle>
              <ul>
                {trial_period_days > 0 ? (
                  <li>{t("FREE_TRIAL_DAYS", { days: trial_period_days })}</li>
                ) : (
                  <></>
                )}
                <li>{t(labels.plan_text)}</li>
                <li>{t("PLAN_TEXT_2")}</li>
                <li>{t("PLAN_TEXT_3")}</li>
                <li>{t("PLAN_TEXT_4")}</li>
                <li>{t("PLAN_TEXT_5")}</li>
              </ul>

              <PlanCardButton top={2} onClick={() => onSelectPlan(plan)}>
                {t("SELECT")}
              </PlanCardButton>
            </PlanCard>
            {trial > 0 && (
              <StyledP color="purple" bottom={2} top={2} align="left" small>
                {t("PAYMENT_AFTER_TRIAL")}{" "}
                <span style={{ fontWeight: "bold", color: "white" }}>
                  {formatPriceInterval(plan)}
                </span>
              </StyledP>
            )}
          </>
        );
      })}
      <div className="mt-auto w-100">
        <StyledP color="purple" bottom={2} top={2} align="center">
          {t("PROBLEM_REPORT")} <Link to="/contact">{t("CLICK_HERE")}</Link>
        </StyledP>
        {isAuthenticated && (
          <StyledP color="pink" align="center" onClick={logout}>
            Logout
          </StyledP>
        )}
      </div>
    </PrimaryBackground>
  );
}
