import axios from 'axios';

export const createContact = async ({ token, body }) => {
  const options = {}
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/contact`,
    body,
    options
  );
}