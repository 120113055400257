import { FlexContainer, StyledP } from "./StyledComponents";

export function FaqComponent({ question, answer }) {

  return (
    <FlexContainer
      profile="true"
      direction="column"
      paddingTop={2}
      paddingBottom={2}
      paddingLeft={2}
      paddingRight={2}
      bottom={2}
    >
      <StyledP small bottom={1}>{question}</StyledP>
      <StyledP small color="purple">{answer}</StyledP>
    </FlexContainer>
  );
}