import { GoToItem } from "../components/GoToItem";
import { Menu } from "../components/Menu";
import { ProfileInfo } from "../components/ProfileInfo";
import {
  CircleContainer,
  FlexContainer,
  FooterContainer,
  PrimaryBackground,
  PrimaryButton,
  StyledP,
} from "../components/StyledComponents";
import { useAuth } from "../providers/auth";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar } from "../components/Avatar";
import { version } from "./../../package.json";
import { HeaderLogo } from "../components/HeaderLogo";
import { isIOS } from "../helpers/fullscreen";
import { NotificationIcon } from "../components/NotificationIcon";

export function Profile(props) {
  const { history } = props;
  const { t } = useTranslation();
  const [{ profile }, dispatch] = useAuth();
  const { firstname = "", lastname = "", createdAt, imported } = profile || {};

  return (
    <>
      <PrimaryBackground direction="column">
        <HeaderLogo paddingRight="0px">
          <NotificationIcon />
        </HeaderLogo>
        <FlexContainer justify="between" paddingBottom={3}>
          <FlexContainer align="center" fitContent>
            <Avatar
              profile={profile}
              onClick={() => history.push("/profile-avatar")}
            />
            <div>
              <StyledP semiBold>
                {firstname} {lastname}
              </StyledP>
              <StyledP small color="purple">
                {t("REGISTERED_SINCE")} {moment(createdAt).format("ll")}
              </StyledP>
            </div>
          </FlexContainer>
          <CircleContainer
            justify="center"
            align="center"
            onClick={() => history.push("/edit-profile")}
            className="pointer"
          >
            <img src="../settings.svg" alt="settings"></img>
          </CircleContainer>
        </FlexContainer>
        <ProfileInfo />
        <GoToItem
          title={t("FAQ")}
          description={t("FAQ_DESCRIPTION")}
          goTo="/faq"
        />
        {!imported && (
          <GoToItem
            title={t("YOUR_SUBSCRIPTION")}
            description={t("YOUR_SUBSCRIPTION_DESCRIPTION")}
            goTo="/subscription"
          />
        )}
        {
          isIOS && (
            <GoToItem
              title={t("ADD_TO_HOMESCREEN")}
              description={t("ADD_TO_HOME_TEXT_SHORT")}
              goTo="/add-to-home"
            />
          )
        }
        <GoToItem
          title={t("CONTACT")}
          description={t("CONTACT_DESCRIPTION")}
          goTo="/contact"
        />
        <PrimaryButton
          onClick={() => {
            localStorage.removeItem("profile-id");
            dispatch({ type: "logout" });
          }}
          top={2}
        >
          Logout
        </PrimaryButton>
        <FooterContainer top={4}>
          <img src="../footer-img.png" alt="logo" width="80px"></img>
          {/* <StyledP color="purple">GOLD CLUB SRL.</StyledP> */}
          <StyledP color="purple">VAT: IT04163010160</StyledP>
          {/* <StyledP color="purple">TELGATE (BG) VIA ROMA 16/I CAP 24060</StyledP> */}
          <StyledP color="purple">
            <span>
              <Link to="/terms">{t("TERMS_AND_CONDITIONS_PRIVACY")}</Link>
            </span>
          </StyledP>
          <StyledP color="purple" bottom={8}>v. {version}</StyledP>
        </FooterContainer>
      </PrimaryBackground>
      <Menu />
    </>
  );
}
