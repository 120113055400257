import axios from "axios";

const getParams = ({
  lang = "it",
  date,
  date_gte,
  date_lte,
  level,
  limit = -1,
  start = 0,
}) => {
  const params = {
    _locale: lang.substring(0, 2),
    _limit: limit,
    _start: start,
    _sort: 'date:DESC',
  };

  if (date) {
    params.date = date;
  } else {
    if (date_gte) {
      params.date_gte = date_gte;
    }
    if (date_lte) {
      params.date_lte = date_lte;
    }
  }

  if (level) {
    params.level = level;
  }
  return params
}

export const getWorkouts = async (options) => {
  const { token } = options
  const params = getParams(options)
  return await axios.get(`${process.env.REACT_APP_API_URL}/workouts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const getWorkoutsCount = async (options) => {
  const { token } = options
  const params = getParams(options)
  params._start = 0
  params._limit = -1
  return await axios.get(`${process.env.REACT_APP_API_URL}/workouts/count`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const getWorkout = async ({ token, lang = "it", id }) => {
  const params = {
    _locale: lang.substring(0, 2),
  };
  return await axios.get(`${process.env.REACT_APP_API_URL}/workouts/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};