import { PrimaryBackground, StyledH3, StyledP, ButtonsContainer, PrimaryButtonSmall, SecondaryButtonSmall, MultipleSelectionButton } from "../../components/StyledComponents";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getGoals } from "../../api/goals";
import { useAuth } from "../../providers/auth";
import classNames from "classnames";
import { HeaderLogo } from "../../components/HeaderLogo";
import { isIOS } from "../../helpers/fullscreen";

export function Step4({ history, next }) {
  const [{ token, profile }] = useAuth()
  const { goals = [] } = profile || {}
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState([])

  const defaultGoals = goals.map(g => typeof g === 'string' ? g : g.id)
  const [selectedIds, setSelectedIds] = useState(defaultGoals)

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const { data } = await getGoals({ token, lang: i18n.language })
        setOptions(data)
      } catch (err) {
        console.log(err)
      }
    }
    fetchGoals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggle = (id) => {
    const ids = selectedIds.filter(s => s !== id)
    if (!selectedIds.includes(id)) {
      ids.push(id)
    }
    setSelectedIds(ids)
  }

  const onNext = () => {
    next({ goals: selectedIds }, isIOS ? '/add-to-home?onboarding=1' : '/start')
  }

  return (
    <PrimaryBackground direction="column" align="center">
      <HeaderLogo />
      <StyledH3 bottom={1} align="center">{t('SELECT_GOALS')}</StyledH3>
      <StyledP color="purple" align="center" bottom={3}>
        {t('SELECT_MORE_OPTIONS')}
      </StyledP>
      {
        options.map((option) => (
          <MultipleSelectionButton
            className={classNames({ selected: selectedIds.includes(option.id) })}
            key={option.id}
            bottom={1}
            onClick={() => toggle(option.id)}
          >
            {option.name}
          </MultipleSelectionButton>
        ))
      }
      <ButtonsContainer auto="auto" justify="between" >
        <SecondaryButtonSmall onClick={() => history.goBack()}>
          {t('BACK')}
        </SecondaryButtonSmall>
        <PrimaryButtonSmall onClick={onNext}>
          {t('FORWARD')}
        </PrimaryButtonSmall>
      </ButtonsContainer>
    </PrimaryBackground>
  );
}