import React, {
  useContext,
  useImperativeHandle,
  useEffect,
  forwardRef,
} from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { createPaymentIntent } from "../../api/stripe";
import { useAuth } from "../../providers/auth";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { StripeErrorMessage } from "../../components/StyledComponents";
import debounce from "lodash.debounce";
import Variables from "../Variables";
import { PlanContext } from "../../providers/plan";
import { eventPurchase, eventRegistrationComplete } from "../../helpers/event";

const Form = forwardRef(({ inputsEvent, setInputsEvent, isSubmitted }, ref) => {
  const [plan] = useContext(PlanContext);
  const stripe = useStripe();
  const elements = useElements();
  const [{ token }, , callApi] = useAuth();
  const { t } = useTranslation();
  const history = useHistory();

  const { unit_amount = 0 } = plan || {};
  const value = (unit_amount.toFixed(2) / 100).toFixed(2);

  useImperativeHandle(ref, () => ({
    async submit() {
      if (!stripe || !elements) return;

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        throw new Error(error);
      }

      try {
        const result = await createPaymentIntent({
          plan: plan.stripe_id,
          payment_method: paymentMethod.id,
          token,
        });

        const { client_secret, status, id } = result.data;

        if (status === "requires_action") {
          let response = null;

          if (client_secret.startsWith("pi_")) {
            response = await stripe.confirmCardPayment(client_secret);
          }

          if (client_secret.startsWith("seti_")) {
            response = await stripe.confirmCardSetup(client_secret);
          }

          if (response?.error) {
            throw response?.error;
          }
        }

        eventPurchase(value, "stripe");
        eventRegistrationComplete(plan, id);

        await new Promise((r) => setTimeout(r, 5000));
        await callApi();
      } catch (error) {
        throw error;
      }
    },
  }));

  useEffect(() => {
    if (!plan) {
      history.replace("/plan-selection");
    }
  }, []);

  const onStripeElementChange = (event) => {
    const { elementType } = event;
    setInputsEvent((i) => ({ ...i, [elementType]: event }));
  };
  const onElementChangeDebounce = debounce(onStripeElementChange, 600);

  const style = {
    base: {
      color: "white",
      fontSize: "16px",
      fontFamily: Variables.Inter,
      "::placeholder": {
        color: Variables.lightPurple,
        fontFamily: Variables.Inter,
      },
    },
  };
  const showError = (inputField) => {
    if (isSubmitted) {
      return !inputsEvent[inputField] || !inputsEvent[inputField].complete;
    }
    return (
      inputsEvent[inputField] &&
      !inputsEvent[inputField].empty &&
      !inputsEvent[inputField].complete
    );
  };

  return (
    <div className="mt-20">
      <div className={isSubmitted ? "submitted" : ""}>
        <CardNumberElement
          onChange={onElementChangeDebounce}
          options={{ placeholder: t("CARD_NUMBER"), style }}
        />
      </div>
      {showError("cardNumber") && (
        <StripeErrorMessage>{t("STRIPE_CARD_ERROR")}</StripeErrorMessage>
      )}

      <div className={isSubmitted ? "submitted" : ""}>
        <CardExpiryElement
          onChange={onElementChangeDebounce}
          options={{ placeholder: t("EXPIRATION_DATE"), style }}
        />
      </div>
      {showError("cardExpiry") && (
        <StripeErrorMessage>{t("STRIPE_DATE_ERROR")}</StripeErrorMessage>
      )}

      <div className={isSubmitted ? "submitted" : ""}>
        <CardCvcElement
          onChange={onElementChangeDebounce}
          options={{ placeholder: "CVC", style }}
        />
      </div>
      {showError("cardCvc") && (
        <StripeErrorMessage>{t("STRIPE_CVC_ERROR")}</StripeErrorMessage>
      )}
    </div>
  );
});

export default Form;
