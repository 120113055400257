import WheelPicker from 'react-simple-wheel-picker';
import moment from 'moment';
import { capitalizeFirstChar } from '../helpers/utils';

const data = moment.months().map((month, i) => ({ 
	id: i.toString(), 
	value: capitalizeFirstChar(month) 
}))

export const MonthPicker = ({ month, setMonth }) => {

	const handleOnChange = ({ id }) => {
		setMonth(id)
	};

	if (data.length === 0) return null
	return (
		<WheelPicker
			data={data}
			onChange={handleOnChange}
			height={270}
			width={100}
			titleText="Enter value same as aria-label"
			itemHeight={30}
			selectedID={month}
			color="#ccc"
			activeColor="#F173BE"
			backgroundColor="transparent"
			classname="dayPicker"
		/>
	);
};