import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getWorkout } from "../api/workouts";
import { formatError } from "../helpers/formatError";
import { useAlert } from "../providers/alert";
import { useAuth } from "../providers/auth";
import { useLoading } from "../providers/loading";
import ReactMarkdown from "react-markdown";
import {
  Badge,
  FlexContainer,
  PrimaryBackground,
  StyledH2,
  WorkoutDetailHead,
  ButtonsContainer,
  PrimaryButtonSmall,
  BackButton,
  StyledP,
  DescriptionInfoContainer,
  StyledH4,
  WorkoutHR,
} from "../components/StyledComponents";
import { getSignedUrl } from "../api/file";
import {
  EVENTS_FULLSCREEN_CHANGE,
  getElementFullscreen,
  hasFullScreen,
  isSafari,
  openFullScreen,
} from "../helpers/fullscreen";
import ReactPlayer from "react-player";
import { completeWorkout } from "../api/videos";
import { CompletedBadge } from "../components/CompletedBadge";
import { getPreviewImage } from "../helpers/workout";
import { Modal, ModalBody } from "reactstrap";
import "./../VideoModal.css";
import { HeaderLogo } from "../components/HeaderLogo";
import { WorkoutInfoComponent } from "../components/WorkoutInfoComponent";
import { PostComments } from "../components/PostComments";
import { COMMENT_TYPE } from "../api/comments";
import { truncate } from "../helpers/capitalize";
import * as Sentry from "@sentry/react";

const VIDEO_EL_ID = "workout-video";
const LIMIT_TEXT = 130;

export function WorkoutDetail({ history }) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const loading = useLoading();
  const alert = useAlert();
  const [{ profile, token }] = useAuth();
  const [workout, setWorkout] = useState();
  const [signedUrl, setSignedUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const refCompleting = useRef();

  // per l'account di Taylor (id=6), mostrare sempre completati
  const completed = (profile && profile.id === 6) || (workout && workout.completed)

  const {
    title = "",
    bodyparts = [],
    description = "",
    video = {},
    vimeoURL = "",
    awsS3URL = "",
  } = workout || {};

  const hasVideo = vimeoURL || awsS3URL || (video && video.url)
  const supportFullScreen = !isSafari && hasFullScreen('VIDEO_TEST')

  useEffect(() => {
    if (id) {
      find();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    for (const EVENT of EVENTS_FULLSCREEN_CHANGE) {
      document.addEventListener(EVENT, onChangeFullScreen);
    }
    return () => {
      for (const EVENT of EVENTS_FULLSCREEN_CHANGE) {
        document.removeEventListener(EVENT, onChangeFullScreen);
      }
    };
  }, []);

  const find = async () => {
    try {
      loading.show();
      const { data = [] } = await getWorkout({
        token,
        lang: i18n.language,
        id,
      });

      setWorkout(data);
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide();
    }
  };

  const start = async () => {
    try {
      loading.show();

      if (!hasVideo) {
        alert.show(t('WORKOUT_NO_VIDEO'));
        return
      }

      if (!vimeoURL && !awsS3URL && !signedUrl) {
        const key = new URL(video.url).pathname.replace("/", "");
        const { data = [] } = await getSignedUrl({ token, key });
        setSignedUrl(data.url);
      }

      if (supportFullScreen) {
        setTimeout(() => {
          const opened = openFullScreen(VIDEO_EL_ID)
          if (!opened) {
            toggle()
          }
        }, 200);
      } else {
        toggle()
      }
    } catch (err) {
      console.log(err);
      alert.show(formatError(err));
    } finally {
      loading.hide();
    }
  };

  const onChangeFullScreen = () => {
    const el = getElementFullscreen();

    if ((el && el.id === VIDEO_EL_ID) || (el && el.tagName === "IFRAME")) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  const onVideoProgress = (progress) => {
    const { played } = progress || {}
    if (played * 100 >= 75 && !workout.completed) {
      markVideoAsCompleted()
    }
  };

  const markVideoAsCompleted = async () => {
    if (refCompleting.current) {
      return
    }
    try {
      refCompleting.current = true
      await completeWorkout({ id: workout.id, token });

      find();
    } catch (err) {
      console.log(err);
    } finally {
      refCompleting.current = false
    }
  };

  const toggle = () => setModalVideo(!modalVideo)

  return (
    <>
      <PrimaryBackground direction="column" padding="0">
        <video id="VIDEO_TEST" style={{ height: 0 }}></video>
        {
          supportFullScreen && hasVideo && !modalVideo && (
            <ReactPlayer
              id={VIDEO_EL_ID}
              playing={isPlaying}
              url={vimeoURL || awsS3URL || signedUrl}
              controls
              controlsList="nodownload"
              width={0}
              height={0}
              onProgress={onVideoProgress}
              // onError={e => Sentry.captureException(e)}
            />
          )
        }

        <WorkoutDetailHead
          bottom={3}
          style={{
            backgroundImage: `url(${getPreviewImage(workout, '../placeholder.png')
              })`,
          }}
        >
          <HeaderLogo workoutDetail="true" />
          <FlexContainer align="center" justify="between" top={1}>
            <FlexContainer align="center">
              <BackButton onClick={() => history.goBack()}>
                <img src="../arrow-back.svg" alt="back" />
              </BackButton>
              <StyledP semiBold left={1}>
                {t("GO_BACK")}
              </StyledP>
            </FlexContainer>

            {completed && <CompletedBadge />}
          </FlexContainer>

          <StyledH2 bottom={1} top={2}>
            {title}&nbsp;
          </StyledH2>
          <FlexContainer paddingBottom={2}>
            {bodyparts.map((part) => (
              <Badge right={1} key={part.id}>
                <span>{part.name}</span>
              </Badge>
            ))}
          </FlexContainer>

          <FlexContainer>
            <WorkoutInfoComponent workout={workout} />
          </FlexContainer>

          <ButtonsContainer top={4} justify="between">
            {
              hasVideo && (
                <PrimaryButtonSmall onClick={start}>
                  {t("START")}
                </PrimaryButtonSmall>
              )
            }
            {/* <SecondaryButtonSmall whiteButton onClick={() => history.goBack()}>{t('COMPLETED')}</SecondaryButtonSmall> */}
          </ButtonsContainer>
        </WorkoutDetailHead>

        {
          description && (
            <>
              <DescriptionInfoContainer>
                <StyledH4 bottom={1}>{t('DESCRIPTION')}</StyledH4>
                <div style={{ color: "white" }}>
                  {<ReactMarkdown>{readMore ? description : truncate(description, LIMIT_TEXT)}</ReactMarkdown>}
                </div>
                {
                  description.length > LIMIT_TEXT && (
                    <StyledP
                      top={-1}
                      bottom={2}
                      color="purple"
                      onClick={() => setReadMore(!readMore)}
                      small
                      semiBold
                      className="pointer"
                    >
                      {t(readMore ? 'READ_LESS' : 'READ_MORE')}
                    </StyledP>
                  )
                }
                <WorkoutHR />
              </DescriptionInfoContainer>
            </>
          )
        }

        {
          workout && (
            <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px' }}>
              <PostComments
                commentType={COMMENT_TYPE.workout}
                commentRef={workout.id}
              />
            </div>
          )
        }
      </PrimaryBackground>

      {
        modalVideo && hasVideo && (
          <Modal isOpen={true} fade={false} toggle={toggle} centered={true} className="videoModal">
            <ModalBody>
              <FlexContainer align="end" justify="end" bottom={0} top={1} paddingRight={1}>
                <BackButton onClick={toggle} >
                  <img src="../close-white.svg" alt="back" style={{ height: '16px', width: '16px' }} />
                </BackButton>
              </FlexContainer>
              <ReactPlayer
                id={VIDEO_EL_ID}
                playing={true}
                url={vimeoURL || awsS3URL || signedUrl}
                controls
                width="100%"
                height="300px"
                controlsList="nodownload"
                onProgress={onVideoProgress}
                // onError={e => Sentry.captureException(e)}
              />
            </ModalBody>
          </Modal>
        )
      }
    </>
  );
}
