import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import { StyledP, DatePickerContainer, PrimaryButtonSmall, ButtonsContainer, SecondaryButtonSmall } from "../components/StyledComponents";
import moment from "moment";
import { DayPicker } from "../components/DayPicker"
import { MonthPicker } from "../components/MonthPicker";
import { YearPicker } from "../components/YearPicker";

export function EditProfileBirthdate({ date, setDate, toggle }) {
  const { t } = useTranslation();
  const [day, setDay] = useState(moment(date).date().toString())
  const [month, setMonth] = useState(moment(date).month().toString())
  const [year, setYear] = useState(moment(date).year().toString())

  const save = () => {
    const birthdate = moment().date(day).month(month).year(year).hour(12).minute(0)
    setDate(birthdate)
    toggle()
  }

  return (
    <Modal isOpen={true} fade={false} toggle={toggle} centered={true} className="birthday-modal">
      <ModalBody>
        <StyledP color="purple" align="center" bottom={4}>
          {t('SELECT_YOUR_BIRTHDAY')}
        </StyledP>
        <DatePickerContainer>
          <DayPicker day={day} setDay={setDay} month={month} />
          <MonthPicker month={month} setMonth={setMonth} />
          <YearPicker year={year} setYear={setYear} />
        </DatePickerContainer>
        <ButtonsContainer auto="auto" justify="between">
          <SecondaryButtonSmall top={3} onClick={toggle}>
            {t('CLOSE')}
          </SecondaryButtonSmall>
          <PrimaryButtonSmall top={3} onClick={save}>
            {t('SELECT')}
          </PrimaryButtonSmall>
        </ButtonsContainer>
      </ModalBody>
    </Modal>
  );
}