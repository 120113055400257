import {
  BackButton,
  FlexContainer,
  PrimaryBackground,
  PrimaryButton,
  StyledP,
} from "../components/StyledComponents";
import { Form, FormGroup, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLoading } from "../providers/loading";
import { useAlert } from "../providers/alert";
import FormErrorMessage from "../components/FormErrorMessage";
import { formatError } from "../helpers/formatError";
import classNames from "classnames";
import { signup } from "../api/auth";
import { Input } from "../components/WrappedInput";
import { Link, useHistory } from "react-router-dom";
import { getErrorIdMessage } from "../helpers/utils";
import { NATIONS } from "../helpers/nations";
import StripeForm from "../components/Stripe/Form";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { useContext, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { PlanContext } from "../providers/plan";
import { useEffect } from "react";
import PayPalButton from "../components/PayPal/PayPalButton";
import { useAuth } from "../providers/auth";
import { HeaderLogo } from "../components/HeaderLogo";
import { SummaryBeforePayment } from "../components/SummaryBeforePayment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

function SignupChild() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
    setError,
  } = useForm();
  const { t } = useTranslation();
  const loading = useLoading();
  const stripe = useStripe();
  const history = useHistory();
  const alert = useAlert();
  const [, dispatch] = useAuth();
  const stripeRef = useRef();
  const [inputsEvent, setInputsEvent] = useState({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });
  const [plan] = useContext(PlanContext);
  const [platform, setPlatform] = useState("stripe"); // 'paypal' | 'stripe';

  useEffect(() => {
    if (!plan) {
      history.replace("/plan-selection");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isStripeFormValid = () => {
    if (platform !== "stripe") return true;

    return (
      stripe &&
      Object.values(inputsEvent).every((input) => input && input.complete)
    );
  };

  const onSignup = async (values) => {
    if (!isStripeFormValid()) {
      return { error: true };
    }

    try {
      localStorage.removeItem("profile-id");
      loading.show();

      const referral = sessionStorage.getItem("utm_ref");
      const { data } = await signup({ ...values, marketing: true, referral });
      dispatch({ type: "login", token: data.jwt, profile: data.user });
      localStorage.setItem("profile-id", data.user.id);

      if (platform === "stripe") {
        loading.setMessage(t("PAYMENT_WAITING"))
        await stripeRef.current.submit();
        history.push("/");
      }
      loading.hide();
      return { error: false };
    } catch (err) {
      const errorId = getErrorIdMessage(err);
      if (errorId === "Auth.form.error.username.taken") {
        setError("username", {
          type: "manual",
          message: t(errorId),
        });
      } else if (errorId === "Auth.form.error.email.taken") {
        setError("email", {
          type: "manual",
          message: t(errorId),
        });
      }

      loading.hide();
      alert.show(formatError(err), {
        onClose() {
          if (errorId === "PAYMENT_ERROR") {
            history.replace("/pay-plan");
          }
        },
      });

      return { error: true };
    }
  };

  const onClickPaypal = async (data, actions) => {
    let success = false;
    await handleSubmit(async (v) => {
      const { error } = await onSignup(v);
      success = !error;
    })();
    if (success) {
      return actions.resolve();
    }
    return actions.reject();
  };

  return (
    <PrimaryBackground direction="column">
      <HeaderLogo />
      <FlexContainer align="center" paddingBottom={4} top={1}>
        <BackButton onClick={() => history.replace("/")}>
          <img src="../arrow-back.svg" alt="back"></img>
        </BackButton>
        <StyledP semiBold left={1}>
          {t("CREATE_AN_ACCOUNT")}
        </StyledP>
      </FlexContainer>
      <Form onSubmit={handleSubmit(onSignup)}>
        <FormGroup className={classNames("mb8", { invalid: errors.firstname })}>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder={t("NAME")}
            {...register("firstname", {
              required: t("FIELD_REQUIRED"),
            })}
          />
          <FormErrorMessage errors={errors} name="firstname" />
        </FormGroup>
        <FormGroup className={classNames("mb8", { invalid: errors.lastname })}>
          <Input
            type="text"
            name="lastname"
            id="lastname"
            placeholder={t("SURNAME")}
            {...register("lastname", {
              required: t("FIELD_REQUIRED"),
            })}
          />
          <FormErrorMessage errors={errors} name="lastname" />
        </FormGroup>
        <FormGroup className={classNames("mb8", { invalid: errors.nation })}>
          <Input
            type="select"
            name="nation"
            id="nation"
            placeholder={t("NATION")}
            {...register("nation", {
              required: t("FIELD_REQUIRED"),
            })}
          >
            <option value="">{t("NATION")}</option>
            {NATIONS.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Input>
          <FormErrorMessage errors={errors} name="nation" />
        </FormGroup>
        <FormGroup className={classNames("mb8", { invalid: errors.username })}>
          <Input
            type="text"
            name="username"
            id="username"
            placeholder={t("USERNAME")}
            {...register("username", {
              required: t("FIELD_REQUIRED"),
              minLength: {
                value: 3,
                message: t("MIN_LENGTH_MESSAGE", { length: 3 }),
              },
            })}
          />
          <FormErrorMessage errors={errors} name="username" />
        </FormGroup>
        <FormGroup className={classNames("mb8", { invalid: errors.email })}>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            {...register("email", {
              required: t("FIELD_REQUIRED"),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                message: t("INVALID_EMAIL"),
              },
            })}
          />
          <FormErrorMessage errors={errors} name="email" />
        </FormGroup>
        <FormGroup className={classNames("mb8", { invalid: errors.password })}>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            {...register("password", {
              required: t("FIELD_REQUIRED"),
              minLength: {
                value: 6,
                message: t("MIN_LENGTH_MESSAGE", { length: 6 }),
              },
            })}
          />
          <FormErrorMessage errors={errors} name="password" />
        </FormGroup>

        <SummaryBeforePayment />
        
        <div style={{ display: "flex" }}>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="radiostripe"
                onChange={() => setPlatform("stripe")}
                checked={platform === "stripe"}
              />{" "}
              {t("CREDIT_CARD")}
            </Label>
          </FormGroup>
          <FormGroup check style={{ marginLeft: 10 }}>
            <Label check>
              <Input
                type="radio"
                name="radiopaypal"
                onChange={() => setPlatform("paypal")}
                checked={platform === "paypal"}
              />{" "}
              Paypal
            </Label>
          </FormGroup>
        </div>

        {platform === "stripe" && (
          <StripeForm
            ref={stripeRef}
            inputsEvent={inputsEvent}
            setInputsEvent={setInputsEvent}
            isSubmitted={isSubmitted}
          />
        )}

        <div className="mb-24">
          <FormGroup check className="mt-20">
            <Label check>
              <Input
                type="checkbox"
                name="privacy"
                {...register("privacy", { required: t("FIELD_REQUIRED") })}
              />{" "}
              {t("TERMS_SENTENCE_1")}
              <Link to="/terms">{t("TERMS_AND_CONDITIONS_PRIVACY")}</Link>
              {t("TERMS_SENTENCE_2")}*
            </Label>
          </FormGroup>
          <FormErrorMessage errors={errors} name="privacy" />
        </div>

        {platform === "stripe" && (
          <PrimaryButton
            type="submit"
            bottom={3}
            disabled={isSubmitted && !isValid && !isStripeFormValid()}
          >
            {t("SIGNUP")}
          </PrimaryButton>
        )}
        {platform === "paypal" && (
          <PayPalButton
            onClickPaypal={onClickPaypal}
            disabled={isSubmitted && !isValid}
          />
        )}
      </Form>
    </PrimaryBackground>
  );
}

export function Signup(props) {
  return (
    <Elements stripe={stripePromise}>
      <SignupChild {...props} />
    </Elements>
  );
}
