export function formatEvents(events) {
  return events.map((evt) => {
    return {
      id: evt.id,
      title: evt.title,
      date: evt.date,
      completed: evt.completed,
    };
  });
}
