import { Redirect, Route, Switch } from 'react-router-dom';
import { updateMe } from '../../api/auth';
import { formatError } from '../../helpers/formatError';
import { useAlert } from '../../providers/alert';
import { useAuth } from '../../providers/auth';
import { useLoading } from '../../providers/loading';
import { OnboardingHome } from './OnboardingHome';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import isEmpty from 'lodash.isempty';

export function Onboarding({ match, history }) {
  const [{ profile = {}, token }, dispatch] = useAuth()
  const { id } = profile || {}
  const alert = useAlert()
  const loading = useLoading()

  const saveAndNext = async (body, nextPath) => {
    try {
      loading.show()
      const { data = {} } = await updateMe({ token, id, body })
      dispatch({ type: 'setProfile', profile: data });
      history.push(nextPath)
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <Redirect to={`${match.url}/home`} />
      </Route>
      <Route
        path={`${match.path}/home`}
        render={(props) => <OnboardingHome {...props} />}
      />
      <Route
        path={`${match.path}/step1`}
        render={(props) => !isEmpty(profile) && <Step1 next={saveAndNext} {...props} />}
      />
      <Route
        path={`${match.path}/step2`}
        render={(props) => !isEmpty(profile) && <Step2 next={saveAndNext} {...props} />}
      />
      <Route
        path={`${match.path}/step3`}
        render={(props) => !isEmpty(profile) && <Step3 next={saveAndNext} {...props} />}
      />
      <Route
        path={`${match.path}/step4`}
        render={(props) => !isEmpty(profile) && <Step4 next={saveAndNext} {...props} />}
      />
      <Route component={() => <Redirect />} />
    </Switch>
  );
}