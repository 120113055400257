
export const flex = ({ direction, justify, align }) => `
  display: flex;
  flex-direction: ${direction || "row"};
  justify-content: ${justify === "center" ? "center" : justify === "end" ? "flex-end" : justify === "between" ? "space-between" : "flex-start"};
  align-items: ${align === "center" ? "center" : align === "end" ? "flex-end" : align === "between" ? "space-between" : "flex-start"};
`;

export const margin = ({ top, bottom, left, right, auto}) => `
  margin-top : ${auto || (top ? `${top*8}px` : "0px")} !important;
  margin-bottom: ${bottom*8 || "0"}px;
  margin-left: ${left*8 || "0"}px;
  margin-right: ${right*8 || "0"}px;
`;

export const padding = ({ paddingTop, paddingBottom, paddingLeft, paddingRight}) => `
  padding-top : ${paddingTop*8 || "0"}px;
  padding-bottom: ${paddingBottom*8 || "0"}px;
  padding-left: ${paddingLeft*8 || "0"}px;
  padding-right: ${paddingRight*8 || "0"}px;
`;

export const alignment = ({ align }) => `
  text-align: ${align === "center" ? "center" : align === "right" ? "right" : "left"};
`;