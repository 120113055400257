import React, { useState, useContext, createContext } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import './Loading.css';

const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const show = (message = '') => {
    Promise.all([
      setIsOpen(true),
      setMessage(message)
    ])
  };

  const hide = () => {
    setIsOpen(false);
    setMessage('');
  };

  const loadingContext = {
    show,
    hide,
    isOpen,
    setMessage,
  };

  return (
    <LoadingContext.Provider value={loadingContext}>
      <Modal
        isOpen={isOpen}
        backdrop="static"
        keyboard={false}
        centered={true}
        zIndex="10000"
        fade={false}
        contentClassName="blocking-content-modal"
      >
        <ModalBody>
          {message && (
            <>
              <p className="blocking-message">{message}</p>
            </>
          )}
          <div className="blocking-modal-spinner">
            <Spinner type="grow" />
          </div>
        </ModalBody>
      </Modal>
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => useContext(LoadingContext);

export { LoadingProvider, useLoading };
