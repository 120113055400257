import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GoToItem } from "../components/GoToItem";
import {
  FlexContainer,
  PrimaryBackground,
  BackButton,
  StyledP,
  StyledH4,
} from "../components/StyledComponents";
import { search } from "../api/search";
import { useAuth } from "../providers/auth";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLoading } from "../providers/loading";
import { useAlert } from "../providers/alert";
import { formatError } from "../helpers/formatError";
import { truncate } from "../helpers/capitalize";

const LIMIT = 10;

export function SearchDetail({ history }) {
  const [results, setResults] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [start, setStart] = useState(0);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [{ token }] = useAuth();
  const loading = useLoading();
  const alert = useAlert();

  const [sentryRef] = useInfiniteScroll({
    loading: loading.isOpen,
    hasNextPage: results.length < totalCount,
    onLoadMore: () => {
      setStart(start + LIMIT);
    },
  });

  const query = qs.parse(location.search)._q;

  useEffect(() => {
    (async () => {
      try {
        loading.show();

        const { data, headers } = await search({
          token,
          query,
          limit: LIMIT,
          start,
          lang: i18n.language,
        });

        setResults((results) => [...results, ...data]);
        setTotalCount(Number(headers["x-total-count"]));
      } catch (error) {
        alert.show(formatError(error));
      } finally {
        loading.hide();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, start]);

  return (
    <PrimaryBackground direction="column">
      <FlexContainer align="center">
        <BackButton onClick={() => history.goBack()}>
          <img src="../arrow-back.svg" alt="back"></img>
        </BackButton>
        <StyledP semiBold left={1}>
          {t("GO_BACK")}
        </StyledP>
      </FlexContainer>

      <StyledH4 top={4}>
        {totalCount} {t("CONTENT_FOUND_FOR")}
      </StyledH4>
      <StyledP small color="purple" bottom={2}>
        {query}
      </StyledP>
      {results.map((result, idx) => {
        const { id, title, description, type } = result;
        const link = type === "article" ? `/news/${id}` : `/workouts/${id}`;

        return (
          <GoToItem
            key={idx}
            small
            title={truncate(title)}
            category={truncate(description)}
            goTo={link}
          />
        );
      })}
      {results.length < totalCount && <div ref={sentryRef} />}
    </PrimaryBackground>
  );
}
