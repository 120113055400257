import axios from 'axios';

export const getFaqs = async ({ token, lang = 'it' }) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/faqs`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        _locale: lang.substring(0, 2),
        _limit: -1
      }
    }
  );
}