import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './translations/en.json';
import translationIt from './translations/it.json';
import moment from 'moment';
import 'moment/locale/it'
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEn
      },
      it: {
        translation: translationIt
      }
    },
    lng: 'it',
    fallbackLng: 'it',
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });

// TEMP inglese disabilitato
moment.locale('it')

export default i18n;