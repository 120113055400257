import axios from 'axios';

export const like = async ({ articleId, token }) => {
    return await axios.post(
            `${process.env.REACT_APP_API_URL}/likes`, {
            articleId
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
}

export const unlike = async ({ articleId, token}) => {
    return await axios.delete(
        `${process.env.REACT_APP_API_URL}/likes`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            articleId
        }
    });
}