import axios from "axios";

export const createPaymentIntent = async ({ plan, payment_method, token }) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/stripe/payment-intent`,
    {
      plan,
      payment_method,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const listProducts = async (token, trial) => {
  const options = {};

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  if (trial) {
    options.params = {
      trial,
    };
  }

  return await axios.get(
    `${process.env.REACT_APP_API_URL}/stripe/products`,
    options
  );
};
