import { useTranslation } from 'react-i18next';
import { BadgeCompleted, StyledP } from './StyledComponents';

export const CompletedBadge = () => {
	const { t } = useTranslation();

	return (
		<BadgeCompleted>
			<StyledP color="pink" semiBold comment>{t('COMPLETED')}</StyledP>
		</BadgeCompleted>
	);
};