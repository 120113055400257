import { CircleContainer, FlexContainer, PrimaryBackground, PrimaryButtonCalendar, StyledP, TopBarContainer } from "../components/StyledComponents";
import { Menu } from "../components/Menu";
import { WorkoutCard } from "../components/WorkoutCard";
import { WorkoutCalendar } from "../components/WorkoutCalendar";
import { useAuth } from "../providers/auth";
import { Avatar } from "../components/Avatar";
import { StringParam, useQueryParam } from "use-query-params";
import { useEffect, useState } from "react";
import { useAlert } from "../providers/alert";
import { getWorkouts } from "../api/workouts";
import { NewsHome } from "../components/NewsHome";
import { formatError } from "../helpers/formatError";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchIcn } from '../assets/icons/search.svg';
import moment from "moment";
import { HeaderLogo } from "../components/HeaderLogo";
import { NotificationIcon } from "../components/NotificationIcon";
import { useLoading } from "../providers/loading";
import { getArticles } from "../api/articles";
import { scrollToId } from "../helpers/scroller";
import Diary from "../components/Diary";
// import { Badge } from "../components/StyledComponents";

export function Home({ history }) {
  const [{ profile, token }] = useAuth()
  const { firstname = '', lastname = '', created_at } = profile || {}
  const [workout, setWorkout] = useState()
  const [date, setDate] = useQueryParam('date', StringParam);
  const alert = useAlert()
  const { i18n, t } = useTranslation();
  const [articles, setArticles] = useState([]);
  const loading = useLoading();

  useEffect(() => {
    const d = moment(date)
    setDate((d.isValid() ? d : moment()).format('YYYY-MM-DD'));

    // 2021-10-11 data della modifica
    if (created_at && localStorage.getItem('start-viewed') !== 'true' && moment(created_at).isAfter('2021-10-11T09:30:00')) {
      history.replace('/start')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    find()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const find = async () => {
    if (!date || !moment(date).isValid()) {
      return
    }
    loading.show()
    try {
      const { data = [] } = await getWorkouts({
        token,
        lang: i18n.language,
        date,
      })
      setWorkout(data[0])

      const { data: dataArticles = [] } = await getArticles({
        token,
        lang: i18n.language,
        limit: 100000,
        start: 0,
        date,
      });
      setArticles(dataArticles)
      // se ci sono news in home page e c'è il workout, scrollo un pezzo per farle vedere
      if (dataArticles.length > 0 && data.length > 0) {
        setTimeout(() => {
          scrollToId('calendar-cont')
        }, 200)
      }
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }

  return (
    <div style={{ height: "100vh", backgroundColor: "#04042E" }}>
      <PrimaryBackground direction="column" padding="0" auto="true">
        <HeaderLogo padding="true">
          <NotificationIcon />
        </HeaderLogo>

        <TopBarContainer paddingLeft={3} paddingRight={3} paddingBottom={2} style={{ alignItems: 'center' }}>
          <div style={{ flex: 1, minWidth: 0 }}>
            <StyledP
              semiBold
              bottom={0}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {firstname} {lastname}
            </StyledP>
            {/* <Badge><span>Pro</span></Badge> */}
          </div>
          <div className="row-container">
            <CircleContainer justify="center" align="center" right={1} onClick={() => history.push('/search')} className="pointer">
              <SearchIcn />
            </CircleContainer>
            <Avatar profile={profile} onClick={() => history.push('/profile-avatar')} />
          </div>
        </TopBarContainer>
        <FlexContainer justify="between" align="center" paddingLeft={3} paddingRight={2} top={1} id="calendar-cont">
          <StyledP small style={{ whiteSpace: 'pre' }}>{t('WEEK_PROGRAM')}</StyledP>
          <PrimaryButtonCalendar onClick={() => history.push(`/calendar`)}>
            {t('VIEW_CALENDAR')}
          </PrimaryButtonCalendar>
        </FlexContainer>
        <WorkoutCalendar date={date} onChange={setDate} />
        <FlexContainer direction="column" paddingTop={3} paddingBottom={10} paddingLeft={3} paddingRight={3} justify="center">
          {
            workout ? (
              <WorkoutCard workout={workout} />
            ) : (
              <div style={{ color: 'white', width: '100%', textAlign: 'center' }}>
                {t('REST_DAY')}
              </div>
            )
          }

          <div className="w-100">
            <NewsHome date={date} articles={articles} />
          </div>

          <div className="w-100">
            <Diary date={date} />
          </div>

        </FlexContainer>
      </PrimaryBackground>

      <Menu />
    </div>
  );
}