export const eventViewContent = () => {
  if (!window) {
    return;
  }
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'ViewContent')
  }
  if (window.ttq && typeof window.ttq.track === 'function') {
    window.ttq.track('ViewContent')
  }

  console.log('event', 'ViewContent')
}

export const eventInitiateCheckout = (value = 0) => {
  if (!window) {
    return;
  }
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'InitiateCheckout', {
      value,
      currency: 'eur'
    })
  }
  if (window.ttq && typeof window.ttq.track === 'function') {
    window.ttq.track('InitiateCheckout', {
      value,
      currency: 'eur'
    })
  }

  console.log('event', 'InitiateCheckout', value)
}


export const eventPurchase = (value = 0, platform = '') => {
  if (!window) {
    return;
  }
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'Purchase', {
      content_name: platform,
      currency: 'eur',
      value
    })
  }
  if (window.ttq && typeof window.ttq.track === 'function') {
    window.ttq.track('Purchase', {
      content_name: platform,
      currency: 'eur',
      value
    })
  }

  console.log('event', 'Purchase', value, platform)
}

export const eventRegistrationComplete = (plan = {}, id) => {
  if (!window) {
    return;
  }
  const { unit_amount = 0, recurring = {} } = plan || {}
  const { interval_count = 1 } = recurring || {}

  const NAMES = {
    1: 'Mensile',
    3: 'Trimestrale',
    6: 'Semestrale'
  }
  const payload = {
    event: 'registrationCompleted',
    id,
    plan: NAMES[interval_count] || '',
    revenue: (unit_amount.toFixed(2) / 100).toFixed(2),
  }

  if (window.dataLayer) {
    window.dataLayer.push(payload)
  }
  console.log('event', payload)
}

export const eventPlanSelected = (plan = {}) => {
  if (!window) {
    return;
  }
  const { unit_amount = 0, recurring = {} } = plan || {}
  const { interval_count = 1 } = recurring || {}

  const NAMES = {
    1: 'Mensile',
    3: 'Trimestrale',
    6: 'Semestrale'
  }
  const payload = {
    event: 'planSelected',
    plan: NAMES[interval_count] || '',
    price: (unit_amount.toFixed(2) / 100).toFixed(2),
  }
  if (window.dataLayer) {
    window.dataLayer.push(payload)
  }
  console.log('event', payload)
}