import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { StringParam, useQueryParam } from "use-query-params";

const RoutesWrapper = ({
  publicRedirect,
  privateRedirect,
  children,
}) => {
  const [{ isAuthenticated, profile } = {}] = useAuth();
  const [utm_ref] = useQueryParam('utm_ref', StringParam);

  useEffect(() => {
    try {
      if (utm_ref) {
        sessionStorage.setItem('utm_ref', utm_ref);
      }
    } catch (err) {
      console.log('sessionStorage not supported', err)
    }
  }, [utm_ref])

  const childrenComponents = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      isAuthenticated,
      publicRedirect,
      privateRedirect,
      profile,
    });
  });

  return <Switch>{childrenComponents}</Switch>;
};

export default RoutesWrapper;
