import { PrimaryBackground, PrimaryButton, StyledH1, StyledP } from "../components/StyledComponents";
import { Form, FormGroup } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLoading } from "../providers/loading";
import { useAlert } from "../providers/alert";
import { reset } from "../api/auth";
import classNames from "classnames";
import { formatError } from "../helpers/formatError";
import FormErrorMessage from "../components/FormErrorMessage";
import { StringParam, useQueryParam } from "use-query-params";
import { useEffect } from "react";
import { useAuth } from "../providers/auth";
import { Input } from "../components/WrappedInput";

export function ResetPassword({ history }) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const loading = useLoading()
  const alert = useAlert()
  const [code] = useQueryParam('code', StringParam);
  const [, dispatch] = useAuth();

  useEffect(() => {
    if (!code) {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onSend = async (values) => {
    try {
      loading.show()
      const { data } = await reset({ ...values, code })
      dispatch({ type: 'login', token: data.jwt, profile: data.user });
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }

  return (
    <PrimaryBackground direction="column" justify="center">
      <StyledH1 bottom={2} align="center">{t('RESET_PASSWORD')}</StyledH1>
      <StyledP color="purple" bottom={3} align="center">{t('INSERT_NEW_PASSWORD')}</StyledP>
      <Form onSubmit={handleSubmit(onSend)}>
        <FormGroup className={classNames("m8", { invalid: errors.password })}>
          <Input type="password" name="password" id="password" placeholder="Password"
            {...register('password', {
              required: t('FIELD_REQUIRED'),
              minLength: {
                value: 6,
                message: t('MIN_LENGTH_MESSAGE', { length: 6 })
              }
            })}
          />
          <FormErrorMessage errors={errors} name="password" />
        </FormGroup>
        <PrimaryButton type="submit" bottom={3} top={3}>
          {t('SEND')}
        </PrimaryButton>
      </Form>
    </PrimaryBackground>
  );
}