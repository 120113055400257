import { PrimaryBackground, StyledH3, StyledP, ButtonsContainer, PrimaryButtonSmall, SecondaryButtonSmall } from "../../components/StyledComponents";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/auth";
import { useState } from "react";
import { ErrorMessage } from './../../components/StyledComponents';
import { HeaderLogo } from "../../components/HeaderLogo";

export function Step3({ history, next }) {
  const { t } = useTranslation();
  const [{ profile = {} }] = useAuth()
  const [weight, setWeight] = useState(profile.weight || 0)
  const [height, setHeight] = useState(profile.height || 0)
  const [submitted, setSubmitted] = useState(false)

  const isInvalid = !weight || !height

  const onNext = () => {
    setSubmitted(true)
    if (isInvalid) {
      return
    }
    next({ weight, height }, '/onboarding/step4')
  }

  return (
    <PrimaryBackground direction="column" align="center">
      <HeaderLogo/>
      <StyledH3 bottom={1} align="center">{t('HEIGHT_WEIGHT')}</StyledH3>
      <StyledP color="purple" align="center" bottom={3}>
        {t('FILL_THE_FIELDS')}
      </StyledP>
      <InputGroup className="mb8">
        <Input
          placeholder={t('WEIGHT')}
          type="number"
          value={weight}
          onChange={e => { setWeight(e.target.value); setSubmitted(false) }}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>kg</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <InputGroup>
        <Input
          placeholder={t('HEIGHT')}
          type="number"
          value={height}
          onChange={e => { setHeight(e.target.value); setSubmitted(false) }}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>cm</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      {
        submitted && isInvalid && (
          <ErrorMessage>{t('INSERT_WEIGHT_HEIGHT')}</ErrorMessage>
        )
      }
      <ButtonsContainer auto="auto" justify="between" >
        <SecondaryButtonSmall onClick={() => history.goBack()}>
          {t('BACK')}
        </SecondaryButtonSmall>
        <PrimaryButtonSmall onClick={onNext}>
          {t('FORWARD')}
        </PrimaryButtonSmall>
      </ButtonsContainer>
    </PrimaryBackground>
  );
}