import styled from 'styled-components'
import Variables from '../Variables';

export const WaveformContainer = styled.div`
  display: flex;  
  flex-direction: row;  
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Variables.inputBackground};
  padding: 4px 16px;
  border-radius: 3px;
`;

export const Wave = styled.div`
  width: 95%;
  wave{
    width: 95%;
  }
`;

export const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: ${Variables.secondary};
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px;
  &:hover {
    background: #DDD;
  }
`;