import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useAuth } from "../providers/auth";
import { FlexContainer, ProfileInfoContainer, StyledP } from "./StyledComponents";

export function ProfileInfo() {
  const [{ profile }] = useAuth()
  const history = useHistory()
  const { t } = useTranslation()
  const {
    weight = 0,
    height = 0,
    birthdate,
  } = profile || {}

  return (
    <FlexContainer
      profile="true"
      justify="center"
      paddingTop={2}
      paddingBottom={2}
      bottom={2}
      className="pointer"
      onClick={() => history.push('/edit-profile')}
    >
      <ProfileInfoContainer>
        <StyledP semiBold>
          {height}cm
        </StyledP>
        <StyledP small color="purple">
          {t('HEIGHT')}
        </StyledP>
      </ProfileInfoContainer>
      <ProfileInfoContainer border>
        <StyledP semiBold>
          {moment().diff(birthdate, 'years')} {t('YEARS')}
        </StyledP>
        <StyledP small color="purple">
          {t('AGE')}
        </StyledP>
      </ProfileInfoContainer>
      <ProfileInfoContainer border>
        <StyledP semiBold>
          {weight}kg
        </StyledP>
        <StyledP small color="purple">
          {t('WEIGHT')}
        </StyledP>
      </ProfileInfoContainer>
    </FlexContainer>
  );
}