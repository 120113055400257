import axios from 'axios';

export const getSignedUrl = async ({ token, key = '' }) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/signed-url`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        key
      }
    }
  );
}