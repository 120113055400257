import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import createInterceptor from "../../api/interceptor";
import { me } from "../../api/auth";
import { initialState, authReducer } from "./authReducer";
import { useAlert } from "../alert";

const AuthContext = createContext([{}, () => {}]);

const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const [cookies = {}, setCookie, removeCookie] = useCookies(["token"]);
  const { token } = cookies;
  const isAuthenticated = !!(token && token.length > 0);

  const [state, dispatch] = useReducer(authReducer, {
    ...initialState,
    token,
    isAuthenticated,
  });

  const callApi = async () => {
    try {
      const { data = {} } = await me(token);
      dispatch({ type: "setProfile", profile: data });
    } catch (err) {
      dispatchFunction({ type: "logout" });
      alert.show(t("GENERIC_ERROR"));
    }
  };

  const dispatchFunction = (action = {}) => {
    const { type, token } = action;

    switch (type) {
      case "login":
        setCookie("token", token, {
          maxAge: 2592000,
        });
        break;
      case "logout":
        removeCookie("token");
        break;
      default:
        break;
    }

    return dispatch(action);
  };

  useEffect(() => {
    createInterceptor(401, () => dispatch({ type: "logout" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      callApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <AuthContext.Provider value={[state, dispatchFunction, callApi]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthContext, useAuth };
