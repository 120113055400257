import React, { useRef, useEffect, useState } from "react";
import { CalendarContainer, CalendarItem, StyledP } from "./StyledComponents";
import moment from "moment";
import useWindowSize from "../hooks/useWindowSize";

export function WorkoutCalendar({ date, onChange }) {
  const [items, setItems] = useState([]);
  const calendarRef = useRef();
  const [width] = useWindowSize()

  useEffect(() => {
    createDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (items.length) {
      setTimeout(() => {
        const el = document.getElementById(`item-${date}`)
        if (calendarRef.current) {
          calendarRef.current.scrollTo({
            left: el.offsetLeft - width / 2 + 26,
            behavior: 'smooth'
          });
        }
        window.scrollTo(0, 0)
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, date]);

  const createDays = () => {
    const weekdays = moment.weekdays();
    const items = [];

    for (let i = 0; i < 7; i++) {
      const pastWeekMonday = moment().startOf("isoWeek").subtract(7, "days");
      const d = pastWeekMonday.add(i, "day");

      items.push({
        date: d,
        label: weekdays[d.day()],
      });
    }

    for (let i = 0; i < 7; i++) {
      const thisMonday = moment().startOf("isoWeek");
      const d = thisMonday.add(i, "day");

      items.push({
        date: d,
        label: weekdays[d.day()],
      });
    }

    for (let i = 0; i < 7; i++) {
      const nextWeekMonday = moment().startOf("isoWeek").add(7, "days");
      const d = nextWeekMonday.add(i, "day");

      items.push({
        date: d,
        label: weekdays[d.day()],
      });
    }

    setItems(items);
  };

  return (
    <CalendarContainer top={3} ref={calendarRef}>
      {items.map((item, i) => {
        const isActive = item.date.isSame(date, "day");

        return (
          <CalendarItem
            id={`item-${item.date.format('YYYY-MM-DD')}`}
            key={i}
            paddingTop={1.5}
            paddingRight={1.5}
            paddingBottom={1.5}
            paddingLeft={1.5}
            className={`${isActive ? "active" : ""} ${item.disabled ? "disabled" : ""
              }`}
            disabled={item.disabled}
            onClick={() => onChange(item.date.format("YYYY-MM-DD"))}
          >
            <StyledP comment semiBold>
              {item.date.format("DD")}
            </StyledP>
            <StyledP calendar>{item.label}</StyledP>
          </CalendarItem>
        );
      })}
    </CalendarContainer>
  );
}
