import { Menu } from "../components/Menu";
import { Post } from "../components/Post";
import { NewsHr, StyledH4 } from "../components/StyledComponents";
import { useNews } from "../providers/news/ModalNews";

export function NewsHome({ articles }) {
  const { show } = useNews();

  return (
    <>
      {articles && articles.length > 0 && (
        <>
          <StyledH4 bottom={2} top={3}>
            News
          </StyledH4>
          <NewsHr />
          <div className="articles-cont w-100">
            {articles.map((article) => (
              <Post
                key={article.id}
                article={article}
                onClick={() => show(article)}
                detail="true"
              />
            ))}
          </div>
        </>
      )}
      <Menu />
    </>
  );
}
