import React, { useState, useEffect } from "react";
import { like, unlike } from "../api/likes";
import { useAuth } from "../providers/auth";

function LikeHeart({ article }) {
  const [liked, setLiked] = useState(false);
  const [count, setCount] = useState(0);
  const [{ token }] = useAuth();

  const { id, userLiked = false, likesCount = 0 } = article || {};

  useEffect(() => {
    setLiked(userLiked);
    setCount(likesCount);
  }, [userLiked, likesCount]);

  async function onLikeClick() {
    try {
      if (!liked) {
        await like({ articleId: id, token });
      } else {
        await unlike({ articleId: id, token });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLiked(!liked);
      setCount(liked ? count - 1 : count + 1);
    }
  }

  return (
    <React.Fragment>
      <img
        src={liked ? "../heart-pink.svg" : "../heart.svg"}
        alt="heart"
        onClick={onLikeClick}
        className="pointer"
      />
      <span>{count}</span>
    </React.Fragment>
  );
}

export default LikeHeart;
