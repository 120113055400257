import { PrimaryBackground, PrimaryButton, StyledH1, StyledP } from "../components/StyledComponents";
import { useTranslation } from "react-i18next";

export function AccountChecking({ history }) {
  const { t } = useTranslation();

  const resend = async () => {
    history.replace('/send-confirmation-email')
  }

  return (
    <PrimaryBackground direction="column" justify="center">
      <StyledH1 bottom={3} align="center">{t('CHECK_ACCOUNT')}</StyledH1>
      <StyledP color="purple" bottom={3} align="center">
        {t('ACCOUNT_CHECKING_SENTENCE')}
      </StyledP>
      <PrimaryButton bottom={3} onClick={resend}>
        {t('SEND_AGAIN')}
      </PrimaryButton>
    </PrimaryBackground>
  );
}