import React, { useEffect, useState } from "react";
import { DiaryContainer, DiaryIcons, DiaryMoodIcon, PrimaryButton, StyledP } from "../components/StyledComponents";
import { createDiary, getDiaries, updateDiary } from "../api/diary";
import { useAuth } from "../providers/auth";
import { useAlert } from "../providers/alert";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Diary = ({ date }) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [{ token, profile }] = useAuth();
  const [loading, setLoading] = useState(false);
  const [diary, setDiary] = useState(null);
  const [mood, setMood] = useState("");
  const [comment, setComment] = useState("");

  const isFuture = moment(date).isAfter(moment(), 'day')
  const isPast = moment(date).isBefore(moment(), 'day')
  const formDisabled = isPast || isFuture || !!diary

  useEffect(() => {
    if (date) {
      fetchDiary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const fetchDiary = async () => {
    try {
      const { data } = await getDiaries({ token, date, user: profile.id });
      const diary = data && data.length > 0 ? data[0] : null
      Promise.all([
        setMood(diary ? diary.mood : ''),
        setComment(diary ? diary.comment : ''),
        setDiary(diary)
      ])
    } catch (error) {
      console.log(error)
      Promise.all([
        setMood(''),
        setComment(''),
        setDiary(null)
      ])
    }
  };

  const onSave = async () => {
    setLoading(true);

    try {
      if (diary && diary.id) {
        const { data } = await updateDiary({ id: diary.id, token, mood, comment });
        setDiary(data)
      } else {
        await createDiary({ token, mood, comment, date });
        await fetchDiary()
      }
      alert.show(t('DIARY.SUCCESS_SAVE'))
    } catch (error) {
      alert.show(t("GENERIC_ERROR"));
    } finally {
      setLoading(false);
    }

  };

  if (isFuture || (isPast && !diary)) {
    return null
  }

  return (
    <DiaryContainer>
      <StyledP semiBold small bottom={2}>
        {isPast ? t('DIARY.DIARY_OF_DAY') : t('DIARY.HOW_DO_YOU_FEEL')}
      </StyledP>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        <DiaryIcons>
          {
            ["happy", "neutral", "sad"].map(m => {
              return (
                <DiaryMoodIcon
                  key={m}
                  onClick={() => {
                    if (!formDisabled) {
                      setMood(m)
                    }
                  }}
                  className={mood === m ? 'selected' : ''}
                >
                  {m === 'happy' && <img src="../super-icon.png" alt="happy" />}
                  {m === 'sad' && <img src="../bad-icon.png" alt="bad" />}
                  {m === 'neutral' && <img src="../good-icon.png" alt="good" />}

                  <StyledP hour semiBold align="center" top={1} bottom={0}>
                    {t(`DIARY.MOOD.${m}`)}
                  </StyledP>
                </DiaryMoodIcon>
              )
            })
          }
        </DiaryIcons>
        <textarea
          rows="3"
          placeholder={t('DIARY.PLACEHOLDER')}
          value={comment}
          onChange={({ target }) => setComment(target.value)}
          disabled={formDisabled}
        />
        {
          !formDisabled && (
            <PrimaryButton top={1} type="submit" disabled={loading || !mood}>
              {t("SEND")}
            </PrimaryButton>
          )
        }
      </form>
    </DiaryContainer>
  );
};

export default Diary;