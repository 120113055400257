import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { truncate } from "../helpers/capitalize";
import { getCommentTime } from "../helpers/utils";
import { Avatar } from "./Avatar";
import {
  FlexContainer,
  NotificationBadgeRead,
  PostContainer,
  PostHeader,
  StyledP,
} from "./StyledComponents";
import { TextWithLink } from "./TextWithLink";
import Variables from "./Variables";

const LIMIT_TEXT = 35;

const Notification = ({ data }) => {
  const history = useHistory();
  const { t } = useTranslation()
  const { comment, read } = data || {};
  const { id, authorUser, content, related, created_at } = comment || {};
  const { username } = authorUser || {};

  if (!related || related.length === 0) {
    return null
  }

  const post = related[0] || {};
  const { __contentType = '' } = post || {};
  const route = __contentType.toLowerCase() === 'article' ? '/news' : '/workouts'

  return (
    <PostContainer
      paddingRight={3}
      paddingTop={2}
      paddingBottom={2}
      className="pointer"
      onClick={() => history.push(`${route}/${post.id}?comment=${id}`)}
    >
      <PostHeader bottom={1}>
        <FlexContainer align="center">
          <Avatar profile={authorUser} />
          <div style={{ flexGrow: 1 }}>
            <StyledP comment>
              <span style={{ fontWeight: 700 }}>{username}</span> {t('NOTIFICATION_USER_COMMENT')}
            </StyledP>
            <StyledP comment bottom={0}>
              <TextWithLink text={`"${truncate(content, LIMIT_TEXT)}"`} />
              <span
                style={{
                  marginLeft: '12px',
                  color: Variables.lightPurple
                }}
              >
                {getCommentTime(created_at)}
              </span>
            </StyledP>
          </div>
          {
            !read && <NotificationBadgeRead />
          }
        </FlexContainer>
      </PostHeader>

    </PostContainer>
  );
};

export { Notification };
