import React from 'react';
import get from 'lodash.get';
import { ErrorMessage } from './StyledComponents';

function FormErrorMessage({ errors = {}, name = '' }) {
  const message = get(errors, `${name}.message`, '');
  if (!message) {
    return null;
  }
  return <ErrorMessage>{message}</ErrorMessage>;
}

export default FormErrorMessage;
