import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, FormGroup, Label } from "reactstrap";
import { BackButton, FlexContainer, PrimaryBackground, PrimaryButton, StyledP } from "../components/StyledComponents";
import { Input } from "../components/WrappedInput";
import FormErrorMessage from "../components/FormErrorMessage";
import { useAlert } from "../providers/alert";
import { useLoading } from "../providers/loading";
import { createContact } from "../api/contacts";
import { useAuth } from "../providers/auth";
import { formatError } from "../helpers/formatError";
import { HeaderLogo } from "../components/HeaderLogo";

export function Contact({ history }) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const alert = useAlert()
  const loading = useLoading()
  const [{ token, isAuthenticated }] = useAuth()

  const submit = async (body) => {
    try {
      loading.show()
      await createContact({ token, body })
      alert.show(t('CONTACT_SUCCESS'));
      reset()
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }
  return (
    <>
      <PrimaryBackground direction="column">
        <HeaderLogo/>
        <FlexContainer align="center" paddingBottom={4}>
          <BackButton onClick={() => history.goBack()}>
            <img src="../arrow-back.svg" alt="back"></img>
          </BackButton>
          <StyledP semiBold left={1}>{t('MANAGE_SUBSCR')}</StyledP>
        </FlexContainer>
        <Form onSubmit={handleSubmit(submit)}>
          <FormGroup className="mb8">
            <Label for="subject">{t('WHAT_IS_YOUR_PROBLEM')}</Label>
            <Input type="select" name="select" id="subject"
              {...register('subject', {
                required: t('FIELD_REQUIRED'),
              })}
            >
              {
                ['billing', 'workout', 'profile', 'other'].map(s => (
                  <option key={s} value={t(`SUBJECTS.${s}`)}>
                    {t(`SUBJECTS.${s}`)}
                  </option>
                ))
              }
            </Input>
            <FormErrorMessage errors={errors} name="subject" />
          </FormGroup>
          {
            !isAuthenticated && (
              <FormGroup className="mb8">
                <Label for="subject">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  {...register("email", {
                    required: t("FIELD_REQUIRED"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                      message: t("INVALID_EMAIL"),
                    },
                  })}
                />
                <FormErrorMessage errors={errors} name="email" />
              </FormGroup>
            )
          }
          <FormGroup className="mb8">
            <Label for="message">{t('HOW_CAN_WE_HELP')}</Label>
            <Input type="textarea" name="message" id="message"
              {...register('message', {
                required: t('FIELD_REQUIRED'),
              })}
            />
            <FormErrorMessage errors={errors} name="message" />
          </FormGroup>
          <PrimaryButton top={2}>{t('SEND_REQUEST')}</PrimaryButton>
        </Form>
      </PrimaryBackground>
    </>
  );
}