import { BackButton, FlexContainer, PrimaryBackground, PrimaryButton, SecondaryButtonSmall, StyledP } from "../components/StyledComponents";
import { useAuth } from "../providers/auth";
import { useTranslation } from "react-i18next";
import AvatarEditor from 'react-avatar-editor'
import { updateAvatar } from "../api/auth";
import { useAlert } from "../providers/alert";
import { useLoading } from "../providers/loading";
import { useRef, useState, useEffect } from "react";
import { formatError } from "../helpers/formatError";
import { Input, FormGroup, Label } from 'reactstrap'

const CENTER_POSITION = { x: 0.5, y: 0.5 }

export function ProfileAvatar(props) {
  const editor = useRef(null);
  const inputEl = useRef(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState(CENTER_POSITION);
  const [image, setImage] = useState();
  const alert = useAlert()
  const { history } = props;
  const { t } = useTranslation()
  const loading = useLoading()
  const [{ profile, token }, dispatch] = useAuth()

  useEffect(() => {
    if (profile.avatar) {
      setImage(profile.avatar.url)
    }
  }, [profile])

  const onSave = async () => {
    try {
      loading.show()

      const canvas = editor.current.getImageScaledToCanvas().toDataURL('image/jpeg', 1.0)
      const res = await fetch(canvas)
      const blob = await res.blob()
      blob.lastModifiedDate = new Date();
      blob.name = `${profile.id}.jpg`;
      const { data = [] } = await updateAvatar({ token, id: profile.id, file: blob })
      dispatch({ type: 'setProfile', profile: { ...profile, avatar: data[0] } });
      history.goBack()
    } catch (err) {
      console.log(err)
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }

  const handleImageInputChange = (event) => {
    const { target } = event || {}
    const { files } = target || {}
    const file = files && files[0]
    if (!file) return
    setImage(file)
    target.value = null
  }

  return (
    <>
      <PrimaryBackground direction="column">
        <FlexContainer align="center" paddingBottom={4} top={1}>
          <BackButton onClick={() => history.goBack()}>
            <img src="../arrow-back.svg" alt="back"></img>
          </BackButton>
          <StyledP semiBold left={1}>{t('GO_BACK')}</StyledP>
        </FlexContainer>
        <PrimaryButton
          onClick={() => inputEl.current.click()}
        >
          Carica immagine
          <input
            type="file"
            ref={inputEl}
            accept="image/*"
            onChange={handleImageInputChange}
            style={{ display: 'none' }}
          />
        </PrimaryButton>,


        <AvatarEditor
          ref={editor}
          image={image}
          border={8}
          borderRadius={300}
          height={150}
          width={150}
          color={[43, 41, 81, 0.6]} // RGBA
          scale={scale}
          crossOrigin="anonymous"
          position={position}
          onPositionChange={e => setPosition(e)}
          style={{ width: '100%', height: 'auto' }}
        />

        <FormGroup className="avatar-form">
          <Label for="zoom">Zoom</Label>
          <Input
            type="range"
            name="zoom"
            id="zoom"
            min="1"
            max="2"
            step="0.1"
            value={scale}
            onChange={e => setScale(Number(e.target.value))}
          />
        </FormGroup>


        <SecondaryButtonSmall className="margin-auto" top={3} onClick={() => setPosition(CENTER_POSITION)}>{t('CENTER')}</SecondaryButtonSmall>
        <PrimaryButton top={3} className="mt-auto" onClick={onSave}>{t('SAVE')}</PrimaryButton>
      </PrimaryBackground>
    </>
  );
}