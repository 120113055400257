export const NATIONS = [
  { name: 'Italia', id: 'IT' },
  { name: 'Afghanistan', id: 'AF' },
  { name: 'Albania', id: 'AL' },
  { name: 'Algeria', id: 'DZ' },
  { name: 'Andorra', id: 'AD' },
  { name: 'Angola', id: 'AO' },
  { name: 'Anguilla', id: 'AI' },
  { name: 'Antartide', id: 'AQ' },
  { name: 'Antigua e Barbuda', id: 'AG' },
  { name: 'Arabia Saudita', id: 'SA' },
  { name: 'Argentina', id: 'AR' },
  { name: 'Armenia', id: 'AM' },
  { name: 'Aruba', id: 'AW' },
  { name: 'Australia', id: 'AU' },
  { name: 'Austria', id: 'AT' },
  { name: 'Azerbaigian', id: 'AZ' },
  { name: 'Bahama', id: 'BS' },
  { name: 'Bahrein', id: 'BH' },
  { name: 'Bangladesh', id: 'BD' },
  { name: 'Barbados', id: 'BB' },
  { name: 'Bielorussia', id: 'BY' },
  { name: 'Belgio', id: 'BE' },
  { name: 'Belize', id: 'BZ' },
  { name: 'Benin', id: 'BJ' },
  { name: 'Bermuda', id: 'BM' },
  { name: 'Bhutan', id: 'BT' },
  { name: 'Bolivia', id: 'BO' },
  { name: 'Bosnia ed Erzegovina', id: 'BA' },
  { name: 'Botswana', id: 'BW' },
  { name: 'Bouvet', id: 'BV' },
  { name: 'Brasile', id: 'BR' },
  { name: 'Brunei', id: 'BN' },
  { name: 'Bulgaria', id: 'BG' },
  { name: 'Burkina Faso', id: 'BF' },
  { name: 'Burundi', id: 'BI' },
  { name: 'Cambogia', id: 'KH' },
  { name: 'Camerun', id: 'CM' },
  { name: 'Canada', id: 'CA' },
  { name: 'Capo Verde', id: 'CV' },
  { name: 'Ciad', id: 'TD' },
  { name: 'Cile', id: 'CL' },
  { name: 'Cina', id: 'CN' },
  { name: 'Città del Vaticano', id: 'VA' },
  { name: 'Colombia', id: 'CO' },
  { name: 'Comore', id: 'KM' },
  { name: 'Corea del Nord', id: 'KP' },
  { name: 'Corea del Sud', id: 'KR' },
  { name: 'Costa d\'Avorio', id: 'CI' },
  { name: 'Costa Rica', id: 'CR' },
  { name: 'Croazia', id: 'HR' },
  { name: 'Cuba', id: 'CU' },
  { name: 'Cipro', id: 'CY' },
  { name: 'Danimarca', id: 'DK' },
  { name: 'Dominica', id: 'DM' },
  { name: 'Ecuador', id: 'EC' },
  { name: 'Egitto', id: 'EG' },
  { name: 'El Salvador', id: 'SV' },
  { name: 'Emirati Arabi Uniti', id: 'AE' },
  { name: 'Eritrea', id: 'ER' },
  { name: 'Estonia', id: 'EE' },
  { name: 'Etiopia', id: 'ET' },
  { name: 'Filippine', id: 'PH' },
  { name: 'Finlandia', id: 'FI' },
  { name: 'Francia', id: 'FR' },
  { name: 'Gabon', id: 'GA' },
  { name: 'Gambia', id: 'GM' },
  { name: 'Georgia', id: 'GE' },
  { name: 'Germania', id: 'DE' },
  { name: 'Ghana', id: 'GH' },
  { name: 'Gibilterra', id: 'GI' },
  { name: 'Gibuti', id: 'DJ' },
  { name: 'Grecia', id: 'GR' },
  { name: 'Groenlandia', id: 'GL' },
  { name: 'Grenada', id: 'GD' },
  { name: 'Guadalupa', id: 'GP' },
  { name: 'Guam', id: 'GU' },
  { name: 'Guatemala', id: 'GT' },
  { name: 'Guinea', id: 'GN' },
  { name: 'Guinea Bissau', id: 'GW' },
  { name: 'Guinea Equatoriale', id: 'GQ' },
  { name: 'Guyana', id: 'GY' },
  { name: 'Guayana Francese', id: 'GF' },
  { name: 'Haiti', id: 'HT' },
  { name: 'Honduras', id: 'HN' },
  { name: 'Hong kong', id: 'HK' },
  { name: 'Ungheria', id: 'HU' },
  { name: 'India', id: 'IN' },
  { name: 'Indonesia', id: 'ID' },
  { name: 'Iran', id: 'IR' },
  { name: 'Iraq', id: 'IQ' },
  { name: 'Irlanda', id: 'IE' },
  { name: 'Islanda', id: 'IS' },
  { name: 'Isole Cayman', id: 'KY' },
  { name: 'Isola Christmas', id: 'CX' },
  { name: 'Isole Cocos', id: 'CC' },
  { name: 'Isole Cook', id: 'CK' },
  { name: 'Isole Faer oer', id: 'FO' },
  { name: 'Isole Falkland', id: 'FK' },
  { name: 'Isole Figi', id: 'FJ' },
  { name: 'Isole Marianne Settentrionali', id: 'MP' },
  { name: 'Isole Marshall', id: 'MH' },
  { name: 'Isole Vergini Americane', id: 'VI' },
  { name: 'Isole Vergini Britanniche', id: 'VG' },
  { name: 'Israele', id: 'IL' },
  { name: 'Giamaica', id: 'JM' },
  { name: 'Giappone', id: 'JP' },
  { name: 'Giordania', id: 'JO' },
  { name: 'Kazakistan', id: 'KZ' },
  { name: 'Kenya', id: 'KE' },
  { name: 'Kiribati', id: 'KI' },
  { name: 'Kuwait', id: 'KW' },
  { name: 'Kirghizistan', id: 'KG' },
  { name: 'Laos', id: 'LA' },
  { name: 'Lettonia', id: 'LV' },
  { name: 'Libano', id: 'LB' },
  { name: 'Lesotho', id: 'LS' },
  { name: 'Liberia', id: 'LR' },
  { name: 'Libia', id: 'LY' },
  { name: 'Liechtenstein', id: 'LI' },
  { name: 'Lituania', id: 'LT' },
  { name: 'Lussemburgo', id: 'LU' },
  { name: 'Macao', id: 'MO' },
  { name: 'Macedonia', id: 'MK' },
  { name: 'Madagascar', id: 'MG' },
  { name: 'Malawi', id: 'MW' },
  { name: 'Malaysia', id: 'MY' },
  { name: 'Maldive', id: 'MV' },
  { name: 'Mali', id: 'ML' },
  { name: 'Malta', id: 'MT' },
  { name: 'Martinica', id: 'MQ' },
  { name: 'Mauritania', id: 'MR' },
  { name: 'Mauritius', id: 'MU' },
  { name: 'Mayotte', id: 'YT' },
  { name: 'Messico', id: 'MX' },
  { name: 'Micronesia', id: 'FM' },
  { name: 'Moldavia', id: 'MD' },
  { name: 'Monaco', id: 'MC' },
  { name: 'Mongolia', id: 'MN' },
  { name: 'Montserrat', id: 'MS' },
  { name: 'Marocco', id: 'MA' },
  { name: 'Mozambico', id: 'MZ' },
  { name: 'Myanmar', id: 'MM' },
  { name: 'Namibia', id: 'NA' },
  { name: 'Nauru', id: 'NR' },
  { name: 'Nepal', id: 'NP' },
  { name: 'Paesi Bassi', id: 'NL' },
  { name: 'Nuova Caledonia', id: 'NC' },
  { name: 'Nuova Zelanda', id: 'NZ' },
  { name: 'Nicaragua', id: 'NI' },
  { name: 'Niger', id: 'NE' },
  { name: 'Nigeria', id: 'NG' },
  { name: 'Niue', id: 'NU' },
  { name: 'Norfolk', id: 'NF' },
  { name: 'Norvegia', id: 'NO' },
  { name: 'Oman', id: 'OM' },
  { name: 'Pakistan', id: 'PK' },
  { name: 'Palau', id: 'PW' },
  { name: 'Panama', id: 'PA' },
  { name: 'Papua Nuova Guinea', id: 'PG' },
  { name: 'Paraguay', id: 'PY' },
  { name: 'Perù', id: 'PE' },
  { name: 'Polinesia Francese', id: 'PF' },
  { name: 'Polonia', id: 'PL' },
  { name: 'Portogallo', id: 'PT' },
  { name: 'Puerto Rico', id: 'PR' },
  { name: 'Qatar', id: 'QA' },
  { name: 'Regno Unito', id: 'GB' },
  { name: 'Repubblica Ceca', id: 'CZ' },
  { name: 'Repubblica Centrafricana', id: 'CF' },
  { name: 'Repubblica Democratica del Congo', id: 'CD' },
  { name: 'Repubblica Dominicana', id: 'DO' },
  { name: 'Repubblica Popolare del Congo', id: 'CG' },
  { name: 'Riunione', id: 'RE' },
  { name: 'Russia', id: 'RU' },
  { name: 'Ruanda', id: 'RW' },
  { name: 'Sahara Occidentale', id: 'EH' },
  { name: 'Sant\'Elena', id: 'SH' },
  { name: 'Saint Kitts e Nevis', id: 'KN' },
  { name: 'Saint Lucia', id: 'LC' },
  { name: 'Saint Pierre et Miquelon', id: 'PM' },
  { name: 'Saint Vincent e Grenadine', id: 'VC' },
  { name: 'Samoa Swain', id: 'AS' },
  { name: 'San Marino', id: 'SM' },
  { name: 'Sao Tomè e Principe', id: 'ST' },
  { name: 'Senegal', id: 'SN' },
  { name: 'Seychelles', id: 'SC' },
  { name: 'Sierra Leone', id: 'SL' },
  { name: 'Singapore', id: 'SG' },
  { name: 'Siria', id: 'SY' },
  { name: 'Slovacchia', id: 'SK' },
  { name: 'Slovenia', id: 'SI' },
  { name: 'Salomone', id: 'SB' },
  { name: 'Somalia', id: 'SO' },
  { name: 'Spagna', id: 'ES' },
  { name: 'Sri Lanka', id: 'LK' },
  { name: 'Stati Uniti', id: 'US' },
  { name: 'Sudafricana', id: 'ZA' },
  { name: 'Sudan', id: 'SD' },
  { name: 'Sudan del Sud', id: 'SS' },
  { name: 'Suriname', id: 'SR' },
  { name: 'Svalbard e Jan Mayen', id: 'SJ' },
  { name: 'Svezia', id: 'SE' },
  { name: 'Svizzera', id: 'CH' },
  { name: 'Swaziland', id: 'SZ' },
  { name: 'Taiwan', id: 'TW' },
  { name: 'Tagikistan', id: 'TJ' },
  { name: 'Tanzania', id: 'TZ' },
  { name: 'Territori Australi e Antartici Francesi', id: 'TF' },
  { name: 'Territorio Brit. dell\'Oceano Indiano', id: 'IO' },
  { name: 'Thailandia', id: 'TH' },
  { name: 'Togo', id: 'TG' },
  { name: 'Tokelau', id: 'TK' },
  { name: 'Tonga', id: 'TO' },
  { name: 'Trinidad e Tobago', id: 'TT' },
  { name: 'Tunisia', id: 'TN' },
  { name: 'Turchia', id: 'TR' },
  { name: 'Turkemenistan', id: 'TM' },
  { name: 'Turks e Caicos', id: 'TC' },
  { name: 'Tuvalu', id: 'TV' },
  { name: 'Uganda', id: 'UG' },
  { name: 'Ucraina', id: 'UA' },
  { name: 'Uruguay', id: 'UY' },
  { name: 'Uzbekistan', id: 'UZ' },
  { name: 'Vanuatu', id: 'VU' },
  { name: 'Venezuela', id: 'VE' },
  { name: 'Vietnam', id: 'VN' },
  { name: 'Wallis e Futuna', id: 'WF' },
  { name: 'Yemen', id: 'YE' },
  { name: 'Zambia', id: 'ZM' },
  { name: 'Zimbabwe', id: 'ZW' },
];