import styled, { createGlobalStyle } from "styled-components";
import { WORKOUT_LEVELS } from "../helpers/workout";
import { flex, margin, alignment, padding } from "./Mixins";
import variables from "./Variables";

export const GlobalStyle = createGlobalStyle`
  body {
    max-width: 480px;
    font-family: ${variables.Inter};
    @media only screen and (min-width: 480px){
      margin: 0 auto;
      background-color: ${variables.lightPurple};
    }
  }
  a {
    color: ${variables.secondary};
    text-decoration: none;
    width: 100%;
  }
  textarea::-webkit-input-placeholder {
    color: ${variables.lightPurple};
  }
  
  textarea:-moz-placeholder { /* Firefox 18- */
    color: ${variables.lightPurple};  
  }
  
  textarea::-moz-placeholder {  /* Firefox 19+ */
    color: ${variables.lightPurple};  
  }
  
  textarea:-ms-input-placeholder {
    color: ${variables.lightPurple};  
  }
  
  textarea::placeholder {
    color: ${variables.lightPurple};  
  }
  form{
    width: 100%;
    .form-group{
      .form-control[readonly] {
        background-color: ${variables.inputBackground};
      }
      input, select, textarea{
        background-color: ${variables.inputBackground};
        border: 1px solid rgba(124, 124, 160, .10);
        padding: 16px;
        color: ${variables.white};
        &::placeholder{
          color: ${variables.lightPurple};
        }
        &:focus{
          border: 1px solid ${variables.secondary};
          background-color: ${variables.inputBackground};
          box-shadow: 0 0 0 0.20rem rgb(241 115 190 / 15%);
          color: ${variables.white};
        }
      }
      select{
        background: url(arrow-down.svg) no-repeat 98% ${
          variables.inputBackground
        };
      }
      textarea{
        min-height: 150px;
        resize: none;
      }
    }
    label{
      font-size: 14px;
      color: ${variables.lightPurple};
    }
  }

  .input-group{
    .form-control{
      background-color: ${variables.inputBackground};
      border: 1px solid rgba(124, 124, 160, .10);
      border-right: none;
      padding: 16px;
      color: ${variables.white};
      &::placeholder{
        color: ${variables.lightPurple};
      }
    }
    .input-group-text{
      background-color: ${variables.inputBackground};
      color: ${variables.white};
      padding: 16px;
      border: 1px solid #111238; 
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &.profile{
        color: ${variables.lightPurple};
      }
    }
    input[type=number]{
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
  }

  .form-check{
    input{
      background-color: ${variables.inputBackground};
      border: 1px solid rgba(124, 124, 160, .10);
      :checked{
        background-color: ${variables.secondary};
        border: 1px solid ${variables.secondary};
      }
    }
  }

  .alertModal{
    .modal-content{
      width: 80%;
      margin: 0 auto;
      .modal-header{
        border: none;
        padding-bottom: 0;
      }
      .modal-body{
        padding: 24px;
        padding-bottom: 0;
        width: 80%;
        margin: 0 auto;
      }
      .modal-footer{
        border: none;
        padding: 24px;
        justify-content: space-between;
      }
      background-color: ${variables.primary};
      color: ${variables.lightPurple};
    }
  }
  .alertDiary{
    .modal-content{
      width: 90%;
      margin: 0 auto;
      padding: 16px;
      background-color: ${variables.primary};
      .modal-body{
        padding: 0;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  .paymentModal{
    .modal-header{
      border-bottom: 0;
      justify-content: center;
      padding-bottom: 0;
    }
    .modal-body{
      label{
        color: ${variables.lightPurple}
      }
    }
  }

  .mb8{
    margin-bottom: 8px;
  }
  .mb16{
    margin-bottom: 16px;
  }
  .margin-auto{
    margin: 0 auto;
  }

  .avatar-form{
    margin-top: 16px;
    width: 100%;
    ${flex({ direction: "column" })}
    label{
      color: ${variables.lightPurple};
      margin-bottom: 8px;
    }
    input{
      width: 100%;
    }
    input[type="range"] {
      -webkit-appearance: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      width: 100%;
      height: 20px;
      margin: 0;
      padding: 0;
      border: none;
      border-radius: .375em;
      background-color: ${variables.inputBackground};
      outline: none;
      cursor: pointer;
    }
    
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
     background-color: ${variables.secondary}!important;
     width: 20px;
     height: 20px;
     border-radius: 50%;
    }
  }

  .fc-daygrid-day-frame{
    height: 60px;
  }
  .fc-daygrid-day{
    height: 60px;
  }
  .fc-col-header-cell{
    background-color: ${variables.primary};
    a{
      color: ${variables.white};
    }
  }

  .alertDiaryBackdrop {
    background-color: #555
  }
`;

// TYPO //

export const StyledH1 = styled.h1`
  font-size: 42px;
  ${margin};
  font-weight: 600;
  color: ${variables.white};
  ${alignment};
`;

export const StyledH2 = styled.h2`
  font-size: 34px;
  ${margin};
  font-weight: 600;
  color: ${variables.white};
  ${alignment};
`;

export const StyledH3 = styled.h3`
  font-size: 26px;
  ${margin};
  font-weight: 600;
  color: ${(props) =>
    props.color === "purple" ? variables.primary : variables.white};
  ${alignment};
`;

export const StyledH4 = styled.h4`
  font-size: 20px;
  ${margin};
  font-weight: 600;
  color: ${variables.white};
  ${alignment};
`;

export const StyledP = styled.p`
  font-size: ${(props) =>
    props.small
      ? "16px"
      : props.calendar
      ? "10px"
      : props.comment
      ? "14px"
      : props.hour
      ? "12px"
      : "18px"};
  ${margin};
  font-weight: ${(props) => (props.semiBold ? "600" : "400")};
  color: ${(props) =>
    props.color === "purple"
      ? variables.lightPurple
      : props.color === "pink"
      ? variables.secondary
      : variables.white};
  ${alignment};
`;

export const AlertAnchorContainer = styled.div`
  background-color: ${variables.secondary};
  padding: 16px;
  border-radius: 3px;
  width: 160px;
  margin: 0;
  ${flex({ justify: "center" })}
  ${margin};
  font-weight: 600;
  font-size: 15px;
  a {
    color: white;
    width: initial;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 10px;
  margin-top: 4px;
  margin-bottom: 0;
  padding-left: 8px;
  font-weight: 400;
  color: ${variables.secondary};
  ${alignment};
`;

// BUTTONS //

export const PrimaryButton = styled.button`
  ${flex({ direction: "column", justify: "center", align: "center" })};
  background-color: ${(props) =>
    props.white ? variables.white : variables.secondary};
  color: ${(props) => (props.white ? variables.secondary : variables.white)};
  padding: 16px;
  font-size: 17px;
  font-weight: 600;
  border: none;
  width: 100%;
  ${margin};
  border-radius: 3px;
  &[disabled] {
    opacity: 0.6;
  }
`;

export const WorkoutButton = styled(PrimaryButton)`
  background-color: ${(props) =>
    (WORKOUT_LEVELS.find(({ level }) => level === props.level) || {}).color};
  padding: 20px;
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${(props) =>
    props.whiteButton ? variables.white : variables.inputBackground};
  color: ${(props) =>
    props.whiteButton ? variables.primary : variables.white};
`;

export const PrimaryButtonSmall = styled(PrimaryButton)`
  width: 150px;
`;

export const PrimaryButtonCalendar = styled(PrimaryButtonSmall)`
  width: 120px;
  padding: 4px;
  font-size: 10px;
`;

export const PrimaryButtonComment = styled(PrimaryButtonSmall)`
  width: 120px;
  font-size: 12px;
  background-color: ${variables.primary};
  padding: 0 8px;
  align-items: flex-start;
`;

export const SecondaryButtonSmall = styled(PrimaryButtonSmall)`
  background-color: ${(props) =>
    props.whiteButton ? variables.white : variables.inputBackground};
  color: ${(props) =>
    props.whiteButton ? variables.primary : variables.white};
`;

export const SingleSelectionButton = styled.button`
  ${flex({ justify: "center", align: "center" })};
  background-color: ${variables.inputBackground};
  color: ${variables.white};
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid rgba(124, 124, 160, 0.1);
  ${margin};
  border-radius: 30px;
  width: 150px;
  &.selected {
    color: ${variables.secondary};
    border: 1px solid ${variables.secondary};
  }
`;

export const MultipleSelectionButton = styled.button`
  ${flex({ align: "center" })};
  background-color: ${variables.inputBackground};
  color: ${variables.white};
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid rgba(124, 124, 160, 0.1);
  ${margin};
  border-radius: 3px;
  width: 100%;
  position: relative;
  &.selected {
    background-color: ${variables.secondary};
    border: 1px solid ${variables.secondary};
    &::after {
      content: "";
      position: absolute;
      right: 24px;
      width: 30px;
      height: 30px;
      background-color: rgba(4, 4, 46, 0.15);
      border-radius: 50%;
      background-image: url(../checkmark.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const PlanCardButton = styled.button`
  ${flex({ justify: "center", align: "center" })};
  background-color: ${variables.white};
  color: ${variables.secondary};
  padding: 8px;
  font-size: 15px;
  font-weight: 600;
  ${margin};
  border-radius: 3px;
  width: 100%;
  border: none;
`;

export const BackButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  ${flex({ justify: "center", align: "center" })};
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
`;

export const TransparentButton = styled.button`
  ${flex({ justify: "center", align: "center" })};
  background-color: transparent;
  border: none;
`;

export const MostSearchedButton = styled.button`
  ${flex};
  ${margin};
  width: 100%;
  background-color: ${variables.inputBackground};
  padding: 16px;
  border: none;
  border-radius: 3px;
`;

export const StyledFilterButton = styled.button`
  ${flex({ justify: "center", align: "center" })};
  ${margin};
  font-size: 13px;
  padding: 8px 12px;
  border-radius: 25px;
  background-color: ${variables.inputBackground};
  color: ${variables.white};
  border: none;
  &.active {
    background-color: ${variables.secondary};
  }
`;

// GENERAL //

export const HeaderLogoContainer = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.padding === "true" ? "24px" : "0px")};
  padding-left ${(props) => (props.padding === "true" ? "24px" : "0px")};
  padding-right: ${(props) => (props.paddingRight || "16px")};
  padding-bottom: ${(props) =>
    props.workoutDetail === "true" ? "8px" : "16px"};

  display: flex;
  justify-content: space-between;
`;

export const PrimaryBackground = styled.div`
  ${flex};
  background-color: ${variables.primary};
  height: ${(props) => (props.auto === "true" ? "auto" : "100vh")};
  padding: ${(props) => (props.padding === "0" ? "0" : "24px")};
  padding-right: ${(props) => (props.right === "0" ? "0" : "")};
  overflow: hidden;
  overflow-y: scroll;
  position: ${(props) => (props.news ? "relative" : "")};
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DatePickerContainer = styled.div`
  position: relative;
  ${flex}
  ul {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: none;
    span {
      display: none;
    }
    li {
      justify-content: center;
      font-family: ${variables.Inter};
      p {
        padding: 0;
      }
    }
  }
`;

export const DatePickerFade = styled.div`
  position: absolute;
  background: ${(props) =>
    props.bottom
      ? "linear-gradient(rgba(4,4,46,.5), #04042E)"
      : "linear-gradient(#04042E, rgba(4,4,46,.5))"};
  z-index: 2;
  opacity: 0.5;
  width: 100%;
  height: ${(props) => (props.bottom ? "57px" : "82px")};
  bottom: ${(props) => (props.bottom ? "0" : "initial")};
  pointer-events: none;
`;

export const Badge = styled.div`
  background-color: ${variables.white};
  ${margin};
  padding: 4px 6px 4px 23px;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  border-radius: 3px;
  width: fit-content;
  &::before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    left: 6px;
    top: 6px;
    background-image: url(../star.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const BadgeCompleted = styled.div`
  background-color: ${variables.white};
  ${margin};
  padding: 4px 4px 4px 24px;
  position: relative;
  border-radius: 3px;
  width: fit-content;
  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 6px;
    bottom: 4px;
    background-image: url(../cup.svg);
    background-repeat: no-repeat;
    background-position: left;
  }
`;
export const FlexContainer = styled.div`
  ${flex};
  ${padding};
  ${margin};
  width: ${(props) => (props.fitContent ? "fit-content" : "100%")};
  background-color: ${(props) =>
    props.profile === "true" ? variables.inputBackground : "transparent"};
  border-radius: ${(props) => (props.profile === "true" ? "3px" : "none")};
`;

// WELCOME SCREEN //

export const WelcomeBackground = styled.div`
  position: relative;
  ${flex({ direction: "column", align: "center" })};
  background-color: ${variables.primary};
  background-image: url("../../taylor-background-2.png");
  background-repeat: no-repeat;
  background-position: top center;
  height: 100vh;
  img {
    margin-top: 24px;
  }
  @media only screen and (min-width: 375px) {
    background-size: cover;
  }
`;

export const FadeContainer = styled.div`
  ${flex({ direction: "column", justify: "end", align: "center" })};
  height: 50vh;
  padding: 0 24px;
  background: linear-gradient(rgba(4, 4, 46, 0), #04042e);
  width: 100%;
  color: ${variables.white};
  margin-top: auto;
  text-align: center;
  box-sizing: border-box;
  h1 {
    align-self: flex-start;
  }
`;

// LOGIN //

export const LoginContainer = styled.div`
  ${flex({ direction: "column", justify: "end", align: "center" })};
  padding: 24px;
  background-color: ${variables.primary};
  width: 100%;
  color: ${variables.white};
  margin-top: auto;
  text-align: center;
  box-sizing: border-box;
  border-radius: 24px 24px 0 0;
`;

// STEPS //

export const ButtonsContainer = styled.div`
  ${flex};
  ${margin};
  width: 100%;
`;

// PLAN //

export const PlanCard = styled.div`
  background-color: ${(props) =>
    props.pink ? variables.secondary : variables.inputBackground};
  color: ${variables.white};
  padding: 16px;
  width: 100%;
  position: relative;
  border-radius: 3px;
  ${margin};
  ul {
    > *:not(:last-child) {
      ${margin({ bottom: 1 })};
    }
    padding-left: 20px;
    margin-bottom: 0;
    li {
      list-style-type: none;
      line-height: normal;
      font-size: 14px;
      &::before {
        content: "";
        position: absolute;
        margin-top: 2px;
        left: 16px;
        width: 14px;
        height: 14px;
        background-color: white;
        border-radius: 50%;
        background-image: url(../checkmark-pink.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
`;

export const PlanCardTitle = styled.div`
  ${flex};
  ${margin};
  span {
    opacity: 0.3;
  }
`;

// HOME //

export const TabBar = styled.div`
  ${flex({ justify: "between" })};
  position: fixed;
  bottom: 0;
  background-color: ${variables.white};
  width: 100%;
  left: 0;
  padding: 0 40px;
  max-width: 480px;
  @media only screen and (min-width: 480px) {
    left: 50%;
    transform: translateX(-50%);
  }
  a {
    ${flex({ direction: "column", justify: "center", align: "center" })};
    color: ${variables.tabBarGrey};
    font-size: 12px;
    padding: 16px;
    &.active {
      color: ${variables.primary};
      svg,
      path {
        fill: ${variables.primary};
      }
      .st0 {
        fill: ${variables.primary};
        fill-rule: nonzero;
      }
    }
  }
`;

export const TopBarContainer = styled.div`
  ${flex({ justify: "between", align: "start" })};
  width: 100%;
  ${padding};
  .row-container {
    display: flex;
  }
`;

export const CircleContainer = styled.div`
  ${flex};
  ${margin};
  width: ${(props) => (props.post ? "24px" : "48px")};
  height: ${(props) => (props.post ? "24px" : "48px")};
  border-radius: 50%;
  background-color: ${variables.inputBackground};
  overflow: hidden;
  flex-shrink: 0;
`;

export const StyledWorkoutCard = styled.div`
  ${flex};
  padding: 16px;
  width: 100%;
  min-height: 280px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border-radius: 6px;
  background-size: cover;
  .row-container {
    display: flex;
    margin-bottom: 16px;
  }
`;

export const StyledWorkoutCardMini = styled.div`
  ${flex};
  padding: 16px;
  width: 100%;
  height: 220px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border-radius: 6px;
  @media only screen and (min-width: 375px) {
    background-size: cover;
  }
  .row-container {
    display: flex;
    margin-bottom: 16px;
  }
`;

export const WorkoutInfo = styled.div`
  ${flex({ align: "center" })};
  ${margin};
  color: ${variables.white};
  font-size: 14px;
  font-weight: 600;
  img {
    margin-right: 4px;
  }
`;
export const WorkoutDetailHead = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding: 24px;
  ${margin};
  box-shadow: inset 0 0 0 2000px rgba(43, 41, 81, 0.4);
`;

export const DescriptionInfoContainer = styled.div`
  ${margin};
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  width: 100%;
`;

export const WorkoutList = styled.ul`
  color: ${variables.lightPurple};
  padding-left: 20px;
`;

export const CalendarItem = styled.button`
  background-color: ${variables.inputBackground};
  border-radius: 3px;
  border: none;
  height: 55px;
  min-width: 55px;
  ${flex({ direction: "column", align: "center", justify: "center" })};
  ${padding};
  ${margin};
  margin-right: 6px;
  &.active {
    background-color: ${variables.secondary};
  }
  &.disabled {
    opacity: 0.4;
  }
`;

export const CalendarContainer = styled.div`
  width: 100%;
  ${padding};
  ${margin};
  ${flex({ align: "center", justify: "left" })};
  padding-left: 24px;
  padding-right: 20px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media only screen and (max-width: 768px) {
    padding-left: 4px;
    padding-right: 0px;
  }

  > :first-child {
    margin-left: 24px;
  }
`;

// NEWS //

export const FiltersContainer = styled.div`
  width: 100%;
  ${padding};
  ${flex({ align: "center" })};
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  > :last-child {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      right: -24px;
      width: 24px;
      height: 100%;
    }
  }
`;

export const PostContainer = styled.div`
  position: relative;
  ${padding};
  width: 100%;

  ::after {
    content: "";
    width: ${(props) =>
      props.detail === "true" ? "100%" : "calc(100% - 24px)"};
    height: 1px;
    background-color: ${variables.lightPurple};
    position: absolute;
    margin-top: 16px;
    margin-bottom: 100px;
  }
`;

export const PostHeader = styled.div`
  ${margin};
  ${flex({ justify: "between", align: "center" })};
`;

export const InteractionCount = styled.div`
  ${flex({ align: "center" })};
  ${margin};
  span {
    font-size: 12px;
    color: ${variables.lightPurple};
  }
  img {
    margin-right: 4px;
  }
`;

export const NewsHr = styled.hr`
  color: ${variables.white};
  ${margin};
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.detail === "true" ? "100%" : "170px")};
  overflow: hidden;
  border-radius: 3px;
  ${padding};
  ${margin};
  img {
    max-width: 100%;
  }
`;

export const MediaContainer = styled.div`
  ${padding};
  ${margin};
  width: 100%;
  video {
    max-width: 100%;
  }
`;

// PROFILE //

export const ProfileInfoContainer = styled.div`
  ${flex({ direction: "column", align: "center" })}
  border-left: ${(props) =>
    props.border ? ["1px solid" + variables.white] : "none"};
  ${margin};
  padding: 0 24px;
`;
export const FooterContainer = styled.div`
  ${flex({ direction: "column", align: "center" })};
  ${margin};
  ${padding};
  text-align: center;
  width: 100%;
  p {
    font-size: 12px;
  }
`;

// NEWS DETAIL //

export const SendButton = styled.button`
  width: 38px;
  height: 38px;
  ${flex({ justify: "center", align: "center" })};
  border-radius: 50%;
  background-color: ${variables.secondary};
  color: ${variables.white};
  border: none;
  img {
    width: 16px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const CloseButton = styled(SendButton)`
  width: 22px;
  height: 22px;
`;

export const CommentInputContainer = styled.div`
  position: relative;
  width: 100%;
  ${margin};
  ${CircleContainer} {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 5px;
    left: 6px;
  }
  ${SendButton} {
    position: absolute;
    top: 2px;
    right: 0;
  }
`;

export const CommentInput = styled.input`
  border-radius: 24px;
  width: calc(100% - 46px);
  outline: none;
  background-color: ${variables.inputBackground};
  border: 1px solid rgba(124, 124, 160, 0.1);
  padding: 8px;
  padding-left: 48px;
  color: ${variables.white};
  &::placeholder {
    color: ${variables.lightPurple};
  }
  &:focus {
    border: 1px solid ${variables.secondary};
    background-color: ${variables.inputBackground};
    box-shadow: 0 0 0 0.2rem rgb(241 115 190 / 15%);
    color: ${variables.white};
  }
`;

export const CommentContainer = styled.div`
  background-color: ${variables.inputBackground};
  padding: 8px;
  ${margin};
  border-radius: 0 9px 9px 9px;
  &.selected {
    border: 1px solid rgba(241, 115, 179, 0.4);
  }
  &.focused {
    border: 1px solid ${variables.secondary};
  }
`;

export const AnswerToContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(241, 115, 179, 0.4);
  width: 100%;
  padding: 8px;
`;

export const EllipsisContainer = styled.div`
  flex: 1;
  min-width: 0;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TermsContainer = styled.div`
  color: white;
`;
export const StripeErrorMessage = styled.p`
  font-size: 10px;
  margin-top: -8px;
  margin-bottom: 16px;
  padding-left: 8px;
  font-weight: 400;
  color: ${variables.secondary};
`;

export const WorkoutHR = styled.div`
  background-color: ${variables.lightPurple};
  width: 100%;
  height: 1px;
`;

export const SubscriptionCardCanceled = styled.div`
  background-color: ${variables.white};
  font-weight: 600;
  color: #c42928;
  padding: 16px;
  width: 100%;
  position: relative;
  border-radius: 3px;
  ${margin};
  p {
    font-weight: normal;
    margin-bottom: 0px;
    font-size: 14px;
    color: ${variables.primary};
  }
`;

export const DiaryContainer = styled.div`
  margin-top: 16px;
  padding: 16px;
  textarea {
    width: 100%;
    padding: 12px;
    color: #fff;
    background-color: #2b2950;
    border-radius: 2px;
    border:none;
    resize: none;
    font-size: 14px;
    &:focus {
      outline: none !important;
      border: none;
    }
  }
`;

export const DiaryFabButton = styled.button`
  padding: 12px;
  background: ${variables.secondary};
  border-radius: 26px;
  border: none;
  -webkit-transition: width 2s; /* Safari */
  transition: max-width 2s;
  height: 50px;
  max-width: 50px;
  display: flex;
  align-items: center;
  img {
    width: 26px;
    height: 26px;
  }
  &.animated {
    max-width: 100%;
  }
`

export const DiaryFabButtonText = styled.span`
  display: inline-block;
  padding: 0 12px 0 8px;
  font-weight: 600;
  font-size: 14px;
  color: ${variables.white};
  white-space: nowrap;
`

export const DiaryMoodIcon = styled.div`
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${variables.lightPurple};
  padding: 8px 16px;
  &.selected {
    border: 2px solid ${variables.secondary};
  }
  img {
    max-width: 44px;
  }
`

export const DiaryIcons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const NotificationDiv = styled.div`
  position: relative;
  svg {
    height: 24px;
    width: 18px;
  }
  .count {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    color: ${variables.white};
    background-color: ${variables.secondary};
    border-radius: 100%;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    letter-spacing: -1px;
  }
`

export const NotificationBadgeRead = styled.span`
  height: 12px;
  width: 12px;
  color: ${variables.secondary};
  background-color: ${variables.secondary};
  border-radius: 100%;
`