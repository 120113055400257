import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { COMMENT_TYPE } from "../api/comments";
import { Post } from "../components/Post";
import { PrimaryBackground, StyledP, FlexContainer, BackButton } from "../components/StyledComponents";
import { useNews } from "../providers/news/ModalNews";
import { HeaderLogo } from "./HeaderLogo";
import { PostComments } from "./PostComments";

export function NewsComponent({ article, modal = false }) {
  const { t } = useTranslation();
  const history = useHistory()
  const { hide } = useNews()

  const onClick = () => {
    if (modal) {
      hide()
    }
    history.goBack()
  }

  return (
    <>
      <PrimaryBackground direction="column">
        <HeaderLogo />
        <FlexContainer align="center" paddingBottom={2} top={1}>
          <BackButton onClick={onClick}>
            <img src="../arrow-back.svg" alt="back"></img>
          </BackButton>
          <StyledP semiBold left={1}>{t('GO_BACK')}</StyledP>
        </FlexContainer>
        {
          article && (
            <>
              <Post article={article} detail={true} modal={modal} />
              <PostComments
                commentRef={article.id}
                commentType={COMMENT_TYPE.article}
              />
            </>
          )
        }
      </PrimaryBackground>
    </>
  );
}