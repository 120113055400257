import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  LoginContainer,
  PrimaryButton,
  StyledH1,
  StyledP,
  WelcomeBackground,
} from "../components/StyledComponents";
import { Form, FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import FormErrorMessage from "../components/FormErrorMessage";
import classNames from "classnames";
import { login } from "../api/auth";
import { useLoading } from "../providers/loading";
import { useAlert } from "../providers/alert";
import { formatError } from "../helpers/formatError";
import { useAuth } from "../providers/auth";
import { Input } from "../components/WrappedInput";
import { getErrorIdMessage } from "../helpers/utils";
import { useLocation } from "react-router-dom";
import qs from "query-string";

export function Login() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const loading = useLoading();
  const alert = useAlert();
  const [, dispatch] = useAuth();
  const location = useLocation();

  useEffect(() => {
    const { confirmed } = qs.parse(location.search);

    if (confirmed === "true") {
      alert.show(t("USER_CONFIRMED"));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = async (values) => {
    try {
      localStorage.removeItem('profile-id');
      loading.show();
      const { data = {} } = await login(values);
      dispatch({ type: "login", token: data.jwt, profile: data.user });
      localStorage.setItem('profile-id', data.user.id);
      
    } catch (err) {
      const errorId = getErrorIdMessage(err);
      const options = {};
      if (errorId === "Auth.form.error.confirmed") {
        options.link = {
          route: "/send-confirmation-email",
          label: t("SEND_CONFIRMATION_EMAIL"),
        };
      }
      alert.show(formatError(err), options);
    } finally {
      loading.hide();
    }
  };

  return (
    <WelcomeBackground>
      <img src="../../logo.png" alt="logo" width="150px" />
      <LoginContainer>
        <StyledH1 bottom={3}>Login</StyledH1>
        <Form onSubmit={handleSubmit(onLogin)}>
          <FormGroup className={classNames("mb8", { invalid: errors.email })}>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              {...register("email", {
                required: t("FIELD_REQUIRED"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                  message: t("INVALID_EMAIL"),
                },
              })}
            />
            <FormErrorMessage errors={errors} name="email" />
          </FormGroup>
          <FormGroup className={classNames({ invalid: errors.password })}>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              {...register("password", {
                required: t("FIELD_REQUIRED"),
              })}
            />
            <FormErrorMessage errors={errors} name="password" />
          </FormGroup>
          <StyledP color="purple" top={1} bottom={3}>
            {t("PASSWORD_FORGOT")}{" "}
            <Link to="/forgot-password">{t("CLICK_HERE")}</Link>
          </StyledP>
          <PrimaryButton type="submit" bottom={3}>
            Login
          </PrimaryButton>
          <StyledP color="purple" align="center">
            {t("DONT_HAVE_ACCOUNT")} <Link to="/plan-selection">{t("SIGNUP")}</Link>
          </StyledP>
        </Form>
      </LoginContainer>
    </WelcomeBackground>
  );
}
