import { PrimaryBackground, PrimaryButton, StyledH1, StyledP } from "../components/StyledComponents";
import { useTranslation } from "react-i18next";

export function ForgotPasswordEmail({ history }) {
  const { t } = useTranslation();

  const resend = async () => {
    history.replace('/forgot-password')
  }

  return (
    <PrimaryBackground direction="column" justify="center">
      <StyledH1 bottom={3} align="center">{t('CHECK_YOUR_EMAIL')}</StyledH1>
      <StyledP color="purple" bottom={3} align="center">
        {t('EMAIL_SENT_SENTENCE')}
      </StyledP>
      <PrimaryButton bottom={3} onClick={resend}>
        {t('SEND_AGAIN')}
      </PrimaryButton>
    </PrimaryBackground>
  );
}