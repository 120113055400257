import { PayPalButtons } from "@paypal/react-paypal-js";
import { PlanContext } from "../../providers/plan";
import { useContext } from "react";
import { useAuth } from "../../providers/auth";
import { useAlert } from "../../providers/alert";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../providers/loading";
import { me } from "../../api/auth";
import JSCookies from "js-cookie";
import { eventPurchase, eventRegistrationComplete } from "../../helpers/event";

function PayPalButton({ onClickPaypal, disabled }) {
  const [plan] = useContext(PlanContext);
  const [, dispatch] = useAuth();
  const alert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const loading = useLoading();

  const { unit_amount = 0 } = plan || {};
  const value = (unit_amount.toFixed(2) / 100).toFixed(2);

  const onError = (err) => {
    console.log("err", err);
    alert.show(t("PAYMENT_ERROR"));
    history.replace("/pay-plan");
  };

  const onCancel = () => {
    alert.show(t("PAYMENT_ERROR"));
    history.replace("/pay-plan");
  }

  const onApprove = async (data) => {
    loading.show(t("PAYMENT_WAITING"));
    eventPurchase(value, "paypal");
    eventRegistrationComplete(plan, data.subscriptionID);

    setTimeout(async () => {
      loading.hide();

      try {
        const token = JSCookies.get("token");
        const { data = {} } = await me(token);
        dispatch({ type: "setProfile", profile: data });
      } catch (err) {}
      alert.show(t("PAYPAL_SUCCESS_MESSAGE"));
      history.replace("/");
    }, 30000);
  };

  return (
    <PayPalButtons
      style={{ height: 55 }}
      disabled={disabled}
      createSubscription={(data, actions) => {
        const profileId = localStorage.getItem("profile-id");
        if (!profileId) return;
        console.log("PayPal id", plan.paypal_id);
        return actions.subscription.create({
          plan_id: plan.paypal_id,
          custom_id: profileId,
        });
      }}
      onApprove={onApprove}
      onClick={onClickPaypal}
      onError={onError}
      onCancel={onCancel}
    />
  );
}

export default PayPalButton;
