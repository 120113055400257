import React, { useRef, useEffect, useState } from "react";
import { getComments } from "../api/comments";
import { useAuth } from "../providers/auth";
import { CircleContainer, CommentContainer, FlexContainer, MediaContainer, StyledP, CommentInput, CommentInputContainer, SendButton, PrimaryButtonComment, AnswerToContainer, CloseButton, EllipsisContainer } from "./StyledComponents";
import { Spinner } from "reactstrap";
import { postComment } from "../api/comments";
import { useTranslation } from "react-i18next";
import { Avatar } from "../components/Avatar";
import { useAlert } from "../providers/alert";
import { getAvatarUrl } from "../helpers/profile";
import { getCommentTime } from "../helpers/utils";
import { scrollToId, scrollToIdCenter } from "../helpers/scroller";
import { NumberParam, useQueryParam } from "use-query-params";

const Comment = ({ comment, onAnswer, thread }) => {
  const { t } = useTranslation()
  const [focusedCommentId] = useQueryParam('comment', NumberParam);
  const { authorUser, content, children = [], created_at, id } = comment
  const { firstname = '', lastname = '', username = '' } = authorUser

  const focused = !thread && focusedCommentId === id

  return (
    <React.Fragment>
      <FlexContainer bottom={1} >
        <CircleContainer post>
          <img src={getAvatarUrl(authorUser, '../profile-default.svg')} alt="profile" className="w-100" />
        </CircleContainer>
        <EllipsisContainer>
          <StyledP left={1}>{firstname} {lastname} <small>@{username}</small></StyledP>
        </EllipsisContainer>
      </FlexContainer>
      <MediaContainer paddingLeft={4} bottom={2} id={`comment-${id}`}>
        <CommentContainer
          bottom={1}
          className={(thread && thread.id === id) ? 'selected' : focused ? 'focused' : ''}
        >
          <StyledP color="purple" comment>
            {content}
          </StyledP>
        </CommentContainer>
        <FlexContainer>
          <StyledP hour>{getCommentTime(created_at)}</StyledP>
          <PrimaryButtonComment onClick={() => onAnswer(comment)}>
            {t('ANSWER')}
          </PrimaryButtonComment>
        </FlexContainer>
      </MediaContainer>
      {
        children.map(child => (
          <div key={child.id} style={{ paddingLeft: '20px', width: '100%' }}>
            <Comment comment={child} onAnswer={onAnswer} thread={thread} />
          </div>
        ))
      }
    </React.Fragment>
  )
}

export function PostComments({ commentType, commentRef }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [{ profile, token }] = useAuth()
  const [comments, setComments] = useState([])
  const [content, setContent] = useState()
  const [thread, setThread] = useState(null)
  const inputRef = useRef(null)
  const alert = useAlert()
  const [focusedCommentId] = useQueryParam('comment', NumberParam);

  useEffect(() => {
    if (commentRef) {
      fetchComments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRef])

  useEffect(() => {
    if (comments.length > 0 && focusedCommentId) {
      scrollToIdCenter(`comment-${focusedCommentId}`)
    }
  }, [comments, focusedCommentId])

  const fetchComments = async (addedId) => {
    try {
      setLoading(true)
      const { data = [] } = await getComments({
        type: commentType,
        token,
        refId: commentRef
      })
      setComments(data)

      if (addedId) {
        setTimeout(() => scrollToId(`comment-${addedId}`), 100)
      }

    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const send = async () => {
    if (!content) return
    try {
      setLoading(true)

      const { data } = await postComment({
        type: commentType,
        token,
        refId: commentRef,
        content,
        profile,
        threadOf: thread && thread.id
      })

      setContent('')
      setThread(null)

      fetchComments(data.id)
    } catch (err) {
      console.log(err)
      alert.show(t('COMMENT_ERROR_SENT'))
    } finally {
      setLoading(false)
    }
  }

  const onAnswer = (comment) => {
    setThread(comment)
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <>
      {
        thread && (
          <AnswerToContainer>
            <StyledP comment>{t('YOU_ARE_ANSWERING')} @{thread.authorUser.username}</StyledP>
            <CloseButton onClick={() => onAnswer(null)}>
              <img src="../close-white.svg" alt="close" style={{ height: '8px', width: '8px' }} />
            </CloseButton>
          </AnswerToContainer>
        )
      }
      <CommentInputContainer top={1} bottom={2} id="comment-input">
        <Avatar profile={profile} />
        <CommentInput
          ref={inputRef}
          value={content}
          placeholder={t('INSERT_COMMENT')}
          onChange={e => setContent(e.target.value)}
        />
        {
          loading ? (
            <div style={{
              float: 'right',
              marginTop: '6px',
              marginRight: '6px',
            }}>
              <Spinner />
            </div>
          ) : (
            <SendButton onClick={send} disabled={!content}>
              <img src="../send.svg" alt="send icon"></img>
            </SendButton>
          )
        }
      </CommentInputContainer>
      {
        comments.map(comment => (
          <Comment
            key={comment.id}
            comment={comment}
            thread={thread}
            onAnswer={onAnswer}
          />
        ))
      }
    </>
  );
}