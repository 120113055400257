import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../providers/auth";

function CalendarCellContent({ event, dayNumberText }) {
  const history = useHistory();
  const [{ profile }] = useAuth()

  // per l'account di Taylor (id=6), mostrare sempre completati
  const completed = (profile && profile.id === 6) || (event && event.completed)

  return (
    <div
      onClick={() => history.push(`/workouts/${event.id}`)}
      style={{ height: "76px" }}
    >
      <p
        style={{
          padding: 0,
          margin: 0,
          backgroundColor: completed ? "#F173BE" : "#FFFFFF",
          color: completed ? "#FFFFFF" : "#04042E",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <span>{dayNumberText}</span>
      </p>
    </div>
  );
}

export default CalendarCellContent;
