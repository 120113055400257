import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getArticles, getCategories } from "../api/articles";
import { FilterButton } from "../components/FilterButton";
import { Menu } from "../components/Menu";
import { Post } from "../components/Post";
import {
  FiltersContainer,
  NewsHr,
  PrimaryBackground,
  StyledH4,
} from "../components/StyledComponents";
import { formatError } from "../helpers/formatError";
import { useAlert } from "../providers/alert";
import { useAuth } from "../providers/auth";
import { useLoading } from "../providers/loading";
import {
  useQueryParams,
  NumericArrayParam,
  withDefault,
} from "use-query-params";
import { useNews } from "../providers/news/ModalNews";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { HeaderLogo } from "../components/HeaderLogo";
import { NotificationIcon } from "../components/NotificationIcon";

const LIMIT = 20;

export function News() {
  const { i18n } = useTranslation();
  const loading = useLoading();
  const alert = useAlert();
  const [{ token }] = useAuth();
  const [categories, setCategories] = useState([]);
  const [start, setStart] = useState(0);
  const [articles, setArticles] = useState({ items: [], total: 0 });
  const { show } = useNews();

  const [query, setQuery] = useQueryParams({
    cat: withDefault(NumericArrayParam, []),
  });
  const { cat = [] } = query;
  const { items = [], total = 0 } = articles;

  const [sentryRef] = useInfiniteScroll({
    loading: loading.isOpen,
    hasNextPage: items.length < total,
    onLoadMore: () => {
      setStart(start + LIMIT);
    },
  });

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cat), start]);

  const search = async () => {
    if (loading.isOpen) {
      return;
    }
    try {
      loading.show();
      if (categories.length === 0) {
        const { data = [] } = await getCategories({
          token,
          lang: i18n.language,
        });
        setCategories(data);
      }
      const { data = [], headers } = await getArticles({
        token,
        lang: i18n.language,
        limit: LIMIT,
        categories: cat,
        start,
      });
      const total = Number(headers["x-total-count"]);
      setArticles({
        items: items.concat(data),
        total,
      });
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide();
    }
  };

  const toggleCategory = (id) => {
    setArticles({ items: [], total: 0 });
    setStart(0);
    if (cat.includes(id)) {
      setQuery({ cat: cat.filter((c) => c !== id) });
    } else {
      setQuery({ cat: cat.concat([id]) });
    }
  };

  return (
    <>
      <PrimaryBackground direction="column" right="0" news>
        <HeaderLogo>
          <NotificationIcon />
        </HeaderLogo>

        <StyledH4 bottom={2}>
          News
        </StyledH4>
        <FiltersContainer paddingBottom={3} paddingTop={2}>
          {categories.map((c) => (
            <FilterButton
              key={c.id}
              active={cat.includes(c.id)}
              category={c.name}
              onClick={() => toggleCategory(c.id)}
            />
          ))}
        </FiltersContainer>
        <NewsHr />
        <div className="articles-cont w-100">
          {items.map((article) => (
            <Post
              key={article.id}
              article={article}
              onClick={() => show(article)}
            />
          ))}
        </div>
        {items.length < total && <div ref={sentryRef} />}
      </PrimaryBackground>
      <Menu />
    </>
  );
}
