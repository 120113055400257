import React, { } from "react";
import { HeaderLogoContainer } from "./StyledComponents";

export const HeaderLogo = ({ padding, workoutDetail, children, paddingRight }) => {

  return (
    <HeaderLogoContainer padding={padding} workoutDetail={workoutDetail} paddingRight={paddingRight}>
      <img src="../../logo.png" alt="logo" width="90px" />
      {children}
    </HeaderLogoContainer>
  );
};
