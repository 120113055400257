import axios from "axios";

export const completeWorkout = async ({ id, token }) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/workout-completed`,
    {
      workout: id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
