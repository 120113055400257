import axios from "axios";

export const search = async ({ token, query, limit, start, lang = "it" }) => {
  const params = {
    _q: query,
    _locale: lang.substring(0, 2),
    _limit: limit,
    _start: start,
  };

  return await axios.get(`${process.env.REACT_APP_API_URL}/search`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};
