/* eslint-disable no-unused-vars */

import React, { useContext, useState, createContext } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { NewsComponent } from '../../components/NewsComponent';
import './NewsModal.css'
import { useQueryParam, NumberParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../loading';
import { getArticle } from '../../api/articles';
import { useAuth } from '../auth';
import { formatError } from '../../helpers/formatError';

const NewsContext = createContext();

const ModalNewsProvider = ({ children }) => {
  const [article, setArticle] = useState();
  const [modal, setModal] = useQueryParam('modal', NumberParam);
  const { i18n } = useTranslation();
  const loading = useLoading();
  const [{ token }] = useAuth();

  const toggle = () => {
    setArticle(null);
  };

  const show = async (article) => {
    try {
      loading.show()
      const { data = {} } = await getArticle({ token, lang: i18n.language, id: article.id })
      setArticle(data)
      setModal(1)
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  };

  const newsContext = {
    article,
    show,
    hide: toggle,
  };

  return (
    <NewsContext.Provider value={newsContext}>
      <Modal isOpen={modal === 1 && !!article} fade={false} toggle={toggle} centered={true} className="newsModal" >
        <ModalBody>
          <NewsComponent article={article} modal={true} />
        </ModalBody>
      </Modal>
      {children}
    </NewsContext.Provider>
  );
};


const useNews = () => useContext(NewsContext);

export { ModalNewsProvider, useNews };