import { PrimaryBackground, PrimaryButton, StyledH1, StyledP } from "../components/StyledComponents";
import { Form, FormGroup } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLoading } from "../providers/loading";
import { useAlert } from "../providers/alert";
import { sendEmailConfirmation } from "../api/auth";
import classNames from "classnames";
import { formatError } from "../helpers/formatError";
import FormErrorMessage from "../components/FormErrorMessage";
import { Input } from "../components/WrappedInput";

export function SendConfirmationEmail({ history }) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const loading = useLoading()
  const alert = useAlert()

  const onSend = async (values) => {
    try {
      loading.show()
      await sendEmailConfirmation(values)
      history.replace('/account-checking')
    } catch (err) {
      alert.show(formatError(err));
    } finally {
      loading.hide()
    }
  }

  return (
    <PrimaryBackground direction="column" justify="center">
      <StyledH1 bottom={2} align="center" className="w-100">
        {t('SEND_CONFIRMATION_EMAIL')}
      </StyledH1>
      <StyledP color="purple" bottom={3} align="center">{t('SEND_CONFIRMATION_EMAIL_LABEL')}</StyledP>
      <Form onSubmit={handleSubmit(onSend)}>
        <FormGroup className={classNames("m8", { invalid: errors.email })}>
          <Input type="email" name="email" id="email" placeholder="Email"
            {...register('email', {
              required: t('FIELD_REQUIRED'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                message: t('INVALID_EMAIL'),
              },
            })}
          />
          <FormErrorMessage errors={errors} name="email" />
        </FormGroup>
        <PrimaryButton type="submit" bottom={3} top={3}>
          {t('SEND')}
        </PrimaryButton>
      </Form>
    </PrimaryBackground>
  );
}