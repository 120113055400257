import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getPreviewImage } from "../helpers/workout";
import { Badge, PrimaryButton, StyledH2, StyledWorkoutCard } from "./StyledComponents";
import { WorkoutInfoComponent } from "./WorkoutInfoComponent";

export function WorkoutCard({ workout }) {
  const history = useHistory()
  const { t } = useTranslation()
  const {
    id = 0,
    title = '',
    bodyparts = [],
  } = workout || {}

  return (
    <StyledWorkoutCard direction="column" style={{
      backgroundImage: `linear-gradient(0deg, rgba(43, 42, 81, 0.4), rgba(43, 42, 81, 0.4)), url(${getPreviewImage(workout, '../placeholder.png')})`
    }}>
      <StyledH2 bottom={1}>{title}</StyledH2>
      <div className="row-container">
        {
          bodyparts.map(part => (
            <Badge right={1} key={part.id}><span>{part.name}</span></Badge>
          ))
        }
      </div>
      <div className="row-container">
        <WorkoutInfoComponent workout={workout} />
      </div>
      <PrimaryButton auto={'auto'} onClick={() => history.push(`/workouts/${id}`)}>
        {t('START_WORKOUT')}
      </PrimaryButton>
    </StyledWorkoutCard>
  );
}