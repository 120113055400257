import moment from 'moment';
import { useEffect, useState } from 'react';
import WheelPicker from 'react-simple-wheel-picker';

export const DayPicker = ({ day, setDay, month }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		const daysInMonth = moment().month(month).daysInMonth()
		setDaysData(daysInMonth)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [month])

	const setDaysData = (length) => {
		const days = [...Array(length).keys()].map(n => ({
			id: (n + 1).toString(),
			value: (n + 1).toString()
		}))
		if (Number(day) > length) {
			setDay(length.toString())
		}
		setData(days)
	}

	const handleOnChange = (target) => {
		if (target) {
			setDay(target.id)
		}
	};

	if (data.length === 0) return null
	return (
		<WheelPicker
			data={data}
			onChange={handleOnChange}
			height={270}
			width={100}
			titleText="Enter value same as aria-label"
			itemHeight={30}
			selectedID={day}
			color="#ccc"
			activeColor="#F173BE"
			backgroundColor="transparent"
			classname="dayPicker"
		/>
	);
};