import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BellIcon } from "../assets/icons/notification-icon.svg";
import { getNotificationsCount } from "../api/notifications";
import { useAuth } from "../providers/auth";
import { NotificationDiv } from "./StyledComponents";

export const NotificationIcon = () => {
  const [{ profile, token }] = useAuth();
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const fetchCount = async () => {
    if (!profile || !profile.id) return;

    try {
      const { data } = await getNotificationsCount({
        token,
        user: profile.id,
      });

      setCount(data);

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <NotificationDiv>
        <Link to="/notifications">
          <BellIcon style={{ marginRight: count > 0 ? '12px' : '0px' }} />
          {
            (count && count > 0) ? (
              <span className="count">
                {count >= 100 ? 99 : count}
              </span>
            ) : null
          }
        </Link>
      </NotificationDiv>
    </>
  );
};
